import React, { useContext } from 'react';
import classNames from './seperatecomponents.module.scss';
import Skeleton from 'react-loading-skeleton';

//images
import dealsFullLogo from '../../static/images/fullLogo/dealsFullLogo.svg';
import walletIcon from '../../static/images/vaults/wallet.svg';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { switchItems } from './static/mapdata';
import { BsThreeDotsVertical } from 'react-icons/bs';
import affiliateLogo from '../../static/images/deals/affiliateIcon.svg';
import influencerLogo from '../../static/images/deals/influncerIcon.svg';
import ibankerLogo from '../../static/images/deals/ibankerIcon.svg';
import wealthmanagerLogo from '../../static/images/deals/wealthmanagerIcon.svg';
import copyLogo from '../../static/images/deals/copyIcon.svg';
import comsplanIcon from '../../static/images/deals/comsplanIcon.svg';
import trainingIcon from '../../static/images/deals/trainingIcon.svg';
import marketingIcon from '../../static/images/deals/marketingIcon.svg';
import addUser from '../../static/images/deals/addUser.svg';
import sendGift from '../../static/images/deals/sendGift.svg';
import community from '../../static/images/deals/community.svg';
import affiliatebank from '../../static/images/deals/affiliatebank.svg';

import dummyIcon from './static/dummyIcon.svg';
import dummyprofile from './static/dummyprofile.svg';
import { useEffect } from 'react';
import axios from 'axios';
import { BankContext } from '../../context/Context';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import fulllogo from '../../static/images/offeringsfull.svg';
import dealsfullIcon from '../../static/images/dealsFullIcon.svg';
import search from '../../static/images/malls/search.svg';
import { useAppContextDetails } from '../../context/AppContext';

export const TopNavbar = () => {
  const history = useHistory();
  const { email } = useContext(BankContext);
  const { setMarketSearch } = useAppContextDetails();
  const [coinValue, setCoinValue] = useState('');
  const [sharetokenBalance, setsharetokenBalance] = useState([]);
  const [apiDataLoadingCoinValue, setApiDataLoadingCoinValue] = useState(false);

  useEffect(() => {
    getbalance();
  }, []);

  function getbalance() {
    setApiDataLoadingCoinValue(true);

    let obj = {
      app_code: 'indianinvestor',
      email: email,
      include_coins: ['INR'],
    };

    axios
      .post('https://comms.globalxchange.com/coin/vault/service/coins/get', obj)
      .then((response) => {
        if (response?.data?.coins_data?.length > 0) {
          //   console.log(response?.data, 'response');
          setCoinValue(response?.data?.coins_data[0]?.coinValue);
          setApiDataLoadingCoinValue(false);
        }
      })
      .catch((error) => {
        setApiDataLoadingCoinValue(false);
      });
  }

  return (
    <div className={classNames.settingsNavbar}>
      <div
        className={classNames.settingsIcon}
        onClick={() => {
          history.push('/offerings/shares');
        }}
      >
        <img src={dealsfullIcon} alt="" />
      </div>
      <div className={classNames.searchSettings}>
        {/* <div className="all">
              <p>All Categories</p>
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={ddown} alt="" />
              </div>
            </div> */}
        <div className={classNames.im}>
          <input
            type="text"
            placeholder="Im Looking For..."
            onChange={(event) => setMarketSearch(event?.target?.value)}
          />
          <div className={classNames.arroww} style={{ cursor: 'pointer' }}>
            <img src={search} alt="" />
          </div>
        </div>
      </div>
      <div className={classNames.settingsListBtn}>Popular Actions</div>
    </div>
  );
};

export const SwithNavbar = () => {
  return (
    <div className={classNames.wSpace}>
      <div className={classNames.optionss}>
        {switchItems.map((eachItem, i) => {
          return <SwitchItems {...eachItem} index={i} />;
        })}
      </div>
    </div>
  );
};

const SwitchItems = ({ name, icon, index }) => {
  return (
    <div
      className={`${index == 0 ? classNames.selectedTab : ''} ${
        classNames.tabs
      }`}
      // style={{ borderBottom: index == 0 ? '3px solid  #08152D' : '' }}
    >
      <img src={icon} alt={name} />
      <div>{name}</div>
      <div
        className={classNames.hoverDiv}
        style={{ display: index == 0 ? 'none' : '' }}
      ></div>
    </div>
  );
};

export const DealsContentPage = () => {
  return (
    <div className={classNames.dealsContentPage}>
      <div className={classNames.leftContainer}>
        <LeftSingleCard />
        <LeftSingleCard />
        <LeftSingleCard />
      </div>
      <div className={classNames.rightContainer}>
        <div className={classNames.topContainer}>
          <div className={classNames.title}>
            <div>
              <div>Welcome To The Deals Portal</div>
              <div>Your Current Deal Maker Rank Is Affiliate</div>
            </div>
            <div>Apply For Another Rank</div>
          </div>
          <div className={classNames.cardsContainer}>
            <div>
              <img src={affiliateLogo} alt="affiliateLogo" />
              <div>Affiliate</div>
            </div>
            <div>
              <img src={influencerLogo} alt="influencerLogo" />
              <div>Influencer</div>
            </div>
            <div>
              <img src={ibankerLogo} alt="ibankerLogo" />
              <div>iBanker</div>
            </div>
            <div>
              <img src={wealthmanagerLogo} alt="wealthmanagerLogo" />
              <div>Wealth Manager</div>
            </div>
          </div>
          <div className={classNames.registerDiv}>
            <div>
              <div>Your Registration Link</div>
              <div>
                <div>
                  https://app.indianinvestor.com/register/affiliate/king
                </div>
                <img src={copyLogo} alt="copyLogo" />
              </div>
            </div>
            <div>
              <div>Your Username</div>
              <div>
                King
                <img src={copyLogo} alt="copyLogo" />
              </div>
            </div>
          </div>
        </div>
        <div className={classNames.bottomContainer}>
          <div>
            <div>
              <img src={comsplanIcon} alt="comsplanIcon" />
            </div>
            <div>
              <div>CompPlans</div>
              <div>Learn the compensation possibilities as an Affiliate.</div>
            </div>
          </div>
          <div>
            <div>
              <img src={trainingIcon} alt="trainingIcon" />
            </div>
            <div>
              <div>Training & Certification</div>
              <div>Expand your career as a capital markets professional.</div>
            </div>
          </div>
          <div>
            <div>
              <img src={marketingIcon} alt="marketingIcon" />
            </div>
            <div>
              <div>Marketing</div>
              <div>
                Explore our suite of digital marketing & branding tools.
              </div>
            </div>
          </div>
          <div className={classNames.threeDivs}>
            <div>
              <img src={addUser} alt="addUser" />
              <div>Add User</div>
            </div>
            <div>
              <img src={sendGift} alt="sendGift" />
              <div>Send Gift</div>
            </div>
            <div>
              <img src={community} alt="community" />
              <div>Community & FAQ’s</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LeftSingleCard = () => {
  return (
    <div className={classNames.leftSingleDiv}>
      <div className={classNames.topDiv}>
        <div>
          <img src={dummyIcon} alt="dummyIcon" />
          <div>TaxChains</div>
        </div>
        <div>@TXCE</div>
      </div>
      <div>
        TaxChains announces that they are looking to do a SafeToken issuance to
        raise 2.0 CR
      </div>
      <div className={classNames.detailsDiv}>
        <div>
          <div>Affiliate Comp:</div>
          <div>Available</div>
        </div>
        <div>
          <div>Influencer Program:</div>
          <div>Not Available</div>
        </div>
        <div>
          <div>Institutional Discount:</div>
          <div>Available</div>
        </div>
      </div>
      <div className={classNames.bottomDiv}>
        <div>IBanker:</div>
        <div>
          <img src={dummyprofile} alt="dummyIcon" />
          <div>Shorupan P</div>
        </div>
      </div>
    </div>
  );
};
