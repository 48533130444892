import React from 'react';
import countryCodes from 'country-codes-list';
import classNames from './shareTradeDetailModal.module.scss';
import { FormatCurrency, FormatNumber } from '../../utils/FunctionTools';
import { useAppDetail } from '../../queryHooks';
import { Link } from 'react-router-dom';

function ShareTradeDetailModal({ onClose = () => {}, shareToken }) {
  const myCountryCodesObject = countryCodes.customList(
    'countryNameEn',
    '{countryCode}'
  );
  const { data: appDetail, isLoading: loadingApp } = useAppDetail(
    shareToken?.pathAppCode
  );
  return (
    <>
      <div className={classNames.shareTradeDetailModal}>
        <div
          className={classNames.overlayClose}
          onClick={() => {
            try {
              onClose();
            } catch (error) {}
          }}
        />
        <div className={classNames.settingsCard}>
          <div className={classNames.inCard}>
            <div className={classNames.coinDetail}>
              <img
                src={shareToken?.token_profile_data?.coinImage}
                alt=""
                className={classNames.coinIcon}
              />
              <span className={classNames.coinName}>
                {shareToken?.token_profile_data?.coinName}
              </span>
              <div className={classNames.symbol}>
                <span>{shareToken?.token}</span>
                <span>
                  <img
                    src={`https://flagcdn.com/w160/${myCountryCodesObject[
                      shareToken?.country
                    ]?.toLowerCase()}.png`}
                    alt=""
                  />
                  {shareToken?.country}
                </span>
                <span>
                  <img src={appDetail?.app_icon} alt="" />
                  {appDetail?.app_name}
                </span>
              </div>
              <div className={classNames.assetCoin}>
                <img src={shareToken?.asset_metaData?.coinImage} alt="" />
                <span>{shareToken?.asset}</span>
              </div>
            </div>
            <TxnDetail />
          </div>
          <div className={classNames.footerBtns}>
            <div className={classNames.btnAssets} style={{ opacity: 0.4 }}>
              Certificate
            </div>
            <Link to="/vaults" className={classNames.btnQuickBuy}>
              Vaults
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

function TxnDetail({ data }) {
  return (
    <>
      <div className={classNames.listItems}>
        <div className={classNames.listItem}>
          <div className={classNames.label}>Order Status</div>
          <div className={classNames.value}></div>
        </div>
        <div className={classNames.listItem}>
          <div className={classNames.label}>Debited</div>
          <div className={classNames.value}>
            <img src={data?.coinOb?.icon} alt="" />
            {data?.coinOb?.coin}
          </div>
        </div>
        <div className={classNames.listItem}>
          <div className={classNames.label}>Credited</div>
          <div className={classNames.value}></div>
        </div>
        <div className={classNames.listItem}>
          <div className={classNames.label}>Order Fill %</div>
          <div className={classNames.value}></div>
        </div>
      </div>
    </>
  );
}

export default ShareTradeDetailModal;
