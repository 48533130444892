import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { BankContext } from '../../context/Context';
import { useCoinContextData } from '../../context/CoinContext';
import { SIDEBAR_CONTENTS } from './constants';
import { SIDEBAR_MENU } from './constants';
import { useLocation } from 'react-router-dom';

//images
import settings from '../../static/images/icons/settings.svg';
import close from '../../static/images/icons/close.svg';
import plus from '../../static/images/clipIcons/plusBlack.svg';
import ddown from '../../static/images/clipIcons/ddown.svg';
import ddown1 from '../../static/images/clipIcons/dup.svg';
import guest from '../../static/images/guest.jpg';

import GarageOS from '../../static/images/GarageOS.svg';
import digifinex from '../../static/images/icons/digifinex.svg';
import coinMarketCap from '../../static/images/icons/coinMarketCap.svg';
import etherScan from '../../static/images/icons/etherScan.svg';
import dgp from '../../static/images/icons/dgp.svg';
import axios from 'axios';
import { useAppContextDetails } from '../../context/AppContext';
import { openAppRetiredApp } from '../../assets/Functions/miniFunctions';

export const DefaultSideBarContent = ({
  active,
  sidebarCollapse,
  chatOn,
  setChatOn,
  appColorCode,
  openSettings,
  isSecondaryMenu,
  toggleSettings,
}) => {
  const {
    email,
    setSidebarCollapse,
    setSelectedTopItemReact,
    username,
    name,
    profileImg,
  } = useContext(BankContext);

  const {
    coinActionEnabled,
    setCoinActionEnabled,
    setCoinAction,
    isCoinLoadingInAddAction,
    addBankingEnabled,
    setAddBankingEnabled,
    isClose,
    setIsClose,
    setSelectedCoin,
    setCoinType,
    senbtnClicked,
    setSendBtnClicked,
  } = useCoinContextData();
  const {
    hideEveryone,
    setHideEveryone,
    hideAffiliate,
    setHideAffiliate,
    hideBrands,
    setHideBrands,
    hideInfluencers,
    setHideInfluencers,
    hideContentCreators,
    setHideContentCreators,
    setCoinSelected,
  } = useAppContextDetails();

  const { pathname } = useLocation();
  const history = useHistory();
  // const sideBarBorder = { borderLeft: '7px solid #CF1A38' };
  const sideBarBorder = {
    background: '#FFFFFF',
    border: ' 0.5px solid #E5E5E5',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    height: 'max-content',
    aspectRatio: '1/1',
    margin: '2rem 1rem',
  };
  const notLoginStylee = {
    // pointerEvents: 'none',
    opacity: '0.5',
  };
  const applyStyle = (condition) => (condition ? sideBarBorder : {});
  const notLoginStyle = (condition) => (condition ? notLoginStylee : {});
  const closeChatScreen = () => (chatOn ? setChatOn(false) : null);
  const [hoverItem, setHoverItem] = useState('');

  function hoverFunctionOfferings() {
    setHoverItem('Offerings');
  }
  function hoverFunctionMarket() {
    setHoverItem('market');
  }
  function hoverFunctionStartup() {
    setHoverItem('capitalized');
  }
  function hoverFunctionStartupVault() {
    setHoverItem('VaultsSecond');
  }
  function hoverFunctionStartupDeals() {
    setHoverItem('deals');
  }
  function hoverFunctionSupernova() {
    setHoverItem('supernova');
  }
  function hoverFunctionStartupGarageOS() {
    setHoverItem('GarageOS');
  }
  function hoverFunctionStartupProspectus() {
    setHoverItem('prospectus');
  }
  function hoverFunctionProduction() {
    setHoverItem('Production');
  }

  function hoverFunctionInfluenceMarket() {
    setHoverItem('InfluenceMarket');
  }

  function hoverFunctionVaults() {
    setHoverItem('vaults');
  }
  function hoverFunctionPlay() {
    setHoverItem('Play');
  }
  function hoverFunctionAffiliate() {
    setHoverItem('Affiliate');
  }

  function hoverFunctionCRM() {
    setHoverItem('CRM');
  }

  function hoverFunctionBrands() {
    setHoverItem('Brands');
  }

  function hoverFunctionAffiliateBank() {
    setHoverItem('AffiliateBank');
  }

  function hoverFunctionCRM1() {
    setHoverItem('Network Chains');
  }

  function hoverFunctionBrands1() {
    setHoverItem('Deals');
  }

  function hoverFunctionEngagement() {
    setHoverItem('Engagement');
  }

  function hoverFunctionPromoting() {
    setHoverItem('Promoting');
  }

  function hoverFunctionMalls() {
    setHoverItem('Retailer');
  }
  function hoverFunctionAdvertisers() {
    setHoverItem('Advertisers');
  }

  function hoverFunctionEndorsement() {
    setHoverItem('Endorsement');
  }

  function hoverFunctionLinked() {
    setHoverItem('Linked');
  }

  function hoverLeaveFunc() {
    setHoverItem('');
  }

  useEffect(() => {
    if (pathname.includes('vault') || pathname.includes('malls')) {
      setHideEveryone(false);
      setHideAffiliate(true);
      setHideBrands(true);
      setHideContentCreators(true);
      setHideInfluencers(true);
    }
    if (
      pathname.includes('capitalized') ||
      pathname.includes('affiliatebank') ||
      pathname.includes('networkChain') ||
      pathname.includes('levels') ||
      pathname.includes('affiliate') ||
      pathname.includes('prospectus') ||
      pathname.includes('vaults')
    ) {
      setHideEveryone(true);
      setHideAffiliate(false);
      setHideBrands(true);
      setHideContentCreators(true);
      setHideInfluencers(true);
    }
    if (pathname.includes('advertise') || pathname.includes('retailer')) {
      setHideEveryone(true);
      setHideAffiliate(true);
      setHideBrands(false);
      setHideContentCreators(true);
      setHideInfluencers(true);
    }
  }, []);
  // console.log('active', active);
  return (
    <div
      style={{
        width: '100%',
        height: sidebarCollapse ? '92%' : '100%',
        display: isClose ? 'none' : 'flex',
        flexWrap: sidebarCollapse ? 'nowrap' : 'wrap',
        border: sidebarCollapse ? 'none' : '0.5px solid #EBEBEB',
        borderRight: 'none',
        flexFlow: sidebarCollapse ? 'column' : 'wrap',
        alignContent: sidebarCollapse ? '' : 'flex-start',
        borderLeft: 'none',
        borderBottom: 'none',
        justifyContent: sidebarCollapse ? 'center' : '',
        marginTop: sidebarCollapse ? '-32px' : '0',
        position: 'relative',
      }}
    >
      <div
        className="header-boxx"
        style={{
          display: sidebarCollapse ? 'none' : 'flex',
        }}
        onClick={() => {
          setHideEveryone(!hideEveryone);
          if (!hideAffiliate) {
            setHideAffiliate(true);
          }
          if (!hideBrands) {
            setHideBrands(true);
          }
          if (!hideContentCreators) {
            setHideContentCreators(true);
          }
          if (!hideInfluencers) {
            setHideInfluencers(true);
          }
        }}
      >
        <div className="text-container">
          <div>
            <img src={hideEveryone ? ddown : ddown1} alt="" />
          </div>
          <div>For Investors</div>
        </div>
        <div>
          <img src={plus} alt="plus" />
        </div>
      </div>

      <div
        className="menu-itm active clickable"
        style={{
          borderBottom: 'none',
          ...applyStyle(active === 'Offerings' && !chatOn && sidebarCollapse),
          // ...notLoginStyle(
          //   !(email && localStorage.getItem('nvestBankLoginAccount'))
          // ),
          display: hideEveryone ? 'none' : 'flex',
        }}
        onClick={() => {
          if (email) {
            // setSelectedTopItemReact('Resources');
            history.push('/offerings/shares');
          } else {
            history.push('/offerings/shares');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionOfferings}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[0].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'Offerings' || active === 'Offerings'
                ? '50px'
                : '40px',
          }}
        />
        {!sidebarCollapse && active === 'Offerings' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[0].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'Offerings' && active != 'Offerings'
                ? 'flex'
                : 'none',
          }}
        >
          {SIDEBAR_MENU[0].name}
        </div>
      </div>

      <div
        className="menu-itm active clickable"
        style={{
          // borderRight: 'none',
          borderBottom: 'none',
          ...applyStyle(active === 'vaults' && !chatOn && sidebarCollapse),
          ...notLoginStyle(
            !(email && localStorage.getItem('nvestBankLoginAccount'))
          ),
          display: hideEveryone ? 'none' : 'flex',
        }}
        onClick={() => {
          if (email) {
            history.push('/vault');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionVaults}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[1].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'vaults' || active === 'vaults' ? '50px' : '40px',
          }}
        />
        {!sidebarCollapse && active === 'vaults' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[1].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'vaults' && active != 'vaults' ? 'flex' : 'none',
          }}
        >
          {SIDEBAR_MENU[1].name}
        </div>
      </div>

      <div
        className="menu-itm active clickable"
        style={{
          borderBottom: 'none',
          borderRight: 'none',
          ...applyStyle(active === 'market' && !chatOn && sidebarCollapse),
          // ...notLoginStyle(
          //   !(email && localStorage.getItem('nvestBankLoginAccount'))
          // ),
          display: hideEveryone ? 'none' : 'flex',
        }}
        onClick={() => {
          if (email) {
            history.push('/market');
            setCoinSelected('');
          } else {
            history.push('/market');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionMarket}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[2].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'market' || active === 'market' ? '50px' : '40px',
          }}
        />
        {!sidebarCollapse && active === 'market' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[2].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'market' && active != 'market' ? 'flex' : 'none',
          }}
        >
          {SIDEBAR_MENU[2].name}
        </div>
      </div>
      <div
        className="header-boxx"
        style={{
          display: sidebarCollapse ? 'none' : 'flex',
          borderTop: !hideEveryone ? '0.5px solid #e7e7e7' : '',
        }}
        onClick={() => {
          setHideAffiliate(!hideAffiliate);
          if (!hideEveryone) {
            setHideEveryone(true);
          }
          if (!hideBrands) {
            setHideBrands(true);
          }
          if (!hideContentCreators) {
            setHideContentCreators(true);
          }
          if (!hideInfluencers) {
            setHideInfluencers(true);
          }
        }}
      >
        <div className="text-container">
          <div>
            <img src={hideAffiliate ? ddown : ddown1} alt="" />
          </div>
          <div>For Startups</div>
        </div>
        <div>
          <img src={plus} alt="plus" />
        </div>
      </div>

      <div
        className="menu-itm active clickable"
        style={{
          ...applyStyle(active === 'capitalized' && !chatOn && sidebarCollapse),
          ...notLoginStyle(
            !(email && localStorage.getItem('nvestBankLoginAccount'))
          ),
          display: hideAffiliate ? 'none' : 'flex',
        }}
        onClick={() => {
          if (email) {
            history.push('/capitalized');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionStartup}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[3].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'capitalized' || active === 'capitalized'
                ? '50px'
                : '40px',
          }}
        />
        {!sidebarCollapse && active === 'capitalized' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[3].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'capitalized' && active != 'capitalized'
                ? 'flex'
                : 'none',
          }}
        >
          {SIDEBAR_MENU[3].name}
        </div>
      </div>

      <div
        className="menu-itm active clickable"
        style={{
          ...applyStyle(active == 'VaultsSecond' && !chatOn && sidebarCollapse),
          ...notLoginStyle(
            !(email && localStorage.getItem('nvestBankLoginAccount'))
          ),
          display: hideAffiliate ? 'none' : 'flex',
        }}
        onClick={() => {
          if (email) {
            history.push('/vaults');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionStartupVault}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[4].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem == 'VaultsSecond' || active == 'VaultsSecond'
                ? '50px'
                : '40px',
          }}
        />
        {/* {!sidebarCollapse && active === 'capitalized' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[3].name}</div>
        )} */}
        {!sidebarCollapse && active == 'VaultsSecond' && (
          <div className="sidebar-icon-label">Vaults</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem == 'VaultsSecond' && active != 'VaultsSecond'
                ? 'flex'
                : 'none',
          }}
        >
          Vaults
        </div>
      </div>
      <div
        className="menu-itm active clickable"
        style={{
          ...applyStyle(active === 'prospectus' && !chatOn && sidebarCollapse),
          ...notLoginStyle(
            !(email && localStorage.getItem('nvestBankLoginAccount'))
          ),
          display: hideAffiliate ? 'none' : 'flex',
        }}
        onClick={() => {
          if (email) {
            history.push('/prospectus');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionStartupProspectus}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[5].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'prospectus' || active === 'prospectus'
                ? '50px'
                : '40px',
          }}
        />
        {!sidebarCollapse && active === 'prospectus' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[5].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'prospectus' && active != 'prospectus'
                ? 'flex'
                : 'none',
          }}
        >
          {SIDEBAR_MENU[5].name}
        </div>
      </div>
      <div
        className="menu-itm active clickable"
        style={{
          ...applyStyle(active === 'supernova' && !chatOn && sidebarCollapse),
          ...notLoginStyle(
            !(email && localStorage.getItem('nvestBankLoginAccount'))
          ),
          display: hideAffiliate ? 'none' : 'flex',
        }}
        onClick={() => {
          if (email) {
            history.push('/supernova');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionSupernova}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[6].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'supernova' || active === 'supernova' ? '50px' : '40px',
          }}
        />
        {!sidebarCollapse && active === 'supernova' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[6].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'supernova' && active != 'supernova' ? 'flex' : 'none',
          }}
        >
          {SIDEBAR_MENU[6].name}
        </div>
      </div>

      <div
        className="menu-itm active clickable"
        style={{
          ...applyStyle(active === 'GarageOS' && !chatOn && sidebarCollapse),
          ...notLoginStyle(
            !(email && localStorage.getItem('nvestBankLoginAccount'))
          ),
          display: hideAffiliate ? 'none' : 'flex',
        }}
        onClick={() => {
      window.open("http://innvoationgarage.in/","blank")
        }}
        onMouseEnter={hoverFunctionStartupGarageOS}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={GarageOS}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'GarageOS' || active === 'GarageOS' ? '50px' : '40px',
          }}
        />
        {!sidebarCollapse && active === 'GarageOS' && (
          <div className="sidebar-icon-label">GarageOS</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'GarageOS' && active != 'GarageOS' ? 'flex' : 'none',
          }}
        >
       GarageOS
        </div>
      </div>

      <div
        className="menu-itm active clickable"
        style={{
    
          ...notLoginStyle(
            !(email && localStorage.getItem('nvestBankLoginAccount'))
          ),
          display: hideAffiliate ? 'none' : 'flex',
        }}
      
      >
      
      </div>

      <div
        className="header-boxx"
        style={{
          display: sidebarCollapse ? 'none' : 'flex',
          opacity: '0.5',
          pointerEvents: 'none',
        }}
        onClick={() => {
          setHideBrands(!hideBrands);
          if (!hideEveryone) {
            setHideEveryone(true);
          }
          if (!hideAffiliate) {
            setHideAffiliate(true);
          }
          if (!hideContentCreators) {
            setHideContentCreators(true);
          }
          if (!hideInfluencers) {
            setHideInfluencers(true);
          }
        }}
      >
        <div className="text-container">
          <div>
            <img src={hideBrands ? ddown : ddown1} alt="" />
          </div>
          <div>For Employees</div>
        </div>
        <div>
          <img src={plus} alt="plus" />
        </div>
      </div>

      <div
        className="header-boxx"
        style={{
          display: sidebarCollapse ? 'none' : 'flex',
          borderTop: sidebarCollapse
            ? 'none'
            : hideBrands
            ? 'none'
            : '0.5px solid #e7e7e7',
          // opacity: '0.5',
          // pointerEvents: 'none',
        }}
        onClick={() => {
          setHideInfluencers(!hideInfluencers);
          if (!hideEveryone) {
            setHideEveryone(true);
          }
          if (!hideAffiliate) {
            setHideAffiliate(true);
          }
          if (!hideContentCreators) {
            setHideContentCreators(true);
          }
          if (!hideBrands) {
            setHideBrands(true);
          }
        }}
      >
        <div className="text-container">
          <div>
            <img src={hideInfluencers ? ddown : ddown1} alt="" />
          </div>
          <div>For Ecosystem Partners</div>
        </div>
        <div>
          <img src={plus} alt="plus" />
        </div>
      </div>
      <div
        className="menu-itm active clickable"
        style={{
          ...applyStyle(active === 'deals' && !chatOn && sidebarCollapse),
          ...notLoginStyle(
            !(email && localStorage.getItem('nvestBankLoginAccount'))
          ),
          display: hideInfluencers ? 'none' : 'flex',
        }}
        onClick={() => {
          if (email) {
            history.push('/deals');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionStartupDeals}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[7].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'deals' || active === 'deals' ? '50px' : '40px',
          }}
        />
        {!sidebarCollapse && active === 'deals' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[7].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'deals' && active != 'deals' ? 'flex' : 'none',
          }}
        >
          {SIDEBAR_MENU[7].name}
        </div>
      </div>
      <div
        className="loggedOutBtns"
        style={{
          display: sidebarCollapse
            ? 'none'
            : email || localStorage.getItem('nvestBankLoginAccount')
            ? 'none'
            : '',
        }}
      >
        <div
          onClick={() => {
            history.push('/');
          }}
        >
          Login
        </div>
        <div
          onClick={() => {
            history.push('/');
          }}
        >
          Get Started
        </div>
      </div>
    </div>
  );
};
