import React from 'react';
import { useAppContextDetails } from '../../../context/AppContext';
import { useCoinContextData } from '../../../context/CoinContext';
import info from '../../../static/images/info.svg';
import timebox from '../../../static/images/timebox.svg';
import {
  getDateTime,
  getTime,
  getTimeFull,
  getYear,
  handleDecimal,
} from '../../../utils/FunctionTools';
import picelock from '../../../static/images/picelock.svg';
import {
  ExchangeRangeSkeleton,
  FeesCardSkeleton,
  InputSkeleton,
  Step3Skeleton,
  Step3SkeletonBottom,
  TimeLoadingSkeleton,
} from './Skeletons';
import Skeleton from 'react-loading-skeleton';

export const WithdrawalConfirmation = ({
  handleBreadCrumb,
  confirmationData,
  validateText,
  isLoading,
}) => {
  const {
    addressTerm,
    selectedCoin,
    toTerm,
    checkedOne,
    setCheckedOne,
    checkedTwo,
    setCheckedTwo,
  } = useCoinContextData();
  const { appCurrencyName } = useAppContextDetails();

  const handleChangeOne = () => setCheckedOne(!checkedOne);
  const handleChangeTwo = () => setCheckedTwo(!checkedTwo);

  return isLoading ? (
    <div className="sidebarwithdraw">
      <div>
        <div className="breadcrumb" style={{ display: 'flex' }}>
          <p
            className="breadCrumbText"
            style={{
              marginTop: '5px',
              cursor: 'pointer',
            }}
            onClick={() => handleBreadCrumb()}
          >
            <span>Enter Amount</span>
            &nbsp;&nbsp;&gt;&nbsp;&nbsp;
          </p>
          <p className="active-crumb">
            <span
              style={{
                textDecoration: 'underline',
                textUnderlineOffset: '3px',
              }}
            >
              Withdrawal Confirmation
            </span>
          </p>
        </div>
        <div className="step-div">
          <p className="step-head">Step 3: Confirmation</p>
          <div className="step-3-scroll-div">
            <p className="step3-each-card-head">Debiting App</p>
            <div className="step3-each-card">
              <Step3Skeleton />
              <Step3SkeletonBottom />
            </div>
            <p className="step3-each-card-head">Debiting Vault</p>
            <div className="step3-each-card">
              <Step3Skeleton />
              <Step3SkeletonBottom />
            </div>
            <p className="step3-each-card-head">Destination</p>
            <div className="step3-each-card">
              <Step3Skeleton />

              <div className="d-flex flex-column">
                <Skeleton
                  style={{
                    marginLeft: '15px',
                    height: '30px',
                    width: '150px',
                  }}
                />
              </div>
            </div>
            <p className="step3-each-card-head">Debiting Amount</p>
            <div className="from-to-inputs">
              <div className="div-from-input">
                <InputSkeleton />
              </div>
              <div className="div-border"></div>
              <div className="div-to-input">
                <InputSkeleton />
              </div>
            </div>
            <p className="step3-each-card-text3">
              Exchange Rate Booked At
              <ExchangeRangeSkeleton />
            </p>
            <p className="step3-each-card-head">PriceLock Insurance</p>
            <div className="step3-each-card">
              <div>
                <img className="step3-each-card-img" src={picelock} alt="" />
              </div>
              <p className="step3-each-card-text">Disabled</p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '25px',
              }}
            >
              <p className="step3-subhead">Fees</p>
              <Skeleton
                style={{
                  marginLeft: '25px',
                  height: '30px',
                  width: '50px',
                }}
              />
            </div>
            <div className="feescard-1">
              <div className="fees-card-content">
                <p className="fee-text1">
                  Affiliate{' '}
                  <span>
                    <img src={info} alt="" width={10} />
                  </span>
                </p>
                <FeesCardSkeleton />
              </div>
              <div className="fees-card-content">
                <p className="fee-text1">
                  App{' '}
                  <span>
                    <img src={info} alt="" width={10} />
                  </span>
                </p>
                <FeesCardSkeleton />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '20px 0 20px 0',
                }}
              >
                <p className="fee-text1">
                  Banker{' '}
                  <span>
                    <img src={info} alt="" width={10} />
                  </span>
                </p>
                <FeesCardSkeleton />
              </div>
            </div>
            <p className="step3-each-card-head">Receiving Amount</p>
            <div className="from-to-inputs">
              <div className="div-from-input">
                <InputSkeleton />
              </div>
              <div className="div-border"></div>
              <div className="div-to-input">
                <InputSkeleton />
              </div>
            </div>
            <p className="step3-each-card-text3">
              Exchange Rate Booked At
              <ExchangeRangeSkeleton />- <ExchangeRangeSkeleton />
            </p>
            <p className="step3-each-card-head">Estimated Time Of Arrival</p>
            <div className="step3-each-card">
              <div className="timebox-div">
                <img className="step3-each-card-img" src={timebox} alt="" />
              </div>
              <TimeLoadingSkeleton />
            </div>
            <p className="step3-each-card-head">Latest Time Of Arrival</p>
            <div className="step3-each-card">
              <div className="timebox-div">
                <img className="step3-each-card-img" src={timebox} alt="" />
              </div>
              <TimeLoadingSkeleton />
            </div>
            <p className="step3-each-card-head">Confirmations</p>
            <div className="check-div">
              <label>
                <input
                  className="check-box-withdraw"
                  type="checkbox"
                  key="check-1"
                  id="check-1"
                  checked={checkedOne}
                  onChange={handleChangeOne}
                />
                <p style={{ paddingLeft: '30px', marginTop: '-35px' }}>
                  {
                    ' I Consent To All The Aforementioned Details Regarding This Transfer Request.'
                  }
                </p>
              </label>
            </div>
            <div className="check-div">
              <label>
                <input
                  className="check-box-withdraw-2"
                  type="checkbox"
                  key="check-2"
                  id="check-2"
                  checked={checkedTwo}
                  onChange={handleChangeTwo}
                />
                <p style={{ paddingLeft: '30px', marginTop: '-35px' }}>
                  {` I Acknowledge That IndianOTC Is Not Able To Recover Funds Once They Have Sent To`}
                  <span style={{ fontWeight: '700' }}> {addressTerm}</span>
                </p>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarwithdraw">
      <div>
        <div className="breadcrumb" style={{ display: 'flex' }}>
          <p
            className="breadCrumbText"
            style={{
              marginTop: '5px',
              cursor: 'pointer',
            }}
            onClick={handleBreadCrumb}
          >
            <span>Enter Amount</span>
            &nbsp;&nbsp;&gt;&nbsp;&nbsp;
          </p>
          <p className="active-crumb">
            <span
              style={{
                textDecoration: 'underline',
                textUnderlineOffset: '3px',
              }}
            >
              Withdrawal Confirmation
            </span>
          </p>
        </div>
        <div className="step-div">
          <p className="step-head">Step 3: Confirmation</p>
          <div className="step-3-scroll-div">
            <p className="step3-each-card-head">Debiting App</p>
            <div className="step3-each-card">
              <div>
                <img
                  className="step3-each-card-img"
                  src={
                    Object.keys(confirmationData).length > 0
                      ? confirmationData.appData.app_icon
                      : ''
                  }
                  alt=""
                />
              </div>
              <p className="step3-each-card-text">
                {Object.keys(confirmationData).length > 0
                  ? confirmationData.appData.app_name
                  : ''}
              </p>
            </div>
            <p className="step3-each-card-head">Debiting Vault</p>
            <div className="step3-each-card">
              <div>
                <img
                  className="step3-each-card-img"
                  src={
                    Object.keys(confirmationData).length > 0
                      ? confirmationData.coinsData.fromCoinData.icon
                      : ''
                  }
                  alt=""
                />
              </div>
              <p className="step3-each-card-text">
                Liquid{' '}
                {Object.keys(confirmationData).length > 0
                  ? confirmationData.coinsData.fromCoinData.name
                  : ''}
              </p>
            </div>
            <p className="step3-each-card-head">Destination</p>
            <div className="step3-each-card">
              <div>
                <img
                  className="step3-each-card-img"
                  src={
                    Object.keys(confirmationData).length > 0
                      ? confirmationData.coinsData.fromCoinData.icon
                      : ''
                  }
                  alt=""
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p className="step3-each-card-text1">
                  External{' '}
                  {Object.keys(confirmationData).length > 0
                    ? confirmationData.coinsData.fromCoinData.coin
                    : ''}{' '}
                  Address
                </p>
                <p className="step3-each-card-text2">{addressTerm}</p>
              </div>
            </div>
            <p className="step3-each-card-head">Debiting Amount</p>
            <div className="from-to-inputs">
              <div className="div-from-input">
                <input
                  className="from-input"
                  placeholder="0.00"
                  key="from-2"
                  id="from-2"
                  type="text"
                  value={
                    Object.keys(confirmationData).length > 0
                      ? confirmationData.finalFromAmount
                      : ''
                  }
                  disabled
                  onChange={(e) => validateText(e.target.value)}
                />
                {/*have to take a look*/}
                <div className="from-coin" key="from-coin">
                  <img
                    className="coinimgsmall"
                    src={selectedCoin.coinImage}
                    alt=""
                  />
                  <p className="coinsymbolsmall">
                    {selectedCoin.coinSymbol} &nbsp;&gt;
                  </p>
                </div>
              </div>
              <div className="div-border"></div>
              <div className="div-to-input">
                <input
                  className="from-input"
                  placeholder="0.00"
                  key="to-2"
                  id="to-2"
                  type="text"
                  value={toTerm}
                  disabled
                />
                <div className="from-coin" key="to-coin">
                  <img
                    className="coinimgsmall"
                    src={selectedCoin.coinImage}
                    alt=""
                  />
                  <p className="coinsymbolsmall">{appCurrencyName}</p>
                </div>
              </div>
            </div>
            <p className="step3-each-card-text3">
              Exchange Rate Booked At
              <span className="step3-each-card-text4">
                {' '}
                {Object.keys(confirmationData).length > 0
                  ? getDateTime(confirmationData.currentTimestamp)
                  : ''}
              </span>
            </p>
            <p className="step3-each-card-head">PriceLock Insurance</p>
            <div className="step3-each-card">
              <div>
                <img className="step3-each-card-img" src={picelock} alt="" />
              </div>
              <p className="step3-each-card-text">Disabled</p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '25px',
              }}
            >
              <p className="step3-subhead">Fees</p>
              <p className="step3-subhead">
                {Object.keys(confirmationData).length > 0
                  ? handleDecimal(confirmationData.reducedAmount)
                  : ''}{' '}
                {Object.keys(confirmationData).length > 0
                  ? confirmationData.fees_in_coin
                  : ''}{' '}
                (
                {Object.keys(confirmationData).length > 0
                  ? confirmationData.percentage_fee_by_volume.fees_with_broker.toFixed(
                      2
                    )
                  : ''}
                %)
              </p>
            </div>
            <div className="feescard-1">
              <div className="fees-card-content">
                <p className="fee-text1">
                  Affiliate{' '}
                  <span>
                    <img src={info} alt="" width={10} />
                  </span>
                </p>
                <p className="fee-text2">
                  {Object.keys(confirmationData).length > 0
                    ? handleDecimal(confirmationData.brokerData.fee)
                    : ''}{' '}
                  (
                  {Object.keys(confirmationData).length > 0
                    ? confirmationData.brokerData.broker_fee_percentage.toFixed(
                        2
                      )
                    : ''}
                  %)
                </p>
              </div>
              <div className="fees-card-content">
                <p className="fee-text1">
                  App{' '}
                  <span>
                    <img src={info} alt="" width={10} />
                  </span>
                </p>
                <p className="fee-text2">
                  {Object.keys(confirmationData).length > 0
                    ? handleDecimal(confirmationData.appData.app_fee)
                    : ''}{' '}
                  (
                  {Object.keys(confirmationData).length > 0
                    ? confirmationData.appData.app_fee_percentage.toFixed(2)
                    : ''}
                  %)
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '20px 0 20px 0',
                }}
              >
                <p className="fee-text1">
                  Banker{' '}
                  <span>
                    <img src={info} alt="" width={10} />
                  </span>
                </p>
                <p className="fee-text2">
                  {Object.keys(confirmationData).length > 0
                    ? handleDecimal(
                        confirmationData.bankerData.trade_fee_native
                      )
                    : ''}{' '}
                  (
                  {Object.keys(confirmationData).length > 0
                    ? confirmationData.bankerData.banker_fee_percentage.toFixed(
                        2
                      )
                    : ''}
                  %)
                </p>
              </div>
            </div>
            <p className="step3-each-card-head">Receiving Amount</p>
            <div className="from-to-inputs">
              <div className="div-from-input">
                <input
                  className="from-input"
                  placeholder="0.00"
                  key="from-2"
                  id="from-2"
                  type="text"
                  value={
                    Object.keys(confirmationData).length > 0
                      ? confirmationData.finalToAmount
                      : ''
                  }
                  disabled
                  onChange={(e) => validateText(e.target.value)}
                />
                <div className="from-coin" key="from-coin">
                  <img
                    className="coinimgsmall"
                    src={selectedCoin.coinImage}
                    alt=""
                  />
                  <p className="coinsymbolsmall">
                    {selectedCoin.coinSymbol} &nbsp;&gt;
                  </p>
                </div>
              </div>
              <div className="div-border"></div>
              <div className="div-to-input">
                <input
                  className="from-input"
                  placeholder="0.00"
                  key="to-2"
                  id="to-2"
                  type="text"
                  value={toTerm}
                  disabled
                />
                <div className="from-coin" key="to-coin">
                  <img
                    className="coinimgsmall"
                    src={selectedCoin.coinImage}
                    alt=""
                  />
                  <p className="coinsymbolsmall">{appCurrencyName} </p>
                </div>
              </div>
            </div>
            <p className="step3-each-card-text3">
              Exchange Rate Booked At
              <span className="step3-each-card-text4">
                {' '}
                {Object.keys(confirmationData).length > 0
                  ? getDateTime(confirmationData.currentTimestamp)
                  : ''}
              </span>{' '}
              - Since You Have Not Turned On PriceLock Insurance Then The
              {appCurrencyName} Valuation Of Your Bitcoin Will Be Different Upon
              Arrival.
            </p>
            <p className="step3-each-card-head">Estimated Time Of Arrival</p>
            <div className="step3-each-card">
              <div className="timebox-div">
                <img className="step3-each-card-img" src={timebox} alt="" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p className="step3-each-card-text1">
                  {Object.keys(confirmationData).length > 0
                    ? getTime(confirmationData.currentTimestamp)
                    : ''}{' '}
                  (12 Hours)
                </p>
                <p className="step3-each-card-text2">
                  {Object.keys(confirmationData).length > 0
                    ? getYear(confirmationData.currentTimestamp)
                    : ''}
                </p>
              </div>
            </div>
            <p className="step3-each-card-head">Latest Time Of Arrival</p>
            <div className="step3-each-card">
              <div className="timebox-div">
                <img className="step3-each-card-img" src={timebox} alt="" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p className="step3-each-card-text1">
                  {Object.keys(confirmationData).length > 0
                    ? getTimeFull(confirmationData.currentTimestamp)
                    : ''}{' '}
                  (24 Hours)
                </p>
                <p className="step3-each-card-text2">
                  {Object.keys(confirmationData).length > 0
                    ? getYear(confirmationData.currentTimestamp)
                    : ''}
                </p>
              </div>
            </div>
            <p className="step3-each-card-head">Confirmations</p>
            <div className="check-div">
              <label>
                <input
                  className="check-box-withdraw"
                  type="checkbox"
                  key="check-1"
                  id="check-1"
                  checked={checkedOne}
                  onChange={handleChangeOne}
                />
                <p style={{ paddingLeft: '30px', marginTop: '-35px' }}>
                  {
                    ' I Consent To All The Aforementioned Details Regarding This Transfer Request.'
                  }
                </p>
              </label>
            </div>
            <div className="check-div">
              <label>
                <input
                  className="check-box-withdraw-2"
                  type="checkbox"
                  key="check-2"
                  id="check-2"
                  checked={checkedTwo}
                  onChange={handleChangeTwo}
                />
                <p style={{ paddingLeft: '30px', marginTop: '-35px' }}>
                  {` I Acknowledge That IndianOTC Is Not Able To Recover Funds Once They Have Sent To`}
                  <span style={{ fontWeight: '700' }}> {addressTerm}</span>
                </p>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
