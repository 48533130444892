import React, { useContext, useEffect } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import classNames from './vaultsPage.module.scss';
import Layout from '../../Layout/Layout';
import { BankContext } from '../../context/Context';
import { useUserApps } from '../../queryHooks';
import VaultPageContextProvider, {
  VaultPageContext,
} from '../../context/VaultPageContext';
import VaultControllPanel from '../../components/VaultControllPanel';
import VaultControllPanelForex from '../../components/VaultControllPanelForex';
import { useAppContextDetails } from '../../context/AppContext';
import { VaultComponent } from '../../components/VaultsPage/VaultComponent';
import { Vaults } from '../../components/VaultsPage/Vaults';
import {
  DealsContentPage,
  SwithNavbar,
  TopNavbar,
  VaultDetailedSection,
} from './SeperateComponents';

function DealsPage() {
  return (
    <VaultPageContextProvider>
      <VaultsPageIn />
    </VaultPageContextProvider>
  );
}

function VaultsPageIn() {
  const { email } = useContext(BankContext);
  const { appSelected, setAppSelected, cpanelOpen, setCpanelOpen } =
    useContext(VaultPageContext);
  const { data: appList = [] } = useUserApps(email);
  const { appCodeParam, coinParam } = useParams();
  const { appCode } = useAppContextDetails();

  useEffect(() => {
    let appSelect;
    if (appCodeParam) {
      appSelect = appList.filter((app) => app.app_code === appCodeParam)[0];
    } else {
      appSelect = appList.filter((app) => app.app_code === appCode)[0];
    }
    if (appSelect) {
      setAppSelected(appSelect);
    }
  }, [appList]);

  if (!email) {
    return <Redirect to="/" />;
  }

  function getCpanel() {
    switch (cpanelOpen) {
      case 'moneyMarkets':
        return <VaultControllPanel onClose={() => setCpanelOpen(false)} />;
      case 'fiat':
        return (
          <VaultControllPanelForex
            onClose={() => setCpanelOpen(false)}
            appSelected={appSelected}
          />
        );
      default:
        return false;
    }
  }

  return (
    <Layout
      active="deals"
      className={classNames.vaults}
      hideFooter
      sidebarCustom={getCpanel()}
    >
      {/* {coinParam ? <VaultComponent /> : <Vaults />} */}
      <div style={{ width: '100%', height: '100%' }}>
        <TopNavbar />
        <SwithNavbar />
        <DealsContentPage />
      </div>
    </Layout>
  );
}

export default DealsPage;
