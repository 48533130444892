import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import './withdrawSidebar.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import { useCoinContextData } from '../../../context/CoinContext';
import { useAppContextDetails } from '../../../context/AppContext';
import { BankContext } from '../../../context/Context';
import { useUserApps } from '../../../queryHooks';
import { APP_USER_TOKEN } from '../../../config';
import {
  getPathId,
  quickConvertCurrency,
  useSendVerificationEmail,
  withdrawalwithStats,
} from '../api';
import { EnterAddress } from './EnterAddress';
import { EnterAmount } from './EnterAmount';
import { WithdrawalConfirmation } from './WithdrawalConfirmation';
import { EmailVerification } from './EmailVerification';
import { WithdrawSuccess } from './WithdrawSuccess';
import { updateScroll } from '../../../config/constants';

function WithdrawSideBar() {
  const { appCode, appCurrencySymbol } = useAppContextDetails();
  const {
    coinType,
    setCoinAction,
    selectedCoin,

    // Withdraw actions
    step,
    setStep,
    addressTerm,
    fromTerm,
    setFromTerm,
    setToTerm,
    initiateWithdraw,
    setInitiateWithdraw,
    isWithdrawConfirmed,
    sendVerificationMail,
    setSendVerificationMail,
    otp,
    setAddressTerm,
  } = useCoinContextData();
  const { email } = useContext(BankContext);
  const userIdToken = Cookies.get(APP_USER_TOKEN);

  const [check, setCheck] = useState(false);
  const [confirmationData, setConfirmationData] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [isValueLoading, setIsValueLoading] = useState(false);

  const { data: userApps } = useUserApps(email);
  const userAppsData = userApps?.filter((e) => e.app_code === appCode);
  const userProfileId = userAppsData?.[0]?.profile_id;

  const { mutate: verifyEmail } = useSendVerificationEmail(
    setSendVerificationMail
  );

  useEffect(() => {
    if (step === 4 && sendVerificationMail) {
      const verificationApiObject = {
        email,
        token: userIdToken,
        app_code: appCode,
        coin: selectedCoin.coinSymbol,
        amount: fromTerm,
        coin_address: selectedCoin.coin_address,
      };
      verifyEmail(verificationApiObject);
    }
  }, [step, sendVerificationMail]);

  const handleCurrencyConversion = (fromValue) => {
    setIsValueLoading(true);
    quickConvertCurrency(selectedCoin.coinSymbol, appCurrencySymbol).then(
      (response) => {
        const result = response?.data;
        const fromCoin = selectedCoin.coinSymbol.toLowerCase();
        const toCoin = appCurrencySymbol.toLowerCase();
        if (result.status) {
          const conversionRate = result[`${fromCoin}_${toCoin}`];
          const calculatedToValue = (fromValue * conversionRate).toFixed(5);
          setToTerm(calculatedToValue);
        }
        setIsValueLoading(false);
      }
    );
  };

  const validateText = (text) => {
    let floatValues = /[+-]?([0-9]*[.])?[0-9]+/;
    if (text.match(floatValues) && !isNaN(text)) {
      handleWithdrawInput(text);
    } else {
      let value = text.substring(0, text.length - 1);
      handleWithdrawInput(value);
    }
  };

  const handleWithdrawInput = (text) => {
    let t = text;
    let newValue = (t === '' ? '0.00' : t !== '' && t.indexOf('.') >= 0)
      ? t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), 5)
      : t;
    setFromTerm(newValue);
    handleCurrencyConversion(newValue);
  };

  useEffect(() => {
    if (coinType !== 'crypto') {
      setCoinAction(['Menu']);
    }
  }, [coinType]);

  const handleBreadCrumb = () => {
    switch (step) {
      case 1: {
        setCoinAction(['Menu']);
        updateScroll();
        break;
      }
      case 2: {
        setStep(1);
        setAddressTerm('');
        updateScroll();
        break;
      }
      case 3: {
        setStep(2);
        setFromTerm('');
        setToTerm('');
        updateScroll();
        break;
      }
      case 4: {
        setStep(3);
        updateScroll();
        break;
      }
      case 5: {
        setStep(4);
        updateScroll();
        break;
      }
      default:
    }
  };

  useEffect(() => {
    let obj = {
      select_type: 'withdraw',
      to_currency: selectedCoin.coinSymbol,
      from_currency: selectedCoin.coinSymbol,
      paymentMethod: 'vault',
    };
    getPathId(obj)
      .then((response) => {
        let result = response.data;
        localStorage.setItem('pathId', result.paths[0].path_id);
      })
      .catch((err) => console.log(err));
  }, []);

  const InitiateWithdraw = (isConfirmed) => {
    if (!check && fromTerm) {
      const code = isConfirmed ? { ext_verify_code: otp } : {};
      setIsloading(true);
      let identifier = `Withdraw ${selectedCoin.coinSymbol} To ${addressTerm}`;
      let obj = {
        token: userIdToken,
        email,
        app_code: appCode,
        profile_id: userProfileId,
        path_id: localStorage.getItem('pathId'),
        from_amount: fromTerm,
        stats: !isConfirmed,
        identifier,
        userWithdrawData: addressTerm,
        priceLock: false,
        priceLock_currency: 'false',
        ...code,
      };

      withdrawalwithStats(obj)
        .then((response) => {
          let result = response.data;
          setConfirmationData(result);
          setIsloading(false);
          setInitiateWithdraw(false);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (initiateWithdraw && fromTerm) {
      InitiateWithdraw(isWithdrawConfirmed);
    }
  }, [initiateWithdraw]);

  switch (step) {
    case 1:
      return <EnterAddress handleBreadCrumb={handleBreadCrumb} />;
    case 2:
      return (
        <EnterAmount
          handleBreadCrumb={handleBreadCrumb}
          validateText={validateText}
          isValueLoading={isValueLoading}
          check={check}
          setCheck={setCheck}
          handleCurrencyConversion={handleCurrencyConversion}
        />
      );
    case 3:
      return (
        <WithdrawalConfirmation
          handleBreadCrumb={handleBreadCrumb}
          confirmationData={confirmationData}
          validateText={validateText}
          isLoading={isLoading}
        />
      );
    case 4:
      return <EmailVerification handleBreadCrumb={handleBreadCrumb} />;
    case 5:
      return (
        <WithdrawSuccess
          isLoading={isLoading}
          confirmationData={confirmationData}
        />
      );
    default:
      return <></>;
  }
}

export default WithdrawSideBar;
