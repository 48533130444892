import React, { useEffect } from 'react';
import './capitalized.scss';
import Layout from '../../Layout/Layout';
import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import { planBContext } from '../../context/PlanBContext';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import axios from 'axios';
import {
  InputDivs,
  InputDivs1,
  InputDivs2,
  InputDivs3,
  ImageUploadDiv,
} from '../../components/BuyBlocks/components/CreatePlanB';
import { uploadImageFunc } from '../../utils/imageUpload';
import { LoadingAnimation } from '../../components/LoadingAnimation';

import fulllogo from '../../static/images/capitalized/logo.svg';
import search from '../../static/images/malls/search.svg';
import close from './close.svg';
import dummy from './dummyImg.svg';
import upload from './upload.svg';
import logo from './logo.svg';
import cover from './cover.svg';

const CapitalizedPage = () => {
  const { ProTab, setProTab, appLogo, setSearchedValue, searchedValue } =
    useAppContextDetails();
  const { email, token } = useContext(BankContext);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [companiesData, setCompaniesData] = useState([]);
  const [actionEnabled, setActionEnabled] = useState(false);
  const [offeringData, setOfferingData] = useState([]);
  const [entrepreneurEnabled, setEntrepreneurEnabled] = useState(false);
  const [entrepreneurStep, setEntrepreneurStep] = useState(1);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [userName, setUserName] = useState();
  const [userPic, setUserPic] = useState();
  const [entrepreneurLoading, setEntrepreneurLoading] = useState(false);
  const [corporateBrandEnabled, setCorporateBrandEnabled] = useState(false);
  const [corporateBrandStep, setCorporateBrandStep] = useState(1);
  const [coverPhoto, setCoverPhoto] = useState();
  const [logo, setLogo] = useState();
  const [brandName, setBrandName] = useState();
  const [countryCode, setCountryCode] = useState();
  const [country, setCountry] = useState();
  const [contactNo, setContactNo] = useState();
  const [foundedDate, setFoundedDate] = useState();
  const [colorCode, setColorCode] = useState();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://counsel.apimachine.com/api/getchecklist`)
      .then((response) => {
        let result = response?.data?.data;
        // console.log(result, 'companiesData result');
        setCompaniesData(result);
        setLoading(false);
      });
  }, []);

  // useEffect(() => {
  //   setLoading1(true);
  //   axios
  //     .get(
  //       `https://comms.globalxchange.io/coin/investment/path/get?path_status=active&banker_email=${email}&investmentType=EQT`
  //     )
  //     .then((response) => {
  //       let result = response?.data?.paths;
  //       // console.log(result, "offeringData result");
  //       setOfferingData(result);
  //       setLoading1(false);
  //     });
  // }, []);

  
  useEffect(() => {
    setSearchedValue('');
  }, [ProTab]);
  const myTimeout = () => {
    setTimeout(reload, 3000);
  };

  function reload() {
    window.location.reload();
  }

  const createEntrepreneurialProfile = () => {
    setEntrepreneurLoading(true);
    axios
      .post(
        'https://teller2.apimachine.com/lxUser/register',
        {
          profilePicURL: userPic,
          firstName: firstName,
          lastName: lastName,
          lxTag: userName,
        },
        { headers: { email, token } }
      )
      .then((response) => {
        let result = response?.data;
        console.log(result, 'createEntrepreneurialProfile result');
        if (result?.status) {
          setEntrepreneurLoading(false);
          setEntrepreneurStep(2);
          myTimeout();
        } else {
          setEntrepreneurLoading(false);
        }
      })
      .catch((error) => {
        console.log(error, 'error in createEntrepreneurialProfile');
      });
  };

  return (
    <Layout active="prospectus" className="Prospectus" hideFooter>
      <div style={{ width: '100%', height: '100%' }}>
        <div className="capitalized-navbar">
          <div className="capitalized-icon">
            <img src={fulllogo} alt="" />
          </div>
          <div className="search-capitalized">
            <div className="im">
              <input
                type="text"
                placeholder="Im Looking For..."
                value={searchedValue}
                onChange={(event) => setSearchedValue(event?.target?.value)}
              />
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={search} alt="" />
              </div>
            </div>
          </div>
          <div
            className="capitalized-listBtn"
            onClick={() => {
              setActionEnabled(!actionEnabled);
            }}
          >
            Popular Actions
          </div>
        </div>

        <div className="w-space">
          <div className="optionss" style={{ width: '22%' }}>
            <div
              className={`each-option ${
                ProTab === 'Checklist' ? 'each-option-selected' : ''
              }`}
              style={{
                fontWeight: ProTab === 'Checklist' ? '600' : '',
              }}
              onClick={() => setProTab('Checklist')}
            >
              <div>
                <p>Checklist</p>
              </div>
            </div>

            <div
              className={`each-option ${
                ProTab === 'Profile' ? 'each-option-selected' : ''
              }`}
              style={{
                fontWeight: ProTab === 'Profile' ? '600' : '',
              }}
              // onClick={() => setProTab('Profile')}
            >
              <div>
                <p>Profile</p>
              </div>
            </div>
          </div>
        </div>

        <div className="capitalized-content">
          {ProTab === 'Checklist' ? (
            <>
              <div className="companyHeader">
                <div style={{ width: '14%' }}>Name</div>
                <div style={{ width: '14%' }}>Category</div>
                <div style={{ width: '30%' }}>Description</div>
                <div style={{ width: '14%' }}>Status</div>
                <div style={{ width: '14%' }}>Price</div>
                <div style={{ width: '14%' }}></div>
              </div>
              <div className="companyContent">
                {loading
                  ? Array(10)
                      .fill('')
                      .map((item, index) => {
                        return (
                          <div className="eachCompany" key={index}>
                            <div
                              className="companyDetails"
                              style={{ width: '14%' }}
                            >
                              <div className="nameDetails">
                                <div>
                                  <Skeleton width={100} height={12} />
                                </div>
                                <div>
                                  <Skeleton width={60} height={12} />
                                </div>
                              </div>
                            </div>
                            <div style={{ width: '14%' }}>
                              <Skeleton width={60} height={25} />
                            </div>
                            <div style={{ width: '30%' }}>
                              <Skeleton width={60} height={25} />
                            </div>
                            <div style={{ width: '14%' }}>
                              <Skeleton width={60} height={25} />
                            </div>
                            <div style={{ width: '14%' }}>
                              <Skeleton width={60} height={25} />
                            </div>
                            <div style={{ width: '14%' }}>
                              <Skeleton width={60} height={25} />
                            </div>
                          </div>
                        );
                      })
                  : companiesData
                      ?.filter((app) =>
                        app?.name
                          ?.toLowerCase()
                          .includes(searchedValue.toLowerCase())
                      )
                      ?.map((e, i) => {
                        return (
                          <div className="eachCompany" key={i}>
                            <div style={{ width: '14%', fontWeight: '600' }}>
                              {e?.name}
                            </div>
                            <div style={{ width: '14%', fontWeight: '600' }}>
                              {e?.category}
                            </div>
                            <div
                              style={{
                                width: '30%',
                                fontWeight: '400',
                                fontSize: '10px',
                              }}
                            >
                              {e?.description}
                            </div>

                            <div style={{ width: '14%', fontWeight: '600' }}>
                              {e?.status}
                            </div>
                            <div style={{ width: '14%', fontWeight: '600' }}>
                              {e?.price}
                            </div>
                            <div style={{ width: '14%', fontWeight: '600' }}>
                              <label htmlFor="">Upload</label>
                            </div>
                          </div>
                        );
                      })}
              </div>
            </>
          ) : (
            <>
              <div className="companyHeader">
                <div style={{ width: '25%' }}>Name</div>
                <div style={{ width: '20%' }}>Price</div>
                <div style={{ width: '20%' }}>Market Cap</div>
                <div style={{ width: '20%' }}>Supply</div>
                <div style={{ width: '15%' }}>Shareholders</div>
              </div>
              <div className="offeringsContent">
                {loading1
                  ? Array(10)
                      .fill('')
                      .map((item, index) => {
                        return (
                          <div className="eachOffering" key={index}>
                            <div className="OfferingDetails">
                              <div>
                                <Skeleton
                                  width={40}
                                  height={40}
                                  borderRadius={50}
                                />
                              </div>
                              <div>
                                <div>
                                  <Skeleton width={100} height={15} />
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                  }}
                                >
                                  <div>
                                    <Skeleton
                                      width={15}
                                      height={15}
                                      borderRadius={50}
                                    />
                                  </div>
                                  <div style={{ fontSize: '0.9rem' }}>
                                    <Skeleton width={70} height={15} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style={{ width: '20%' }}>
                              <Skeleton width={100} height={20} />
                            </div>
                            <div style={{ width: '20%' }}>
                              <Skeleton width={100} height={20} />
                            </div>
                            <div style={{ width: '20%' }}>
                              <Skeleton width={100} height={20} />
                            </div>
                            <div style={{ width: '15%' }}>
                              <Skeleton width={100} height={20} />
                            </div>
                          </div>
                        );
                      })
                  : offeringData?.map((e, i) => {
                      return (
                        <div className="eachOffering" key={i}>
                          <div className="OfferingDetails">
                            <div>
                              <img
                                src={e?.token_profile_data?.coinImage}
                                alt=""
                                className="img1"
                              />
                            </div>
                            <div>
                              <div style={{ fontWeight: '500' }}>
                                {e?.token_profile_data?.coinName} (
                                {e?.token_profile_data?.coinSymbol})
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '5px',
                                }}
                              >
                                <div>
                                  <img
                                    src={e?.apps_metadata?.[0]?.app_icon}
                                    alt=""
                                    className="img2"
                                  />
                                </div>
                                <div style={{ fontSize: '0.9rem' }}>
                                  {e?.apps_metadata?.[0]?.app_name}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{ width: '20%', fontWeight: '100' }}>
                            {e?.token_price?.toFixed(4)}
                          </div>
                          <div style={{ width: '20%', fontWeight: '100' }}>
                            {e?.asset_balance?.toFixed(4)}
                          </div>
                          <div style={{ width: '20%', fontWeight: '100' }}>
                            {e?.token_totalSupply?.toFixed(4)}
                          </div>
                          <div style={{ width: '15%', fontWeight: '100' }}>
                            {e?.tokenHoldersCount}
                          </div>
                        </div>
                      );
                    })}
              </div>
            </>
          )}
        </div>

        {actionEnabled && (
          <div className="popular">
            <div className="head-txt">
              <div>Popular Actions</div>
              <div
                onClick={() => {
                  setActionEnabled(false);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <div
              className="each-action"
              onClick={() => {
                setActionEnabled(false);
                setEntrepreneurEnabled(true);
              }}
            >
              <div>Become An Entrepreneur</div>
            </div>
            <div
              className="each-action"
              onClick={() => {
                setActionEnabled(false);
                setCorporateBrandEnabled(true);
              }}
            >
              <div>Create Corporate Brand</div>
            </div>
            <div className="each-action">
              <div>Add Your Company</div>
            </div>
            <div className="each-action">
              <div>Issue A ShareToken</div>
            </div>
          </div>
        )}

        {entrepreneurEnabled && (
          <div className="popular">
            {entrepreneurStep === 1 && (
              <>
                <div className="head-txt">
                  <div>Entrepreneur Profile</div>
                  <div
                    onClick={() => {
                      setEntrepreneurEnabled(false);
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div className="overall-div">
                  <InputDivs
                    heading="What is the founders first name?"
                    placeholderText="First Name"
                    setFunc={setFirstName}
                    funcValue={firstName}
                  />
                  <InputDivs
                    heading="What is the founders last name?"
                    placeholderText="Last Name"
                    setFunc={setLastName}
                    funcValue={lastName}
                  />
                  <InputDivs1
                    heading="Create an username for founder"
                    placeholderText="Username"
                    setFunc={setUserName}
                    funcValue={userName}
                  />
                  <div
                    style={{
                      marginTop: '2rem',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.7rem',
                    }}
                  >
                    <div style={{ fontSize: '1.1rem' }}>
                      Upload a picture of the founder
                    </div>
                    <ImageUploadDivProfilePic
                      setFunc={setUserPic}
                      funcValue={userPic}
                    />
                  </div>
                </div>
                <div className="stepBtns">
                  <div
                    style={{ background: '#5F6163' }}
                    onClick={() => {
                      setEntrepreneurEnabled(false);
                      setActionEnabled(true);
                      setUserPic('');
                      setUserName('');
                      setLastName('');
                      setFirstName('');
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: '#4CAF50',
                      opacity:
                        userName && userPic && firstName && lastName
                          ? '1'
                          : '0.25',
                      cursor:
                        userName && userPic && firstName && lastName
                          ? 'pointer'
                          : 'not-allowed',
                    }}
                    onClick={() => {
                      if (userName && userPic && firstName && lastName) {
                        createEntrepreneurialProfile();
                      }
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {entrepreneurLoading && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%', height: '100%' }}
              >
                <LoadingAnimation icon={appLogo} width={200} />
              </div>
            )}

            {entrepreneurStep === 2 && (
              <div className="successMsgg">
                Entrepreneur Profile Successfully Created
              </div>
            )}
          </div>
        )}

        {corporateBrandEnabled && (
          <div className="popular1">
            {corporateBrandStep === 1 && (
              <>
                <div className="head-txt">
                  <div>Corporate Brand</div>
                  <div
                    onClick={() => {
                      setCorporateBrandEnabled(false);
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div className="overall-div">
                  <div className="subbTxt">
                    What country is your company headquartered in?
                  </div>
                  <div className="each-action">
                    <img src={dummy} alt="" />
                    <div>India</div>
                  </div>
                  <div className="each-action">
                    <img src={dummy} alt="" />
                    <div>India</div>
                  </div>
                  <div className="each-action">
                    <img src={dummy} alt="" />
                    <div>India</div>
                  </div>
                  <div className="each-action">
                    <img src={dummy} alt="" />
                    <div>India</div>
                  </div>
                  <div className="each-action">
                    <img src={dummy} alt="" />
                    <div>India</div>
                  </div>
                  <div className="each-action">
                    <img src={dummy} alt="" />
                    <div>India</div>
                  </div>
                </div>
              </>
            )}

            {corporateBrandStep === 2 && (
              <>
                <div className="head-txt">
                  <div>Corporate Brand</div>
                  <div
                    onClick={() => {
                      setCorporateBrandEnabled(false);
                      setBrandName('');
                      setCountryCode('');
                      setCountry('');
                      setContactNo('');
                      setFoundedDate('');
                      setColorCode('');
                      setCoverPhoto('');
                      setLogo('');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div className="overall-div">
                  <div className="coverPic-container">
                    <div className="coverPicDiv">
                      <ImageUploadDivCoverPic
                        setFunc={setCoverPhoto}
                        funcValue={coverPhoto}
                      />
                    </div>
                    <div className="logoDiv">
                      <ImageUploadDivLogo setFunc={setLogo} funcValue={logo} />
                    </div>
                  </div>
                  <div className="inputs-container">
                    <InputDivs
                      heading="What is your company’s brand name?"
                      placeholderText="Brand Name"
                      setFunc={setBrandName}
                      funcValue={brandName}
                    />
                    <InputDivs3
                      heading="What is the primary contact number for your company?"
                      placeholderText="+91"
                      setFunc={setCountryCode}
                      funcValue={countryCode}
                      placeholderText1="India"
                      setFunc1={setCountry}
                      funcValue1={country}
                      placeholderText2="000-000-0000"
                      setFunc2={setContactNo}
                      funcValue2={contactNo}
                    />
                    <InputDivs
                      heading="When was the company founded?"
                      placeholderText="Enter date"
                      setFunc={setFoundedDate}
                      funcValue={foundedDate}
                    />
                    <InputDivs2
                      heading="What is your company’s colour code?"
                      placeholderText="#000000"
                      setFunc={setColorCode}
                      funcValue={colorCode}
                    />
                    <div className="stepBtns">
                      <div
                        style={{ background: '#5F6163' }}
                        onClick={() => {
                          setCorporateBrandEnabled(false);
                          setActionEnabled(true);
                          setBrandName('');
                          setCountryCode('');
                          setCountry('');
                          setContactNo('');
                          setFoundedDate('');
                          setColorCode('');
                          setCoverPhoto('');
                          setLogo('');
                        }}
                      >
                        Go Back
                      </div>
                      <div
                        style={{
                          background: '#4CAF50',
                          opacity:
                            brandName &&
                            countryCode &&
                            country &&
                            contactNo &&
                            foundedDate &&
                            colorCode &&
                            coverPhoto &&
                            logo
                              ? '1'
                              : '0.25',
                          cursor:
                            brandName &&
                            countryCode &&
                            country &&
                            contactNo &&
                            foundedDate &&
                            colorCode &&
                            coverPhoto &&
                            logo
                              ? 'pointer'
                              : 'not-allowed',
                        }}
                        // onClick={() => {
                        //   if (brandName &&
                        //     countryCode &&
                        //     country &&
                        //     contactNo &&
                        //     foundedDate &&
                        //     colorCode &&
                        //     coverPhoto &&
                        //     logo) {
                        //     createEntrepreneurialProfile();
                        //   }
                        // }}
                      >
                        Next Step
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {corporateBrandStep === 3 && (
              <div className="successMsgg">
                Corporate Brand Successfully Created
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default CapitalizedPage;

export const ImageUploadDivProfilePic = ({ setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(planBContext);

  return (
    <div
      className="imageUploadDiv"
      onClick={() => setSelectedDropDown('')}
      style={{
        width: '100px',
        height: '100px',
        border: '0.5px solid #e7e7e7',
        borderRadius: '50%',
      }}
    >
      {funcValue ? (
        <div
          className="imageDiv"
          style={{ height: '100%', width: '100%', marginRight: '0' }}
        >
          <img
            src={funcValue}
            alt="planBAccountPic"
            className="profileImg"
            htmlFor="profileUpdateImgPlanB"
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      ) : (
        <label
          htmlFor="profileUpdateImgPlanB"
          className="uploadFileDiv"
          style={{
            width: '100%',
            height: '100%',
            marginBottom: '0',
          }}
        >
          <input
            className="uploadNewPicPlanB"
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
            }}
            accept="image/*"
            id="profileUpdateImgPlanB"
          />
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: 'none',
              borderRadius: '50%',
            }}
          >
            {planBAccountPicUploading ? (
              <div>Uploading</div>
            ) : (
              <div>
                <img
                  src={upload}
                  alt=""
                  style={{ width: '50px', height: '50px' }}
                />
              </div>
            )}
          </div>
        </label>
      )}
    </div>
  );
};

export const ImageUploadDivLogo = ({ setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(planBContext);

  return (
    <div
      className="imageUploadDiv"
      onClick={() => setSelectedDropDown('')}
      style={{
        width: '120px',
        height: '120px',
        // border: '0.5px solid #e7e7e7',
        borderRadius: '50%',
      }}
    >
      {funcValue ? (
        <div
          className="imageDiv"
          style={{ height: '100%', width: '100%', marginRight: '0' }}
        >
          <img
            src={funcValue}
            alt="planBAccountPic"
            className="profileImg"
            htmlFor="profileUpdateImgPlanB"
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      ) : (
        <label
          htmlFor="profileUpdateImgPlanB"
          className="uploadFileDiv"
          style={{
            width: '100%',
            height: '100%',
            marginBottom: '0',
          }}
        >
          <input
            className="uploadNewPicPlanB"
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
            }}
            accept="image/*"
            id="profileUpdateImgPlanB"
          />
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: 'none',
              borderRadius: '50%',
            }}
          >
            {planBAccountPicUploading ? (
              <div>Uploading</div>
            ) : (
              <div>
                <img
                  src={logo}
                  alt=""
                  style={{ width: '50px', height: '50px' }}
                />
              </div>
            )}
          </div>
        </label>
      )}
    </div>
  );
};

export const ImageUploadDivCoverPic = ({ setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(planBContext);

  return (
    <div
      className="imageUploadDiv"
      onClick={() => setSelectedDropDown('')}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {funcValue ? (
        <div
          className="imageDiv"
          style={{ height: '100%', width: '100%', marginRight: '0' }}
        >
          <img
            src={funcValue}
            alt="planBAccountPic"
            className="profileImg"
            htmlFor="profileUpdateImgPlanB"
            style={{ width: '100%', height: '100%', borderRadius: '0' }}
          />
        </div>
      ) : (
        <label
          htmlFor="profileUpdateImgPlanB"
          className="uploadFileDiv"
          style={{
            width: '100%',
            height: '100%',
            marginBottom: '0',
          }}
        >
          <input
            className="uploadNewPicPlanB"
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
            }}
            accept="image/*"
            id="profileUpdateImgPlanB"
          />
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: 'none',
            }}
          >
            {planBAccountPicUploading ? (
              <div>Uploading</div>
            ) : (
              <div>
                <img src={cover} alt="" />
              </div>
            )}
          </div>
        </label>
      )}
    </div>
  );
};
