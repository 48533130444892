import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import styles from './vpTemplate.module.scss';
import './selected.css';
import VpTemplateNav from './VpTemplateNav';
// import Scrollspy from "react-scrollspy";

import { IoIosFlash } from 'react-icons/io';
import { GrDocumentText } from 'react-icons/gr';
import { FiStar, FiShare } from 'react-icons/fi';
import companyLogo from '../../static/images/templateImages/companyLogo.svg';
import offeringsThumbnail from '../../static/images/offeringsthumbnail.svg';
import 'reactjs-popup/dist/index.css';
import Pitch from './Pitch';
import Updates from './Updates';
import Discussion from './Discussion';
import Reviews from './Reviews';

import shareTokens from '../../static/images/templateImages/shareToken.svg';
import safeToken from '../../static/images/templateImages/safeTokens.svg';
import csop from '../../static/images/templateImages/csop.svg';
import { GlobalContext } from '../../context/GlobalContext';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';

const VpTemplate = () => {
  const history = useHistory();
  const { offeringsCoin } = useParams();
  const thumbs = [
    'https://uploads.republic.com/p/offerings/slider_media_items/contents/small_2x/000/006/525/6525-1665080822-edb5f04255cbfc113a8a4bba78e25203f6e0bf55.jpg',
    'https://uploads.republic.com/p/offerings/slider_media_items/contents/small_2x/000/006/525/6525-1665080822-edb5f04255cbfc113a8a4bba78e25203f6e0bf55.jpg',
  ];
  const offeringTypes = [
    {
      icon: shareTokens,
      title: 'ShareTokens',
    },
    {
      icon: safeToken,
      title: 'SafeTokens',
    },
    {
      icon: csop,
      title: 'CSOP',
    },
  ];

  const {
    selectedTab,
    setSelectedTab,
    offeringsData,
    setOfferingsData,
    offeringsData2,
    setOfferingsData2,
  } = useContext(GlobalContext);
  const stickyHeaderRef = useRef(null);

  const [stickyTabs, setStickyTabs] = useState(false);
  const [showDesc, setShowDesc] = useState('');
  const [selectedThumb, setSelectedThumb] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedNav, setSelectedNav] = useState('pitch');
  const [selectedAction, setSelectedAction] = useState('Offerings');
  const [hidden, setHidden] = useState(false);
  const { setnewNavbarMenu } = useContext(GlobalContext);
  const [selectedSectionId, setSelectedSectionId] = useState('');

  const [offeringsDataLoading, setOfferingsDataLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setOfferingsDataLoading(true);
        console.log(offeringsCoin, 'offeringsCoin');

        const response1 = await axios.get(
          `https://comms.globalxchange.io/coin/investment/path/get?token=${offeringsCoin}`
        );
        setOfferingsData(response1.data);

        const response2 = await axios.get(
          `https://comms.globalxchange.io/gxb/apps/sharetoken/prospectus?token_symbol=${offeringsCoin}`
        );
        setOfferingsData2(response2.data.data[0]);

        setOfferingsDataLoading(false);
      } catch (error) {
        console.log(error?.message, 'offerings coin API error');
        setOfferingsDataLoading(false);
      }
    };

    fetchData();
  }, [offeringsCoin]);

  function handleMouseLeaveProduct() {
    setnewNavbarMenu('');
  }

  useEffect(() => {
    let elementcontainer = document.getElementById('mainContainer');
    elementcontainer.addEventListener('scroll', handleScrollStick);
    return () => {
      elementcontainer.removeEventListener('scroll', handleScrollStick);
    };
  }, []);

  const handleScrollStick = () => {
    const headerRect = stickyHeaderRef.current.getBoundingClientRect();
    if (headerRect.top > 0) {
      setHidden(false);
    } else {
      setHidden(true);
    }
    // console.log(headerRect.top, 'kjdhkjed');
  };
  const handleScroll = (sectionid) => {
    const element = document.getElementById(sectionid);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  useEffect(() => {
    handleScroll(selectedNav);
  }, [selectedNav]);

  useEffect(() => {
    console.log(selectedSectionId, 'selectedSectionId');
  }, [selectedSectionId]);

  // useEffect(() => {
  //   let element = document.getElementById('stickyTabs');
  //   let elementcontainer = document.getElementById('stickyTabs');
  //   console.log(elementcontainer.scrollTop, 'lknjkwbekjcwe');
  // }, []);

  const conditionalRender = () => {
    if (selectedTab === 'Pitch') {
      return (
        <Pitch
          handleScroll={handleScroll}
          selectedSectionId={selectedSectionId}
          setSelectedSectionId={setSelectedSectionId}
        />
      );
    } else if (selectedTab === 'Discussion') {
      return <Discussion />;
    } else if (selectedTab === 'Updates') {
      return <Updates />;
    } else if (selectedTab === 'Reviews') {
      return <Reviews />;
    }
  };

  const conditionalActionContent = () => {
    switch (selectedAction) {
      case 'Offerings':
        return (
          <div>
            <div className={styles.actionHeader}>Select Offering Type</div>
            {offeringTypes.map((item) => {
              return (
                <div className={styles.offeringItem}>
                  <div>
                    <img src={item.icon} alt="" />
                  </div>
                  <div>{item.title}</div>
                </div>
              );
            })}
            <div className={styles.getStartedBtn}>Get Started</div>
          </div>
        );
      case 'Earn':
        return <div>Earn Action</div>;
      case 'Win':
        return <div>Win Action</div>;
      default:
        break;
    }
  };

  function formatLargeNumber(number) {
    if (number >= 1000000) {
      let formattedNumber = Math.round(number / 100) / 100;
      // console.log('coverting million', formattedNumber);
      if (formattedNumber >= 100) {
        formattedNumber = Math.floor(formattedNumber / 100) + 'M';
      } else {
        formattedNumber += 'K';
      }
      return formattedNumber;
    } else if (number >= 1000) {
      let formattedNumber = number / 1000;
      formattedNumber = formattedNumber?.toFixed(2);
      formattedNumber += 'K';
      return formattedNumber;
    } else {
      return number;
    }
  }

  return (
    <div
      id="mainContainer"
      style={{
        overflowY: 'scroll',
        position: 'relative',
        height: '100%',
        width: '100%',
      }}
      onMouseEnter={handleMouseLeaveProduct}
    >
      <div className={styles.heroParent}>
        <div className={styles.heroContainer}>
          <div className={styles.companyContainer}>
            <div className={styles.companyLogo}>
              {offeringsDataLoading ? (
                <Skeleton height={95} width={95} />
              ) : (
                <img
                  src={
                    offeringsData &&
                    offeringsData?.paths?.length > 0 &&
                    offeringsData?.paths[0]?.token_profile_data?.coinImage
                      ?.length > 0
                      ? offeringsData?.paths[0]?.token_profile_data?.coinImage
                      : companyLogo
                  }
                  alt="company logo"
                />
              )}
            </div>
            <div className={styles.companyName}>
              <div>
                {offeringsDataLoading ? (
                  <Skeleton height={30} width={180} />
                ) : offeringsData &&
                  offeringsData?.paths?.length > 0 &&
                  offeringsData?.paths[0]?.token_profile_data?.coinName
                    ?.length > 0 ? (
                  offeringsData?.paths[0]?.token_profile_data?.coinName
                ) : (
                  'TokenSwap'
                )}
              </div>
              <div className={styles.companyDesc}>
                {offeringsDataLoading ? (
                  <Skeleton height={15} width={80} />
                ) : offeringsData2?.other_data?.tagline ? (
                  offeringsData2?.other_data?.tagline + ' | '
                ) : (
                  ''
                )}
                {''}
                <span
                  className={styles.companyDescInside}
                  onClick={() => {
                    if (offeringsData2?.other_data?.website) {
                      window.open(offeringsData2?.other_data?.website);
                    }
                  }}
                >
                  {offeringsData2?.other_data?.website ? 'Website' : ''}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.categoryGrid}>
            <div>
              {offeringsDataLoading ? (
                <Skeleton height={20} width={20} circle />
              ) : (
                <img
                  src={
                    offeringsData?.investmentSubTypes?.privateequity?.icon
                      ? offeringsData?.investmentSubTypes?.privateequity?.icon
                      : ''
                  }
                  alt=""
                />
              )}
              <span>
                {offeringsDataLoading ? (
                  <Skeleton height={15} width={80} />
                ) : offeringsData?.investmentSubTypes?.privateequity?.name ? (
                  offeringsData?.investmentSubTypes?.privateequity?.name
                ) : (
                  ''
                )}
              </span>
            </div>
            <div>
              {offeringsDataLoading ? (
                <Skeleton height={20} width={20} circle />
              ) : (
                <img
                  src={
                    offeringsData &&
                    offeringsData?.paths?.length > 0 &&
                    offeringsData?.paths[0]?.apps_metadata?.length > 0 &&
                    offeringsData?.paths[0]?.apps_metadata[0] &&
                    offeringsData?.paths[0]?.apps_metadata[0]?.app_icon
                      ? offeringsData?.paths[0]?.apps_metadata[0]?.app_icon
                      : ''
                  }
                  alt=""
                />
              )}
              <span>
                {offeringsDataLoading ? (
                  <Skeleton height={15} width={80} />
                ) : offeringsData &&
                  offeringsData?.paths?.length > 0 &&
                  offeringsData?.paths[0]?.apps_metadata?.length > 0 &&
                  offeringsData?.paths[0]?.apps_metadata[0] &&
                  offeringsData?.paths[0]?.apps_metadata[0]?.app_name ? (
                  offeringsData?.paths[0]?.apps_metadata[0]?.app_name
                ) : (
                  'Fintech'
                )}
              </span>
            </div>
            <div>
              {offeringsDataLoading ? (
                <Skeleton height={20} width={20} circle />
              ) : (
                <img
                  src={
                    offeringsData &&
                    offeringsData?.paths?.length > 0 &&
                    offeringsData?.paths[0]?.asset_metaData?.coinImage
                      ? offeringsData?.paths[0]?.asset_metaData?.coinImage
                      : ''
                  }
                  alt=""
                />
              )}
              <span>
                {offeringsDataLoading ? (
                  <Skeleton height={15} width={80} />
                ) : offeringsData &&
                  offeringsData?.paths?.length > 0 &&
                  offeringsData?.paths[0]?.asset_metaData?.coinName ? (
                  offeringsData?.paths[0]?.asset_metaData?.coinName
                ) : (
                  'Seed Round'
                )}
              </span>
            </div>
            <div>
              {/* <img src="" alt="" /> */}
              <span>
                {offeringsDataLoading ? (
                  <Skeleton height={15} width={80} />
                ) : offeringsData &&
                  offeringsData?.paths?.length > 0 &&
                  offeringsData?.paths[0]?.pathSeries?.length > 0 &&
                  offeringsData?.paths[0]?.pathSeries[0] &&
                  offeringsData?.paths[0]?.pathSeries[0]?.seriesName ? (
                  offeringsData?.paths[0]?.pathSeries[0]?.seriesName
                ) : (
                  ''
                )}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.offeringsVideo}>
          {offeringsData2?.other_data?.explanationvideo ? (
            <ReactPlayer url={offeringsData2?.other_data?.explanationvideo} />
          ) : (
            <img src={offeringsThumbnail} alt="offeringsThumbnail" />
          )}
        </div>
        <div
          className={styles.topSection}
          style={{
            background:
              offeringsData &&
              offeringsData?.paths?.length > 0 &&
              offeringsData?.paths[0]?.token_profile_data?.color_codes?.length >
                0
                ? '#' +
                  offeringsData?.paths[0]?.token_profile_data?.color_codes[0]
                : '',
          }}
        ></div>
      </div>
      <div className={styles.statParent}>
        <div className={styles.statContainer}>
          <div className={styles.statItem}>
            <div>
              {offeringsDataLoading ? (
                <Skeleton height={30} width={150} />
              ) : offeringsData &&
                offeringsData?.paths?.length > 0 &&
                offeringsData?.paths[0]?.tokens_sold ? (
                offeringsData?.paths[0]?.tokens_sold?.toFixed(2)
              ) : (
                '0'
              )}
              {offeringsDataLoading ? '' : ' '}
              {offeringsDataLoading
                ? ''
                : offeringsData &&
                  offeringsData?.paths?.length > 0 &&
                  offeringsData?.paths[0]?.tokens_sold &&
                  offeringsData?.paths[0]?.pathSeries?.length > 0 &&
                  offeringsData?.paths[0]?.pathSeries[0]?.isssuingAmount
                ? `(${
                    (
                      (offeringsData?.paths[0]?.tokens_sold /
                        offeringsData?.paths[0]?.pathSeries[0]
                          ?.isssuingAmount) *
                      100
                    ).toFixed(3) + '%'
                  })`
                : ''}
            </div>
            <div>
              {offeringsDataLoading ? (
                <Skeleton height={20} width={80} />
              ) : offeringsData &&
                offeringsData?.paths?.length > 0 &&
                offeringsData?.paths[0]?.pathSeries?.length > 0 &&
                offeringsData?.paths[0]?.pathSeries[0]?.isssuingAmount ? (
                'Of ' +
                (offeringsData?.paths[0]?.pathSeries[0]?.isssuingAmount +
                  ' Shares Sold')
              ) : (
                '0'
              )}
            </div>
          </div>
          <div className={styles.statItem}>
            <div>
              {offeringsDataLoading ? (
                <Skeleton height={20} width={150} />
              ) : offeringsData &&
                offeringsData?.paths?.length > 0 &&
                offeringsData?.paths[0]?.tokenHoldersCount ? (
                offeringsData?.paths[0]?.tokenHoldersCount
              ) : (
                '0'
              )}
            </div>
            <div>Investors So Far</div>
          </div>
          <div className={styles.statItem}>
            <div>
              {' '}
              {offeringsDataLoading ? (
                <Skeleton height={20} width={150} />
              ) : offeringsData &&
                offeringsData?.paths?.length > 0 &&
                offeringsData?.paths[0]?.capital_raised ? (
                formatLargeNumber(offeringsData?.paths[0]?.capital_raised)
              ) : (
                '0'
              )}
            </div>
            <div>
              {offeringsData &&
              offeringsData?.paths?.length > 0 &&
              offeringsData?.paths[0]?.asset
                ? offeringsData?.paths[0]?.asset
                : ''}{' '}
              Raised So Far
            </div>
          </div>
        </div>
        <progress
          id="file"
          value={
            offeringsData &&
            offeringsData?.paths?.length > 0 &&
            offeringsData?.paths[0]?.tokens_sold
              ? offeringsData?.paths[0]?.tokens_sold
              : ''
          }
          max={
            offeringsData &&
            offeringsData?.paths?.length > 0 &&
            offeringsData?.paths[0]?.pathSeries?.length > 0 &&
            offeringsData?.paths[0]?.pathSeries[0]?.isssuingAmount
              ? offeringsData?.paths[0]?.pathSeries[0]?.isssuingAmount
              : ''
          }
          className={styles.customProgress}
        >
          32%
        </progress>
      </div>

      {/* sticy right side start */}

      {/* <div>
        {selectedTab === 'Pitch' && (
          <div className={styles.stickyRight}>
            <div className={styles.actionTitle}>Own TokenSwap</div>
            <div className={styles.actionGrid}>
              <div
                onClick={(e) => setSelectedAction('Offerings')}
                className={
                  selectedAction === 'Offerings' ? styles.selectedAction : ''
                }
              >
                Offerings
              </div>
              <div
                onClick={(e) => setSelectedAction('Earn')}
                className={
                  selectedAction === 'Earn' ? styles.selectedAction : ''
                }
              >
                Earn
              </div>
              <div
                onClick={(e) => setSelectedAction('Win')}
                className={
                  selectedAction === 'Win' ? styles.selectedAction : ''
                }
              >
                Win
              </div>
            </div>
            {conditionalActionContent()}
          </div>
        )}
      </div> */}

      {/* sticy right side end */}

      {/* Tabs section */}
      <div
        className={styles.stickyHeader}
        id="stickyTabs"
        ref={stickyHeaderRef}
      >
        {hidden && (
          <div id="hidden-div" className={styles.hiddenDiv}>
            <div className={styles.stickeyTopLeft}>
              <div
                className={styles.companyLogo}
                style={{ width: '30px', height: '30px' }}
              >
                <img
                  src={companyLogo}
                  alt="company logo"
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
              <div>TokenSwap</div>
            </div>
            <div className={styles.stickeyTopRight}>
              Private Companies {`>`} Crypto {`>`} TokenSwap
            </div>
          </div>
        )}
        <div
          style={{
            // display: "flex",
            // justifyContent: "space-between",
            // alignItems: "center",
            display: 'grid',
            gridTemplateColumns: '6fr 0.8fr',
            padding: '0px 3rem',
            // paddingLeft: '15vw',
            // paddingRight: '8vw',
            gap: '50px',
          }}
        >
          <div className={styles.tabsContainer}>
            <div
              className={
                selectedTab === 'Pitch' ? styles.selectedTab : styles.tabs
              }
              onClick={(e) => {
                setSelectedTab('Pitch');
              }}
            >
              <div>Pitch</div>
            </div>
            <div
              className={
                selectedTab === 'Discussion' ? styles.selectedTab : styles.tabs
              }
              onClick={(e) => {
                setSelectedTab('Discussion');
              }}
            >
              <div>Discussion</div>
              {/* <div className={styles.numberBadge}>38</div> */}
            </div>
            <div
              className={
                selectedTab === 'Updates' ? styles.selectedTab : styles.tabs
              }
              onClick={(e) => {
                setSelectedTab('Updates');
              }}
            >
              <div>Updates</div>
              {/* <div className={styles.numberBadge}>9</div> */}
            </div>
            <div
              className={
                selectedTab === 'Reviews' ? styles.selectedTab : styles.tabs
              }
              onClick={(e) => {
                setSelectedTab('Reviews');
              }}
            >
              <div>Reviews</div>
              {/* <div className={styles.numberBadge}>38</div> */}
            </div>
          </div>
          <div
            className={styles.backtoTop}
            onClick={(e) => handleScroll('backToTop')}
          >
            Back To Top
          </div>
          {/* <div className={styles.tabInvestButton}>
            Invest in ACFN Franchised Inc.
          </div> */}
        </div>
        {/* <hr style={{ marginTop: '7px', border: '0.5px solid #e7e7e7' }} /> */}
      </div>
      {/* Tab section End */}
      {conditionalRender()}
      {/* popup section */}
      {/* <div className={styles.popupWrapper}>This is a popup</div> */}
    </div>
  );
};

export default VpTemplate;

// old hero section

{
  /* <VpTemplateNav /> */
}
{
  /* <div className={styles.headerContainer}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: '40px', height: '40px', background: '#e7e7e7' }}>
            <img
              src="https://uploads.republic.com/p/offerings/logos/small_2x/000/001/825/1825-1663866126-7f54924d96c9f970d76dc1790e0e5513cc19ff5f.png"
              alt=""
              style={{ width: '100%', height: '100%', borderRadius: '5px' }}
            />
          </div>
          <div className={styles.headerTitle}>ACFN Franchised Inc.</div>
        </div>
        <div className={styles.iconsDiv}>
          <FiStar />
          <FiShare />
        </div>
      </div>
      <div className={styles.headerSubTitle}>
        Creating opportunities for entrepreneurs | Hundreds of franchises to
        date
      </div>
      <div className={styles.badgeContainer}>
        <div
          className={styles.badgeSpecial}
          style={{ background: 'transparent', border: '1px solid #F83A5C' }}
        >
          <IoIosFlash width={20} height={20} />
          &nbsp;&nbsp;SPECIAL
        </div>
        <div>B2B</div>
        <div>SERVICE</div>
        <div>FINTECH & FINANCE</div>
      </div>
      <div className={styles.templateContainer}>
        <div className={styles.leftSide}>
          <div className={styles.slideContainer}>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=AclN7SQUr0U"
              width={'100%'}
            />
          </div>
        </div>
        <div className={styles.rightSide}>
          <div className={styles.totalAmount}>$225,465</div>
          <div className={styles.totalAmountSub}>
            <div>900% of minimum goal raised</div>
            <div className={styles.badgeContainer} style={{ padding: '0px' }}>
              <div>i</div>
            </div>
          </div>
          <div style={{ width: '100%' }}>
            <progress
              id="file"
              value="100"
              max="100"
              style={{
                width: '100%',
                accentColor: '#19c157',
                // borderColor: "red",
                // accentColor: "#19c157",
                // backgroundColor: "lightgray",
              }}
            >
              32%
            </progress>
          </div>
          <div className={styles.statsMobile}>
            <div className={styles.sectionContainer}>
              <div className={styles.title}>205</div>
              <div className={styles.subtitle}>Investors</div>
            </div>
            <hr style={{ border: '1px solid #e7e7e7' }} />
            <div className={styles.sectionContainer}>
              <div className={styles.title}>78 days</div>
              <div className={styles.subtitle}>Left to invest</div>
            </div>
          </div>
          <div className={styles.sectionContainer}>
            <div className={styles.title}>
              <IoIosFlash style={{ color: '#f83a5c' }} />
              $774,534
            </div>
            <div className={styles.subtitle}>Left under special deal terms</div>
          </div>
        </div>
      </div> */
}
