import React, { useContext } from 'react';
import classNames from './seperatecomponents.module.scss';
import Skeleton from 'react-loading-skeleton';

//images
import decksFullLogo from '../../static/images/fullLogo/decksFullLogo.svg';
import supernovaFullLogo from '../../static/images/fullLogo/supernovaFullLogo.svg';
import indianInvestorLogo from '../../static/images/logos/indianinvestor.svg';
import walletIcon from '../../static/images/vaults/wallet.svg';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { switchItems } from './static/mapdata';
import { BsThreeDotsVertical } from 'react-icons/bs';
import affiliateLogo from '../../static/images/deals/affiliateIcon.svg';
import influencerLogo from '../../static/images/deals/influncerIcon.svg';
import ibankerLogo from '../../static/images/deals/ibankerIcon.svg';
import wealthmanagerLogo from '../../static/images/deals/wealthmanagerIcon.svg';
import copyLogo from '../../static/images/deals/copyIcon.svg';
import comsplanIcon from '../../static/images/deals/comsplanIcon.svg';
import trainingIcon from '../../static/images/deals/trainingIcon.svg';
import marketingIcon from '../../static/images/deals/marketingIcon.svg';
import addUser from '../../static/images/deals/addUser.svg';
import sendGift from '../../static/images/deals/sendGift.svg';
import community from '../../static/images/deals/community.svg';
import affiliatebank from '../../static/images/deals/affiliatebank.svg';
import { useEffect } from 'react';
import axios from 'axios';
import { BankContext } from '../../context/Context';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import fulllogo from '../../static/images/offeringsfull.svg';
import dealsfullIcon from '../../static/images/dealsFullIcon.svg';
import search from '../../static/images/malls/search.svg';
import { useAppContextDetails } from '../../context/AppContext';
import { useCoinContextData } from '../../context/CoinContext';
import Card from './components/Cards';

export const TopNavbar = () => {
  const history = useHistory();
  const { email } = useContext(BankContext);
  const { setMarketSearch } = useAppContextDetails();
  const [coinValue, setCoinValue] = useState('');
  const [sharetokenBalance, setsharetokenBalance] = useState([]);
  const [apiDataLoadingCoinValue, setApiDataLoadingCoinValue] = useState(false);

  useEffect(() => {
    getbalance();
  }, []);

  function getbalance() {
    setApiDataLoadingCoinValue(true);

    let obj = {
      app_code: 'indianinvestor',
      email: email,
      include_coins: ['INR'],
    };

    axios
      .post('https://comms.globalxchange.com/coin/vault/service/coins/get', obj)
      .then((response) => {
        if (response?.data?.coins_data?.length > 0) {
            // console.log(response?.data, 'response');
          setCoinValue(response?.data?.coins_data[0]?.coinValue);
          setApiDataLoadingCoinValue(false);
        }
      })
      .catch((error) => {
        setApiDataLoadingCoinValue(false);
      });
  }

  return (
    <div className={classNames.settingsNavbar}>
      <div
        className={classNames.settingsIcon}
        onClick={() => {
          history.push('/supernova');
        }}
      >
        <img src={supernovaFullLogo} alt="" />
      </div>
      <div className={classNames.searchSettings}>
        {/* <div className="all">
              <p>All Categories</p>
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={ddown} alt="" />
              </div>
            </div> */}
        <div className={classNames.im}>
          <input
            type="text"
            placeholder="Im Looking For..."
            onChange={(event) => setMarketSearch(event?.target?.value)}
          />
          <div className={classNames.arroww} style={{ cursor: 'pointer' }}>
            <img src={search} alt="" />
          </div>
        </div>
      </div>
      <div className={classNames.settingsListBtn}>Popular Actions</div>
    </div>
  );
};

export const SwithNavbar = ({
  vaultsSidebar,
  setVaultsSidebar,
  setOpenedSidebar,
  decksAppcode,
}) => {
  // const { selectedAppVaults } = useCoinContextData();

  const { selectedAppDecks } = useAppContextDetails();

  return (
    <div className={classNames.wSpace}>
      <div className={classNames.optionss}>
        {switchItems.map((eachItem, i) => {
          return <SwitchItems {...eachItem} index={i} />;
        })}
      </div>
      <div className={classNames.lastOption}>
        <div className={classNames.changeAppVault}>
          <div>
            <span>
              {selectedAppDecks?.token_symbol
                ? selectedAppDecks?.token_symbol?.toUpperCase()
                : 'No Decks'}
            </span>
          </div>
          <div
            className={classNames.selectApp}
            onClick={() => {
              // console.log('vailysshjnjj', selectedAppDecks);
              setVaultsSidebar(true);
              setOpenedSidebar('mydecks');
            }}
          >
            Change
          </div>
        </div>
      </div>
    </div>
  );
};

const SwitchItems = ({ name, index }) => {
  return (
    <div
      className={`${index == 0 ? classNames.selectedTab : ''} ${
        classNames.tabs
      }`}
      // style={{ borderBottom: index == 0 ? '3px solid  #08152D' : '' }}
    >
      {/* <img src={icon} alt={name} /> */}
      <div>{name}</div>
      <div
        className={classNames.hoverDiv}
        style={{ display: index == 0 ? 'none' : '' }}
      ></div>
    </div>
  );
};

export const DecksContent = ({
  setVaultsSidebar,
  setDecksMainSidebar,
  setOpenedSidebar,
}) => {
  const decksCards = [
    { name: 'Problem' },
    { name: 'Solution' },
    { name: 'Market' },
    { name: 'Products' },
    { name: 'Business Model' },
    { name: 'Traction' },
    { name: 'Competition' },
    { name: 'Financials' },
    { name: 'IP' },
  ];

  const {
    decksAppcode,
    selectedAppDecks,
    setSelectedAppDecks,
    decksContentAPI,
  } = useAppContextDetails();

  //add function
  function openAddFunction(value) {
    // console.log(value, 'main side value');
    setVaultsSidebar(true);
    setDecksMainSidebar(value);
    setOpenedSidebar('addingSection');
  }

  return (
    <div className={classNames.decksContent}>
      {decksCards?.map((eachItem, index) => {
        return (
          <Card
            key={eachItem?.name + index}
            {...eachItem}
            func={openAddFunction}
          />
        );
      })}
    </div>
  );
};
