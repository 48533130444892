import React, { useContext, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { BankContext } from '../context/Context';
import ViewAsUserItem from './ViewAsUserItem';

import unameIcon from '../static/images/formIcons/uname.svg';
import emailIcon from '../static/images/formIcons/email.svg';
import logo from '../static/images/logos/viralVerse.svg';
import { useAppContextDetails } from '../context/AppContext';

function LayoutSidebarChangeUser({ adminSidebar, setAdminSidebar, setChatOn }) {
  const history = useHistory();
  const {
    setEmail,
    setUsername,
    setProfileImg,
    setProfileId,
    setName,
    closeSidebars,
    sidebarCollapse,
  } = useContext(BankContext);
  const [search, setSearch] = useState('');
  const [users, setUsers] = useState([]);
  const [type, setType] = useState('email');
  const [loading, setLoading] = useState(true);
  const { appCode, appLogo, appFullLogo } = useAppContextDetails();

  useEffect(() => {
    setLoading(true);
    Axios.get(
      `https://comms.globalxchange.io/gxb/apps/users/get?app_code=${appCode}`
    )
      .then((res) => {
        const { data } = res;
        if (data.status) {
          setUsers(data.users);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div
      className={`side-bar admin d-flex flex-column ${
        adminSidebar && 'clickable'
      }`}
    >
      <div className="profile d-flex">
        <img
          className="clickable"
          src={
            sidebarCollapse
              ? appLogo
              : 'https://drivetest.globalxchange.io/gxsharepublic/?full_link=bets.brain.stream/fb911ead98660b179b9aae14cdab11de'
          }
          alt="Icon Logo"
          onClick={() => {
            setAdminSidebar(false);
            setChatOn(false);
            closeSidebars();
            history.push('/malls');
          }}
        />
      </div>
      <div className="userFind">
        <div className="searchWrap">
          <input
            type="text"
            className="searchInp"
            placeholder={`Type In The ${
              type === 'name' ? 'Username' : 'Email Id'
            }`}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="box">
            {type === 'name' ? (
              <img src={emailIcon} alt="" onClick={() => setType('email')} />
            ) : (
              <img src={unameIcon} alt="" onClick={() => setType('name')} />
            )}
          </div>
        </div>
        <Scrollbars className="userList">
          {loading
            ? Array(10)
                .fill('')
                .map(() => (
                  <div className="viewAsUserItem">
                    <Skeleton className="profileImg" />
                    <div className="nameEmail">
                      <Skeleton className="name" width={200} />
                      <Skeleton className="email" width={160} />
                    </div>
                  </div>
                ))
            : users
                .filter((user) =>
                  user[type].toLowerCase().includes(search.toLowerCase())
                )
                .map((user) => {
                  return (
                    <ViewAsUserItem
                      user={user}
                      key={user._id}
                      onClick={() => {
                        setEmail(user.email);
                        setUsername(user.name);
                        setName(user.name);
                        setProfileImg('');
                        setAdminSidebar(false);
                        setProfileId('');
                        history.push('/vaults');
                      }}
                    />
                  );
                })}
        </Scrollbars>
      </div>
    </div>
  );
}

export default LayoutSidebarChangeUser;
