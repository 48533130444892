import React, { useEffect } from 'react';
import './capitalized.scss';
import Layout from '../../Layout/Layout';
import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import { planBContext } from '../../context/PlanBContext';
import checks from '../../static/images/check.svg';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import axios from 'axios';
import { predefinedToast } from '../../utils/toast';
import Mcb from './NewMcbApp';
import {
  InputDivDescrintion,
  InputDivs,
  InputDivs1,
  InputDivs2,
  InputDivs3,
  ImageUploadDiv1,
  InputDivsWithArrow,
  InputDivsWithBox,
  InputDivsWithBox1,
  InputDivsWithBox2,
  ImageUploadDiv,
} from '../../components/BuyBlocks/components/CreatePlanB';
import EditEnterArticle from '../../components/Editor/EnterArticle';
import usd from '../../components/BuyBlocks/components/usd.svg';
import { uploadImageFunc, uploadImageFunc1 } from '../../utils/imageUpload';
import { LoadingAnimation } from '../../components/LoadingAnimation';

import fulllogo from '../../static/images/capitalized/fulllogo.svg';
import search from '../../static/images/malls/search.svg';
import close from './close.svg';
import dummy from './dummyImg.svg';
import upload from '../../static/images/upload.svg';
import logo from './logo.svg';
import cover from './cover.svg';
import { currentShareholders } from './api';
import toast from 'react-hot-toast';
import { faSleigh } from '@fortawesome/free-solid-svg-icons';
import { InputDropdown } from '../../components/BuyBlocks/components/CreatePlanB';
import './toggle.scss';
const CapitalizedPage = () => {
  const {
    capTab,
    setCapTab,
    appLogo,
    issueShareTokenEnabled,
    setIssueShareTokenEnabled,
    issuanceAssetEnabled,
    setIssuanceAssetEnabled,
    issuanceCountryEnabled,
    setIssuanceCountryEnabled,
    companyForShareTokenEnabled,
    setCompanyForShareTokenEnabled,
    equityRoundEnabled,
    setEquityRoundEnabled,
    setSearchedValue,
    allCountries,
    setAllCountries,
    BrandedCountryEnabled,
    setBrandedCountryEnabled,
    searchedValue,
    setEBrandedCountryEnabled,
    EBrandedCountryEnabled,
  } = useAppContextDetails();
  const [articleBody, setArticleBody] = useState('');
  const [companytoggle, setcompanytoggle] = useState(false);
  const { email, token } = useContext(BankContext);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [loading3, setLoading3] = useState(true);
  const [CampaignsData, setCampaignsData] = useState([]);
  const [companiesData, setCompaniesData] = useState([]);
  const [actionEnabled, setActionEnabled] = useState(false);
  const [offeringData, setOfferingData] = useState([]);
  const [entrepreneurEnabled, setEntrepreneurEnabled] = useState(false);
  const [promoterStep1, setpromoterStep1] = useState(false);
  const [promoterStep2, setpromoterStep2] = useState(false);
  const [catDeatil, setcatDeatil] = useState(false);
  const [promotloader, setpromotloader] = useState(false);
  const [promotSucsess, setpromotSucsess] = useState(false);
  const [entrepreneurStep, setEntrepreneurStep] = useState(1);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [userName, setUserName] = useState();
  const [userPic, setUserPic] = useState();
  const [coverpic, setcoverpic] = useState();
  const [catpic, setcatpic] = useState();
  const [entrepreneurLoading, setEntrepreneurLoading] = useState(false);
  const [corporateBrandEnabled, setCorporateBrandEnabled] = useState(false);
  const [corporateBrandStep, setCorporateBrandStep] = useState(1);
  const [coverPhoto, setCoverPhoto] = useState();
  const [logo, setLogo] = useState();
  const [brandName, setBrandName] = useState();
  const [countryCode, setCountryCode] = useState();
  const [country, setCountry] = useState();
  const [catlogo, setcatlogo] = useState();
  const [catdes, setcatdes] = useState('');
  const [catname, setcatname] = useState('');
  const [catPcolor, setcatPcolor] = useState('');
  const [catScolor, setcatScolor] = useState('');
  const [contactNo, setContactNo] = useState();
  const [foundedDate, setFoundedDate] = useState();
  const [colorCode, setColorCode] = useState();
  const [EColorCode, setEColorCode] = useState();
  const [shareHoldersData, setShareHoldersData] = useState([]);
  const [tokenValue, setTokenValue] = useState('');
  const [brandCountry, setbrandCountry] = useState();
  const [ebrandCountry, setebrandCountry] = useState();
  // const [issueShareTokenEnabled, setIssueShareTokenEnabled] = useState(false);
  const [issueShareTokenStep, setIssueShareTokenStep] = useState(1);
  const [issuanceAsset, setIssuanceAsset] = useState();
  const [issuanceCountry, setIssuanceCountry] = useState();
  const [company, setCompany] = useState();
  const [tickerSymbol, setTickerSymbol] = useState();
  const [sharetokenFullName, setSharetokenFullName] = useState();
  const [primaryColorCode, setPrimaryColorCode] = useState();
  const [secondaryColorCode, setSecondaryColorCode] = useState();
  const [equityRound, setEquityRound] = useState();
  const [initialSupply, setInitialSupply] = useState();
  const [initialSalePrice, setInitialSalePrice] = useState();
  const [affiliateCommission, setAffiliateCommission] = useState();
  const [directAffiliateCommission, setDirectAffiliateCommission] = useState();
  const [networkChainCommission, setNetworkChainCommission] = useState();
  const [directExecutiveOverride, setDirectExecutiveOverride] = useState();
  const [executiveNetworkChainCommission, setExecutiveNetworkChainCommission] =
    useState();
  const [shareTokenPrice, setshareTokenPrice] = useState();
  const [Esops, setEsops] = useState();
  const [CSOPS, setCSOPS] = useState();
  const [sucessMessageShow, setsucessMessageShow] = useState(false);

  const [SafetokenPrice, setSafetokenPrice] = useState();
  const [shareTokenIcon, setShareTokenIcon] = useState();
  const [issuanceAssetData, setIssuanceAssetData] = useState([]);
  const [filteredIssuanceAssetData, setFilteredIssuanceAssetData] = useState(
    []
  );
  const [countrydata, setcountrydata] = useState([]);
  const [brandEmail, setbrandEmail] = useState('');
  const [stake, setstake] = useState([]);
  const [equity, setequity] = useState([]);
  const [banker, setbanker] = useState('');
  const [searchitem, setsearchitem] = useState('');
  const [searchitemCountry, setsearchitemCountry] = useState('');
  const [searchitemCompany, setsearchitemCompany] = useState('');
  const [brandusername, setbrandusername] = useState('');
  const [sucessMessage, setsucessMessage] = useState(false);
  const [pathname, setpathname] = useState('');
  const [brandDes, setbrandDes] = useState('');
  const [brandNameget, setbrandNameget] = useState('');
  const [Loading2, setLoading2] = useState(false);
  const [offteringAction, setoffteringAction] = useState(false);
  const [profileData, setprofileData] = useState([]);
  const [promote, setpromote] = useState([]);
  const [brandimgstep1, setbrandimgstep1] = useState('');
  const [brandimgstep2, setbrandimgstep2] = useState();
  const [entercover, setentercover] = useState();
  const [getappinfo, setgetappinfo] = useState({
    coinSymbol: 'USD',
    coinImage: usd,
  });
  const [getinputvalue, setgetinputvalue] = useState({
    coinSymbol: 'USD',
    coinImage: usd,
  });
  const [getinputCountry, setgetinputCountry] = useState({
    coinSymbol: 'USD',
    coinImage: usd,
  });
  const [getinputcompany, setgetinputcompany] = useState({
    coinSymbol: 'USD',
    coinImage: usd,
  });
  const [shareHolder, setshareHolder] = useState([]);
  const [loadingShare, setloadingShare] = useState(false);
  const [brandAddress, setbrandAddress] = useState('');
  const [profileBrand, setprofileBrand] = useState('');
  const [brndFristName, setbrndFristName] = useState('');
  const [brndLastName, setbrndLastName] = useState('');
  const [branduserNameCheck, setbranduserNameCheck] = useState('');
  const [BrancheckStep2, setBrancheckStep2] = useState('');
  const [contactNumberEnter, setcontactNumberEnter] = useState();
  const [stepcolorCode, setstepcolorCode] = useState();
  const [check, setcheck] = useState(true);
  console.log('profileBrand', profileBrand);
  const Namelist = [
    {
      name: 'Pre-Seed',
    },
    {
      name: 'Seed',
    },
    {
      name: 'Series A',
    },
    {
      name: 'Series B',
    },
    {
      name: 'Series C',
    },
  ];
  const [shareTokenDrawer, setshareTokenDrawer] = useState([]);
  const [shareTokenDrawerLoader, setshareTokenDrawerLoader] = useState(false);
  const [shareTokenDrawerOpen, setshareTokenDrawerOpen] = useState(false);
  const [pathName, setpathName] = useState('');
  const [selectedTokenOfferings, setSelectedTokenOfferings] = useState('');
  const [selectedTokenOfferingsData, setSelectedTokenOfferingsData] =
    useState('');
  const [selectedTokenOfferingsPathID, setSelectedTokenOfferingsPathID] =
    useState('');
  const [selectedTokenOfferingsImage, setSelectedTokenOfferingsImage] =
    useState('');
  // const [check,setcheck]=useState(false)

  const offerFunction = (e) => {
    setcheck(e?.campaign);
    setpathName(e.path_id);
    setoffteringAction(true);
    setSelectedTokenOfferings(e?.token_profile_data?.coinSymbol);
    handleOfferingSelect(e?.token_profile_data?.coinSymbol);
  };
  // postdata(e)

  console.log('profileData', profileData?.length);
  async function handleOfferingSelect(selectedCoin) {
    let data = await axios.get(
      `https://comms.globalxchange.io/coin/investment/path/get?token=${selectedCoin}`
    );
    let res = data?.data?.paths;
    if (res?.length > 0) {
      // console.log(res[0]?.path_id, 'selected coin data');
      setSelectedTokenOfferingsPathID(res[0]?.path_id);
      res = res[0]?.token_profile_data;
      // console.log(data?.data, 'getter image');
      setSelectedTokenOfferingsData(res);
    }
  }
  async function submitIconEdit() {
    let obj = {
      email: email,
      token: token,
      path_id: selectedTokenOfferingsPathID ? selectedTokenOfferingsPathID : '',
      update_data: {
        token_profile_data: {
          coinName: selectedTokenOfferingsData
            ? selectedTokenOfferingsData?.coinName
            : '',
          coinSymbol: selectedTokenOfferingsData
            ? selectedTokenOfferingsData?.coinSymbol
            : '',
          coinImage: selectedTokenOfferingsImage
            ? selectedTokenOfferingsImage
            : '',
          color_codes: selectedTokenOfferingsData
            ? selectedTokenOfferingsData?.color_codes
            : '',
        },
      },
    };

    let data = await axios.post(
      'https://comms.globalxchange.io/coin/investment/path/edit',
      obj
    );
    console.log(data?.data, 'submitIconEdit');
    if (data?.data?.status) {
      setoffteringAction('offeringsSuccesful');
      setTimeout(() => {
        setoffteringAction(false);
        window.location.reload();
      }, 3000);
    } else {
      setoffteringAction('');
    }
  }

  const handlecheck = (e) => {
    setcheck(e);
    postdata(e);
  };

  const postdata = (e) => {
    axios
      .post('https://comms.globalxchange.io/coin/investment/path/edit', {
        email: email,
        token: token,
        path_id: pathName,
        update_data: {
          campaign: e,
        },
      })
      .then((response) => {
        let result = response?.data;
        console.log(result, 'createEntrepreneurialProfile result');
        if (result?.status) {
          endapi();
          compainFunction();
          setoffteringAction(false);
          // setpromoterStep2(true);
        } else {
          setcheck(check);
        }
      })
      .catch((error) => {
        console.log(error, 'error in createEntrepreneurialProfile');
      });
  };

  const toggle = () => {
    switch (check) {
      case 'off':
        setcheck('on');
        break;
      case 'on':
        setcheck('off');
        break;
    }
  };

  const callendfunct = () => {
    setTimeout(() => {
      setsucessMessage(false);
      setCapTab('Offerings');
      endapi();
    }, 3000);
  };

  useEffect(() => {
    endapi();
  }, []);
  useEffect(() => {
    setSearchedValue('');
  }, [capTab]);
  useEffect(() => {
    setshareTokenDrawerLoader(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/investment/path/get?investmentType=EQT&banker_email=${email}`
      )
      .then((response) => {
        let result = response?.data?.paths;
        let result1 =
          response?.data?.paths?.[0]?.token_profile_data?.coinSymbol;
        setTokenValue(result1);
        console.log(result1, '12mcknjcxzc');
        setshareTokenDrawer(result);
        setshareTokenDrawerLoader(false);
        // setcountrydata(result);
      });
  }, []);

  useEffect(() => {
    setloadingShare(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/investment/path/tokens/sharetoken/get?token=${tokenValue}`
      )
      .then((response) => {
        let result = response?.data?.userData?.data;
        console.log(result, '12mcknjcxzc');
        setshareHolder(result);
        setloadingShare(false);
        // setcountrydata(result);
      });
  }, [tokenValue]);
  const capitizaledApi = () => {
    // setLoading1(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/investment/path/get?token=IOTCE&getTokenHolders=true`
      )
      .then((response) => {
        let result = response?.data?.paths;
        // console.log(result, "offeringData result");
        setshareHolder(result);
        // setLoading1(false);
      });
  };

  useEffect(() => {
    compainFunction();
  }, []);

  const compainFunction = () => {
    setLoading3(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/investment/path/get?path_status=active&banker_email=${email}&investmentType=EQT&campaign=true`
      )
      .then((response) => {
        let result = response?.data?.paths;
        setCampaignsData(result);
        setLoading3(false); // console.log(result, 'companiesData result');
        // setcountrydata(result);
      });
  };
  function endapi() {
    setLoading1(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/investment/path/get?path_status=active&banker_email=${email}&investmentType=EQT`
      )
      .then((response) => {
        let result = response?.data?.paths;
        // console.log(result, 'offeringData result');
        setOfferingData(result);
        setLoading1(false);
      });
  }
  // https://teller2.apimachine.com/admin/allBankers?email=shorupan@brokerapp.io
  useEffect(() => {
    axios
      .get(`https://teller2.apimachine.com/admin/allBankers?email=${email}`)
      .then((response) => {
        let result = response?.data;
        setpromote(result);
        // console.log(result, 'companiesData result');
        // setcountrydata(result);
      });
  }, []);
  console.log('setpromote', promote);

  useEffect(() => {
    axios
      .get(`https://teller2.apimachine.com/admin/allBankers?email=${email}`)
      .then((response) => {
        let result = response?.data?.data?.[0];
        setbanker(result?.bankerTag);
        // console.log(result, 'companiesData result');
        // setcountrydata(result);
      });
  }, []);

  const callfunction = () => {
    let obj = {
      email: email,
      token: token,
      asset: issuanceAsset,
      banker: banker,
      country: issuanceCountry,
      investmentType: 'EQT',
      investmentSubType: 'privateequity',
      pathType: 'shareToken',
      pathAppCode: company,
      commission_fee_percentage: Number(affiliateCommission),
      total_steps: {
        step1: {
          name: 'Initiated',
          status: 'Initiated',
        },
        step2: {
          name: 'Reedemption state',
          status: 'Redeemed',
        },
      },
      banker_trade_fee: 0,
      gx_trade_fee: 0,
      banker_fixed_fee: 0,
      gx_fixed_fee: 0,
      success_step: 'step2',
      failure_step: 'step1',
      seriesName: equityRound,
      isssuingAmount: Number(initialSupply),
      token_price: Number(initialSalePrice),
      token_symbol: tickerSymbol,
      token_profile_data: {
        coinName: sharetokenFullName,
        coinSymbol: tickerSymbol,
        coinImage: shareTokenIcon,
        color_codes: [primaryColorCode, secondaryColorCode],
      },
      commission_distribution: {
        direct: Number(directAffiliateCommission),
        indirect: Number(networkChainCommission),
        direct_bd: Number(directExecutiveOverride),
        indirect_bd: Number(executiveNetworkChainCommission),
      },
      token_distribution: {
        shareToken: Number(shareTokenPrice),
        safeToken: Number(SafetokenPrice),
        CSOPToken: Number(CSOPS),
        ESOPToken: Number(Esops),
      },
    };
    axios
      .post(`https://comms.globalxchange.io/coin/investment/path/define`, obj)
      .then((response) => {
        let result = response?.data;
        if (result?.status) {
          setIssueShareTokenEnabled(false);
          setsucessMessage(true);
          callendfunct();

          // toast(result?.message, {
          //   duration: 4000,
          //   position: 'top-left',
          // });
          // predefinedToast(result?.message);
        } else {
          toast(result?.message, {
            duration: 4000,
            position: 'top-left',
          });
          // predefinedToast(result?.message);
        }
        console.log(result, 'nm');
        // setcountrydata(result);
      });
  };

  useEffect(() => {
    axios
      .get(`https://comms.globalxchange.io/coin/vault/countries/data/get`)
      .then((response) => {
        let result = response?.data?.countries;
        // console.log(result, 'companiesData result');
        setcountrydata(result);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`https://comms.globalxchange.io/gxb/apps/get?created_by=${email}`)
      .then((response) => {
        let result = response?.data?.apps;
        // console.log(result, 'companiesData result');
        setstake(result);
      });
  }, []);

  //comms.globalxchange.com/coin/vault/countries/data/get

  https: useEffect(() => {
    setLoading(true);
    axios
      .get(`https://comms.globalxchange.io/gxb/apps/get?created_by=${email}`)
      .then((response) => {
        let result = response?.data?.apps;
        // console.log(result, 'companiesData result');
        setCompaniesData(result);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading2(true);
    axios
      .get(
        `https://comms.globalxchange.io/gxb/app/gxlive/user/operator/get?email=${email}`
      )
      .then((response) => {
        let result = response?.data?.operators;

        setprofileData(result);
        setLoading2(false);
      });
  }, []);
  console.log(profileData, 'sfjakfff');
  useEffect(() => {
    axios
      .get('https://comms.globalxchange.io/coin/vault/get/all/coins')
      .then((response) => {
        let result = response?.data?.coins;
        // console.log(result, 'issuanceAssetData result');
        setIssuanceAssetData(result);
        setFilteredIssuanceAssetData(result);
      });
  }, [issuanceAssetEnabled]);

  const shareHoldersFunction = () => {
    let query = 'IOTCE';
    currentShareholders(query).then((response) => {
      let result = response?.data;
      // console.log(result, 'shareHoldersFunction result');
    });
  };

  const myTimeout = () => {
    setTimeout(reload, 3000);
  };

  function reload() {
    window.location.reload();
  }

  const createCategory = () => {
    // setpromotloader(true);

    // setpromoterStep2(true);
    axios
      .post(
        'https://comms.globalxchange.io/coin/investment/sub/types/add',
        {
          email:email,
          token: token,
          name:catname,
          icon: catlogo,
          description: catdes,
          full_logo:catpic,
          parent_investment_type: 'EQT',
          color_codes: [catPcolor, catScolor]
        }
      )
      .then((response) => {
        let result = response?.data;
        console.log(result, 'createEntrepreneurialProfile result');

        if (result?.status) {
          reload();
          // setsucessMessageShow(true);
          setcatDeatil(false);
        } else {
          // setpromotloader(false);
        }
      })
      .catch((error) => {
        console.log(error, 'error in createEntrepreneurialProfile');
      });
  };

  const createPromterstep2 = () => {
    // setpromotloader(true);
    let object = {
      profilePicURL: brandimgstep1,
      firstName: brndFristName,
      lastName: brndLastName,
      lxTag: branduserNameCheck,
    };

    let vxncmvb={
      bankerTag: BrancheckStep2,
      colorCode: EColorCode,
      address: brandAddress,
      coverPicURL: coverpic,
      displayName: brandusername,
      description: brandDes,
      partneringInstitutions: ['5f09e92e3148a4126c25bc19'],
      country: brandCountry,
      profilePicURL: brandimgstep1,
      profilePicPNG: brandimgstep1,
      profilePicWhite: brandimgstep2,
      profilePicWhitePNG: brandimgstep2,
      autoDeposit: false,
      sefcoin_cashback: false,
      other_data: {},
    }
    console.log('zce2', vxncmvb);
    // setpromoterStep2(true);
    axios
      .post(
        'https://teller2.apimachine.com/lxUser/register/banker',
        {
          bankerTag: BrancheckStep2,
          colorCode: EColorCode,
          address: brandAddress,
          coverPicURL: coverpic,
          displayName: brandusername,
          description: brandDes,
          partneringInstitutions: ['5f09e92e3148a4126c25bc19'],
          country: brandCountry,
          profilePicURL: brandimgstep1,
          profilePicPNG: brandimgstep1,
          profilePicWhite: brandimgstep2,
          profilePicWhitePNG: brandimgstep2,
          autoDeposit: false,
          sefcoin_cashback: false,
          other_data: {},
        },
        { headers: { email, token } }
      )
      .then((response) => {
        let result = response?.data;
        console.log(result, 'createEntrepreneurialProfile result');

        if (result?.status) {
          reload();
          setsucessMessageShow(true);
          setpromoterStep2(true);
        } else {
          setpromotloader(false);
        }
      })
      .catch((error) => {
        console.log(error, 'error in createEntrepreneurialProfile');
      });
  };

  const createPromterstep1 = () => {
    // setpromotloader(true);
    let object = {
      profilePicURL: brandimgstep1,
      firstName: brndFristName,
      lastName: brndLastName,
      lxTag: branduserNameCheck,
    };
    console.log('zce2', object);
    // setpromoterStep2(true);
    axios
      .post(
        'https://teller2.apimachine.com/lxUser/register',
        {
          profilePicURL: brandimgstep1,
          firstName: brndFristName,
          lastName: brndLastName,
          lxTag: branduserNameCheck,
        },
        { headers: { email, token } }
      )
      .then((response) => {
        let result = response?.data;
        console.log(result, 'createEntrepreneurialProfile result');
        if (result?.status) {
          setpromoterStep2(true);
        } else {
          setpromotloader(false);
        }
      })
      .catch((error) => {
        console.log(error, 'error in createEntrepreneurialProfile');
      });
  };

  const createEntrepreneurialProfile = () => {
    callsecondEnterFuntion();
    setEntrepreneurLoading(true);
    axios
      .post(
        'https://comms.globalxchange.io/gxb/app/gxlive/user/operator/create',
        {
          email: email, // user email
          token: token, // user token
          brand_name: firstName,
          brand_logo: userPic,
          brand_cover: entercover,
          brand_country: ebrandCountry,
          brand_email: lastName,
          brand_phone: contactNumberEnter,
          founding_date: userName,
          other_data: {
            colour_code: stepcolorCode,
          },
        }
      )
      .then((response) => {
        let result = response?.data;
        console.log(result, 'createEntrepreneurialProfile result');
        if (result?.status) {
          setEntrepreneurLoading(false);
          setEntrepreneurStep(2);
          myTimeout();
        } else {
          setEntrepreneurLoading(false);
        }
      })
      .catch((error) => {
        console.log(error, 'error in createEntrepreneurialProfile');
      });
  };
  const callsecondEnterFuntion = () => {
    axios
      .post('https://comms.globalxchange.io/gxb/app/gxlive/user/signup', {
        email: email,
      })
      .then((response) => {
        let result = response?.data;

        if (result?.status) {
        } else {
        }
      })
      .catch((error) => {
        console.log(error, 'error in createEntrepreneurialProfile');
      });
  };
  return (
    <Layout active="capitalized" className="capitalized" hideFooter>
      <div style={{ width: '100%', height: '100%' }}>
        <div className="capitalized-navbar">
          <div className="capitalized-icon">
            <img src={fulllogo} alt="" />
          </div>
          <div className="search-capitalized">
            <div className="im">
              <input
                type="text"
                value={searchedValue}
                placeholder="Im Looking For..."
                onChange={(event) => setSearchedValue(event?.target?.value)}
              />
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={search} alt="" />
              </div>
            </div>
          </div>
          <div
            className="capitalized-listBtn"
            onClick={() => {
              setActionEnabled(!actionEnabled);
            }}
          >
            Popular Actions
          </div>
        </div>

        <div className="w-space">
          <div className="optionss" style={{ width: '35%' }}>
            <div
              className={`each-option ${
                capTab === 'Companies' ? 'each-option-selected' : ''
              }`}
              style={{
                fontWeight: capTab === 'Companies' ? '600' : '',
              }}
              onClick={() => setCapTab('Companies')}
            >
              <div>
                <p>Companies</p>
              </div>
            </div>

            <div
              className={`each-option ${
                capTab === 'Offerings' ? 'each-option-selected' : ''
              }`}
              style={{
                fontWeight: capTab === 'Offerings' ? '600' : '',
              }}
              onClick={() => setCapTab('Offerings')}
            >
              <div>
                <p>Offerings</p>
              </div>
            </div>

            <div
              className={`each-option ${
                capTab === 'Campaigns' ? 'each-option-selected' : ''
              }`}
              style={{
                fontWeight: capTab === 'Campaigns' ? '600' : '',
              }}
              onClick={() => setCapTab('Campaigns')}
            >
              <div>
                <p>Campaigns</p>
              </div>
            </div>

            <div
              className={`each-option ${
                capTab === 'Shareholders' ? 'each-option-selected' : ''
              }`}
              style={{
                fontWeight: capTab === 'Shareholders' ? '600' : '',
              }}
              onClick={() => setCapTab('Shareholders')}
            >
              <div>
                <p>Shareholders</p>
              </div>
            </div>
            {/* <div
              className={`each-option ${
                capTab === 'Profile' ? 'each-option-selected' : ''
              }`}
              style={{
                fontWeight: capTab === 'Profile' ? '600' : '',
              }}
              onClick={() => setCapTab('Profile')}
            >
              <div>
                <p>Profile</p>
              </div>
            </div> */}
          </div>
          {/* <div
            className={`each-option ${
              capTab === 'Profile' ? 'each-option-selected' : ''
            }`}
            style={{
              fontWeight: capTab === 'Profile' ? '600' : '',
            }}
            onClick={() => setCapTab('Profile')}
          >
            <div>
              <p>Profile</p>
            </div>
          </div> */}
          {capTab != 'Shareholders' && (
            <div
              className="selectOption"
              style={{
                justifyContent: 'center',
                width: '9rem',
                background:
                  capTab === 'Profile' ? 'rgba(237, 237, 237, 0.37)' : '#fff',
                cursor: 'pointer',
                padding: '0px',
              }}
              onClick={() => setCapTab('Profile')}
            >
              <div className="tokenValue">My Profiles</div>
            </div>
          )}
          {capTab === 'Shareholders' && (
            <div
              className="selectOption"
              onClick={() => {
                setshareTokenDrawerOpen(true);
                setActionEnabled(false);
              }}
            >
              <div
                className="tokenValue"
                // onClick={() => setshareTokenDrawerOpen(true)}
              >
                {tokenValue?.toUpperCase()}
              </div>
              <div className="changeBtn">Change</div>
            </div>
          )}
        </div>

        <div className="capitalized-content">
          {capTab === 'Companies' ? (
            <>
              <div className="companyHeader">
                <div style={{ width: '30%' }}>Name</div>
                <div style={{ width: '25%' }}>Founding Date</div>
                <div style={{ width: '25%' }}>ShareToken</div>
                <div style={{ width: '20%' }}>Users</div>
              </div>
              <div className="companyContent">
                {loading
                  ? Array(10)
                      .fill('')
                      .map((item, index) => {
                        return (
                          <div className="eachCompany" key={index}>
                            <div className="companyDetails">
                              <div>
                                <Skeleton
                                  width={40}
                                  height={40}
                                  borderRadius={50}
                                />
                              </div>
                              <div className="nameDetails">
                                <div>
                                  <Skeleton width={100} height={12} />
                                </div>
                                <div>
                                  <Skeleton width={60} height={12} />
                                </div>
                              </div>
                            </div>
                            <div style={{ width: '25%' }}>
                              <Skeleton width={60} height={25} />
                            </div>
                            <div style={{ width: '25%' }}>
                              <Skeleton width={60} height={25} />
                            </div>
                            <div style={{ width: '20%' }}>
                              <Skeleton width={60} height={25} />
                            </div>
                          </div>
                        );
                      })
                  : companiesData
                      ?.filter((app) =>
                        app?.app_name
                          ?.toLowerCase()
                          .includes(searchedValue.toLowerCase())
                      )
                      ?.map((e, i) => {
                        return (
                          <div className="eachCompany" key={i}>
                            <div className="companyDetails">
                              <div>
                                <img src={e?.app_icon} alt="" />
                              </div>
                              <div className="nameDetails">
                                <div style={{ fontWeight: '500' }}>
                                  {e?.app_name}
                                </div>
                                <div
                                  style={{
                                    fontSize: '0.8rem',
                                    fontWeight: '100',
                                  }}
                                >
                                  {e?.app_code}
                                </div>
                              </div>
                            </div>
                            <div style={{ width: '25%', fontWeight: '100' }}>
                              {moment(e?.date).format('MMM Do YYYY')}
                            </div>
                            <div style={{ width: '25%', fontWeight: '100' }}>
                              {e?.shareTokenData?._id ? 'Issued' : 'Not Issued'}
                            </div>
                            <div style={{ width: '20%', fontWeight: '100' }}>
                              0
                            </div>
                          </div>
                        );
                      })}
              </div>
            </>
          ) : capTab === 'Offerings' ? (
            <>
              <div className="companyHeader">
                <div style={{ width: '25%' }}>Name</div>
                <div style={{ width: '20%' }}>Price</div>
                <div style={{ width: '20%' }}>Market Cap</div>
                <div style={{ width: '20%' }}>Supply</div>
                <div style={{ width: '15%' }}>Shareholders</div>
              </div>
              <div className="offeringsContent">
                {loading1
                  ? Array(10)
                      .fill('')
                      .map((item, index) => {
                        return (
                          <div className="eachOffering" key={index}>
                            <div className="OfferingDetails">
                              <div>
                                <Skeleton
                                  width={40}
                                  height={40}
                                  borderRadius={50}
                                />
                              </div>
                              <div>
                                <div>
                                  <Skeleton width={100} height={15} />
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                  }}
                                >
                                  <div>
                                    <Skeleton
                                      width={15}
                                      height={15}
                                      borderRadius={50}
                                    />
                                  </div>
                                  <div style={{ fontSize: '0.9rem' }}>
                                    <Skeleton width={70} height={15} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style={{ width: '20%' }}>
                              <Skeleton width={100} height={20} />
                            </div>
                            <div style={{ width: '20%' }}>
                              <Skeleton width={100} height={20} />
                            </div>
                            <div style={{ width: '20%' }}>
                              <Skeleton width={100} height={20} />
                            </div>
                            <div style={{ width: '15%' }}>
                              <Skeleton width={100} height={20} />
                            </div>
                          </div>
                        );
                      })
                  : offeringData
                      ?.filter((app) =>
                        app?.token_profile_data?.coinName
                          ?.toLowerCase()
                          .includes(searchedValue.toLowerCase())
                      )
                      ?.map((e, i) => {
                        return (
                          <div
                            className="eachOffering"
                            key={i}
                            onClick={() => offerFunction(e)}
                          >
                            <div className="OfferingDetails">
                              <div>
                                <img
                                  src={e?.token_profile_data?.coinImage}
                                  alt=""
                                  className="img1"
                                />
                              </div>
                              <div>
                                <div style={{ fontWeight: '500' }}>
                                  {e?.token_profile_data?.coinName} (
                                  {e?.token_profile_data?.coinSymbol})
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                  }}
                                >
                                  <div>
                                    <img
                                      src={e?.apps_metadata?.[0]?.app_icon}
                                      alt=""
                                      className="img2"
                                    />
                                  </div>
                                  <div style={{ fontSize: '0.9rem' }}>
                                    {e?.apps_metadata?.[0]?.app_name}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style={{ width: '20%', fontWeight: '100' }}>
                              {e?.token_price?.toFixed(4)}
                            </div>
                            <div style={{ width: '20%', fontWeight: '100' }}>
                              {e?.asset_balance?.toFixed(4)}
                            </div>
                            <div style={{ width: '20%', fontWeight: '100' }}>
                              {e?.token_totalSupply?.toFixed(4)}
                            </div>
                            <div style={{ width: '15%', fontWeight: '100' }}>
                              {e?.tokenHoldersCount}
                            </div>
                          </div>
                        );
                      })}
              </div>
            </>
          ) : capTab === 'Campaigns' ? (
            <>
              {/* <EditEnterArticle
                articleBody={JSON.stringify('articleBody')}
               
              /> */}

             <div className="companyHeader">
                <div style={{ width: '25%' }}>Name</div>
                <div style={{ width: '20%' }}>Price</div>
                <div style={{ width: '20%' }}>Market Cap</div>
                <div style={{ width: '20%' }}>Supply</div>
                <div style={{ width: '15%' }}>Shareholders</div>
              </div>
              <div className="offeringsContent">
                {loading3
                  ? Array(10)
                      .fill('')
                      .map((item, index) => {
                        return (
                          <div className="eachOffering" key={index}>
                            <div className="OfferingDetails">
                              <div>
                                <Skeleton
                                  width={40}
                                  height={40}
                                  borderRadius={50}
                                />
                              </div>
                              <div>
                                <div>
                                  <Skeleton width={100} height={15} />
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                  }}
                                >
                                  <div>
                                    <Skeleton
                                      width={15}
                                      height={15}
                                      borderRadius={50}
                                    />
                                  </div>
                                  <div style={{ fontSize: '0.9rem' }}>
                                    <Skeleton width={70} height={15} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style={{ width: '20%' }}>
                              <Skeleton width={100} height={20} />
                            </div>
                            <div style={{ width: '20%' }}>
                              <Skeleton width={100} height={20} />
                            </div>
                            <div style={{ width: '20%' }}>
                              <Skeleton width={100} height={20} />
                            </div>
                            <div style={{ width: '15%' }}>
                              <Skeleton width={100} height={20} />
                            </div>
                          </div>
                        );
                      })
                  : CampaignsData?.filter((app) =>
                      app?.token_profile_data?.coinName
                        ?.toLowerCase()
                        .includes(searchedValue.toLowerCase())
                    )?.map((e, i) => {
                      return (
                        <div className="eachOffering" key={i}>
                          <div className="OfferingDetails">
                            <div>
                              <img
                                src={e?.token_profile_data?.coinImage}
                                alt=""
                                className="img1"
                              />
                            </div>
                            <div>
                              <div style={{ fontWeight: '500' }}>
                                {e?.token_profile_data?.coinName} (
                                {e?.token_profile_data?.coinSymbol})
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '5px',
                                }}
                              >
                                <div>
                                  <img
                                    src={e?.apps_metadata?.[0]?.app_icon}
                                    alt=""
                                    className="img2"
                                  />
                                </div>
                                <div style={{ fontSize: '0.9rem' }}>
                                  {e?.apps_metadata?.[0]?.app_name}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{ width: '20%', fontWeight: '100' }}>
                            {e?.token_price?.toFixed(4)}
                          </div>
                          <div style={{ width: '20%', fontWeight: '100' }}>
                            {e?.asset_balance?.toFixed(4)}
                          </div>
                          <div style={{ width: '20%', fontWeight: '100' }}>
                            {e?.token_totalSupply?.toFixed(4)}
                          </div>
                          <div style={{ width: '15%', fontWeight: '100' }}>
                            {e?.tokenHoldersCount}
                          </div>
                        </div>
                      );
                    })}
              </div> 
            </>
          ) : capTab === 'Profile' ? (
            <>
              <div className="companyHeader">
                <div style={{ width: '30%' }}>Profile</div>
                <div style={{ width: '30%' }}>Status</div>
                <div style={{ width: '40%' }}></div>
              </div>
              <div className="companyContent">
                {Loading2 ? (
                  Array(10)
                    .fill('')
                    .map((item, index) => {
                      return (
                        <div className="eachCompany" key={index}>
                          <div style={{ width: '30%' }}>
                            <Skeleton width={60} height={25} />
                          </div>
                          <div style={{ width: '30%' }}>
                            <Skeleton width={60} height={25} />
                          </div>
                          <div style={{ width: '40%' }}>
                            <Skeleton width={60} height={25} />
                          </div>
                        </div>
                      );
                    })
                ) : (
                  <>
                    <div className="eachCompany">
                      <div style={{ width: '30%', fontWeight: '100' }}>
                        Entrepreneur Profile
                      </div>
                      <div style={{ width: '30%', fontWeight: '100' }}>
                        {profileData?.length > 0 ? 'Created' : 'Not Created'}
                      </div>
                      <div
                        className="labelTable"
                        style={{ width: '40%', fontWeight: '100' }}
                      >
                        <label style={{ marginRight: '10px' }}>Create</label>
                        <label>Edit</label>
                      </div>
                    </div>
                    <div className="eachCompany">
                      <div style={{ width: '30%', fontWeight: '100' }}>
                        Promoter Profile
                      </div>
                      <div style={{ width: '30%', fontWeight: '100' }}>
                        {promote?.status ? 'Created' : 'Not Created'}
                      </div>
                      <div
                        className="labelTable"
                        style={{ width: '40%', fontWeight: '100' }}
                      >
                        <label style={{ marginRight: '10px' }}>Create</label>
                        <label>Edit</label>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="companyHeader">
                <div style={{ width: '90%' }}>Name</div>

                <div style={{ width: '10%' }}>Holdings</div>
              </div>

              {loadingShare ? (
                <div className="sharesContent">
                  {Array(10)
                    .fill('')
                    .map((item, index) => {
                      return (
                        <div className="eachShareHolder">
                          <div className="eachCompany">
                            <div style={{ width: '91%' }}>
                              <Skeleton width={250} height={25} />
                            </div>
                          </div>
                          <div
                            className="eachCompany"
                            style={{
                              paddingRight: '5rem',
                            }}
                          >
                            <div style={{ width: '10%' }}>
                              <Skeleton width={60} height={25} />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              ) : (
                <div className="sharesContent">
                  {shareHolder?.map((item) => {
                    let pop = tokenValue?.toLocaleLowerCase();
                    let text = '_balance';
                    let result = pop + text;
                    console.log('czhjew,pop', result);
                    return (
                      <div className="eachShareHolder">
                        <div className="shareDetails" style={{ width: '90%' }}>
                          <div>
                            <img src={item?.user_data?.profile_img} alt="" />
                          </div>
                          <div>
                            <div className="txt1">{item?.user_data?.name}</div>
                            <div className="txt2">{item?.email}</div>
                          </div>
                        </div>

                        <div style={{ width: '10%' }}>
                          <div className="txt1">
                            {item?.vault?.[result]?.toFixed(2)}
                          </div>
                          {/* <div className="txt2">3:32 PM EST</div> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          )}
        </div>
        {shareTokenDrawerOpen && (
          <div className="popular">
            <div className="head-txt">
              <div>My ShareTokens</div>
              <div
                onClick={() => {
                  setshareTokenDrawerOpen(false);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            {shareTokenDrawerLoader ? (
              <>
                {Array(10)
                  .fill('')
                  ?.map((item) => {
                    return (
                      <div className="each-action">
                        <div>
                          {' '}
                          <Skeleton width={120} height={25} />
                        </div>
                      </div>
                    );
                  })}
              </>
            ) : (
              <>
                {shareTokenDrawer?.map((item) => {
                  return (
                    <div
                      className="each-action"
                      style={{
                        justifyContent: 'flex-start',
                      }}
                      onClick={() => {
                        setTokenValue(item?.token_profile_data?.coinSymbol);
                        setshareTokenDrawerOpen(false);
                      }}
                    >
                      <img
                        style={{
                          height: '27px',
                          marginRight: '10px',
                        }}
                        src={item?.token_profile_data?.coinImage}
                      />
                      <div>{item?.token_profile_data?.coinName}</div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        )}
        {companytoggle && (
          <div className="popular">
            <div className="head-txt">
              <div>Company Actions</div>
              <div
                onClick={() => {
                  setcompanytoggle(false);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <Mcb
              setcompanytoggle={setcompanytoggle}
              companytoggle={companytoggle}
            />
          </div>
        )}

        {offteringAction == 'offeringsSuccesful' ? (
          <div className="popular">
            <div className="finalMessage">
              You have successfully deleted the offering icon for&nbsp;
              {selectedTokenOfferingsData
                ? selectedTokenOfferingsData?.coinName
                : ''}
              You will be redirected to the updated video list now.
            </div>
          </div>
        ) : offteringAction == 'offeringsIcon' ? (
          <div className="popular">
            <div className="head-txt">
              <div>Edit Offering Details</div>
              <div
                onClick={() => {
                  setoffteringAction(false);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <div className="offeringsIconMenu" id="workingdiv">
              <div>
                <img
                  src={
                    selectedTokenOfferingsData
                      ? selectedTokenOfferingsData?.coinImage
                      : ''
                  }
                  alt=""
                />
              </div>
              <div>
                <ImageUploadDiv
                  setFunc={setSelectedTokenOfferingsImage}
                  funcValue={selectedTokenOfferingsImage}
                  uploadIcon={upload}
                />
              </div>
            </div>
            <div className="offeringsIconMenuBtn">
              <div
                onClick={submitIconEdit}
                style={{
                  pointerEvents: selectedTokenOfferingsImage ? '' : 'none',
                  opacity: selectedTokenOfferingsImage ? '' : '0.7',
                }}
              >
                Submit Edit
              </div>
              <div
                onClick={() => {
                  setoffteringAction('editOfferingDetails');
                }}
              >
                Go Back
              </div>
            </div>
          </div>
        ) : offteringAction == 'editOfferingDetails' ? (
          <div className="popular">
            <div className="head-txt">
              <div>Edit Offering Details</div>
              <div
                onClick={() => {
                  setoffteringAction(false);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div
              className="each-action"
              onClick={() => {
                setoffteringAction('offeringsIcon');
              }}
            >
              <div
                style={{
                  height: '45px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Offering Icon
              </div>
            </div>
          </div>
        ) : (
          offteringAction && (
            <div className="popular">
              <div className="head-txt">
                <div>Offering Actions</div>
                <div
                  onClick={() => {
                    setoffteringAction(false);
                  }}
                  className="close-div"
                >
                  <img src={close} alt="" />
                </div>
              </div>
              <div className="each-action">
                <div>Campaign On</div>
                <div className="togglecomp">
                  <div className="toggle-div">
                    <input
                      className="tgl1"
                      type="checkbox"
                      id="switch"
                      onClick={(e) => handlecheck(e.target.checked)}
                      checked={check}
                    />
                    <label className="tgl2" htmlFor="switch" />
                  </div>
                </div>
              </div>
              <div
                className="each-action"
                onClick={() => {
                  setoffteringAction('editOfferingDetails');
                }}
              >
                <div
                  style={{
                    height: '45px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Edit Offering Details
                </div>
              </div>
            </div>
          )
        )}
        {actionEnabled && (
          <div className="popular">
            <div className="head-txt">
              <div>Offering Actions</div>
              <div
                onClick={() => {
                  setActionEnabled(false);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <div
              className="each-action"
              style={{
                pointerEvents: profileData?.length > 0 && 'none',
              }}
              onClick={() => {
                setActionEnabled(false);
                setEntrepreneurEnabled(true);
              }}
            >
              <div>Become An Entrepreneur</div>
              {profileData?.length > 0 && <img src={checks} />}
            </div>
            <div
              className="each-action"
              style={{
                pointerEvents: promote?.status && 'none',
              }}
              onClick={() => {
                setActionEnabled(false);
                setpromoterStep1(true);
              }}
            >
              <div>Become A Promoter</div>
              {promote?.status && <img src={checks} />}
            </div>
            <div
              className="each-action"
              onClick={() => {
                setActionEnabled(false);
                setcompanytoggle(true);
              }}
            >
              <div>Add Your Company</div>
            </div>
            <div
              className="each-action"
              onClick={() => {
                setActionEnabled(false);
                setIssueShareTokenEnabled(true);
              }}
            >
              <div>Issue A ShareToken</div>
            </div>

            <div
              className="each-action"
              onClick={() => {
                setActionEnabled(false);
                setcatDeatil(true);
              }}
            >
              <div>Add Category</div>
            </div>
          </div>
        )}

        {promoterStep1 && (
          <div className="popular">
            <div className="head-txt">
              <div>Become Promoter (Step 1)</div>
              <div
                onClick={() => {
                  setpromoterStep1(false);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <div className="overall-div">
              <div
                style={{
                  fontSize: '1.1rem',
                  marginTop: '15px',

                  marginBottom: '14px',
                }}
              >
                Upload Profile Picture
              </div>
              <ImageUploadDivProfilePic
                setFunc={setbrandimgstep1}
                funcValue={brandimgstep1}
              />
              <InputDivs
                heading="What is your first name?"
                placeholderText="First Name.."
                setFunc={setbrndFristName}
                funcValue={brndFristName}
              />
              <InputDivs
                heading="What is your last name?"
                placeholderText="First Name.."
                setFunc={setbrndLastName}
                funcValue={brndLastName}
              />

              <InputDivsWithBox2
                heading="Create a brand username"
                placeholderText="Username..."
                setFunc={setbranduserNameCheck}
                funcValue={branduserNameCheck}
              />
            </div>
            <div className="stepBtns">
              <div
                style={{ background: '#5F6163' }}
                onClick={() => {
                  setpromoterStep1(false);
                  setActionEnabled(true);
                  setbrandimgstep1('');
                  setbrndFristName('');
                  setbrndLastName('');
                  setbranduserNameCheck('');
                }}
              >
                Go Back
              </div>
              <div
                style={{
                  background: '#4CAF50',
                  opacity:
                    brandimgstep1 &&
                    brndFristName &&
                    brndFristName &&
                    branduserNameCheck
                      ? '1'
                      : '0.25',
                  cursor:
                    brandimgstep1 &&
                    brndFristName &&
                    brndFristName &&
                    branduserNameCheck
                      ? 'pointer'
                      : 'not-allowed',
                }}
                onClick={() => {
                  if (
                    brandimgstep1 &&
                    brndFristName &&
                    brndFristName &&
                    branduserNameCheck
                  ) {
                    createPromterstep1();
                  }
                }}
              >
                Next Step
              </div>
            </div>
          </div>
        )}

Issue A ShareToken
        {
          catDeatil  &&
      

        <div className="popular">
          <>
            <div className="head-txt">
              <div>Add Category</div>
              <div
                onClick={() => {
                  setcatDeatil(false);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <div className="overall-div"
            style={{
              height: "calc(100% - 4rem)",
            }}
            >
              <div style={{ fontSize: '1.1rem', marginBottom: '13px' }}>
                Category icon
              </div>
              <ImageUploadDivProfilePic
                setFunc={setcatlogo}
                funcValue={catlogo}
              />

              <div
                style={{
                  fontSize: '1.1rem',
                  marginBottom: '13px',
                  marginTop: '1.4rem',
                }}
              >
                Category logo
              </div>
              <div
                style={{
                  backgroundImage: `url(${catpic})`,
                  width: '100%',
                  height: '159px',
                  padding: '3rem',
                  border: '0.5px solid #E5E5E5',
                  backgroundPosition: '100% 100%',
                  backgroundReapt: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius:"15px"
                }}
              >
                <ImageUploadDivProfilePicStep2
                  setFunc={setcatpic}
                  funcValue={catpic}
                />
              </div>

              <InputDivs
                heading="What is the category name?"
                placeholderText="Name"
                setFunc={setcatname}
                funcValue={catname}
              />
              <InputDivDescrintion
                heading="Describe the category"
                placeholderText="Description"
                setFunc={setcatdes}
                funcValue={catdes}
              />
              <InputDivs2
                heading="Primary colour code"
                placeholderText="#00000"
                setFunc={setcatPcolor}
                funcValue={catPcolor}
              />

              <InputDivs2
                heading="Secondary colour code"
                placeholderText="#00000"
                setFunc={setcatScolor}
                funcValue={catScolor}
              />
        

            <div
                style={{
                  background: '#4CAF50',
                  width: "100%",
    borderRadius: "35px",
    color: "#ffffff",
    fontWeight: "700",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor:" pointer",
    height: "4rem",
    padding: "1rem",
    marginTop: "1.3rem",
    marginBottom:"1.3rem",
                  opacity:
                    catpic &&
                    catlogo &&
                    catdes &&
                    catScolor &&
                    catPcolor &&
                    catname
                      ? '1'
                      : '0.25',
                  cursor:
                    catpic &&
                    catlogo &&
                    catdes &&
                    catScolor &&
                    catPcolor &&
                    catname
                      ? 'pointer'
                      : 'not-allowed',
                }}
                onClick={() => {
                  if (
                    catpic &&
                    catlogo &&
                    catdes &&
                    catScolor &&
                    catPcolor &&
                    catname
                  ) {
                    createCategory();
                  }
                }}
              >
                Next Step
              </div>

              </div>
          </>
        </div>
          }
        {promoterStep2 && (
          <div className="popular">
            <>
              <div className="head-txt">
                <div>Become Promoter (Step 2)</div>
                <div
                  onClick={() => {
                    setpromoterStep2(false);
                  }}
                  className="close-div"
                >
                  <img src={close} alt="" />
                </div>
              </div>
              <div className="overall-div">
                {/* <div style={{ fontSize: '1.1rem' }}>
                    Upload a picture of the founder
                  </div> */}
                <div style={{ fontSize: '1.1rem' }}>Upload cover photo</div>
                <div
                  style={{
                    backgroundImage: `url(${coverpic})`,
                    width: '100%',
                    height: '159px',
                    padding: '3rem',
                    border: '0.5px solid #E5E5E5',
                    backgroundPosition: '100% 100%',
                    backgroundReapt: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ImageUploadDivProfilePicStep2
                    setFunc={setcoverpic}
                    funcValue={coverpic}
                  />
                </div>

                {/* <div
                  style={{
                    // backgroundImage: `url(${coverpic})`,
                    width: '100%',
                    // height: '159px',
                    // border: '0.5px solid #E5E5E5',
                    backgroundPosition: 'cover',
                    backgroundReapt: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ImageUploadDivProfilePicLogologoCutoms
                    setFunc={setbrandimgstep1}
                    funcValue={brandimgstep1}
                  />
                </div> */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src={brandimgstep1}
                    style={{
                      // backgroundImage: `url(${coverpic})`,
                      width: '8rem',
                      height: '8rem',
                      marginTop: '1rem',
                      // border: '0.5px solid #E5E5E5',
                      backgroundPosition: 'cover',
                      backgroundReapt: 'none',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                    }}
                  />
                </div>
                {/* <div
                    style={{
                      marginTop: '2rem',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.7rem',
                    }}
                  >
                    <div style={{ fontSize: '1.1rem' }}>
                      Upload a picture of the founder
                    </div>
                    <div
                      style={{
                        backgroundImage: `url(${coverpic})`,
                        width: '100%',
                        height: '159px',
                        border: '0.5px solid #E5E5E5',
                        backgroundPosition: 'cover',
                        backgroundReapt: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                    
                      <ImageUploadDivProfilePicLogo
                        setFunc={setcoverpic}
                        funcValue={coverpic}
                      />
                    </div>
                  </div> */}
                {/* <div
                    style={{
                      marginTop: '2rem',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.7rem',
                    }}
                  >
                    <div style={{ fontSize: '1.1rem' }}>
                      Upload a picture of the founder
                    </div>
                    <ImageUploadDivProfilePic
                      setFunc={setUserPic}
                      funcValue={userPic}
                    />
                  </div> */}
                <InputDivs
                  heading="Brand Display Name"
                  placeholderText="Username.."
                  setFunc={setbrandusername}
                  funcValue={brandusername}
                />

                <InputDivsWithBox2
                  heading="Brand Username"
                  placeholderText="Enter Up To Four Characters"
                  setFunc={setBrancheckStep2}
                  funcValue={BrancheckStep2}
                />
                <InputDivDescrintion
                  heading="Describe Your Brand"
                  placeholderText="Username.."
                  setFunc={setbrandDes}
                  funcValue={brandDes}
                />

                <InputDivs2
                  heading="Brand Colour Code"
                  placeholderText="#000000"
                  setFunc={setEColorCode}
                  funcValue={EColorCode}
                />
                <InputDivsWithArrow
                  heading="What Country Is The Brand Headquartered In?"
                  placeholderText="Click To Select"
                  setFunc={setbrandCountry}
                  funcValue={brandCountry}
                  getinputvalue={getinputCountry}
                />
                <InputDivs
                  heading="What is the brand address?"
                  placeholderText="Enter address..."
                  setFunc={setbrandAddress}
                  funcValue={brandAddress}
                />
                {/* <InputDivs
                  heading="Brand Display Name"
                  placeholderText="Username.."
                  setFunc={setbrandusername}
                  funcValue={brandusername}
                /> */}

                <div
                  style={{
                    fontSize: '1.1rem',
                    marginTop: '35px',

                    marginBottom: '14px',
                  }}
                >
                  Upload white profile picture
                </div>
                <ImageUploadDivProfilePicCovering
                  setFunc={setbrandimgstep2}
                  funcValue={brandimgstep2}
                />
                {/* <ImageUploadDivProfilePicset1
                  setFunc={setprofileBrand}
                  funcValue={profileBrand}
                /> */}

                {/* <InputDivs
                    heading="What is your contact email?"
                    placeholderText="Enter the email.."
                    setFunc={setbrandEmail}
                    funcValue={brandEmail}
                  /> */}

                {/* <div className="split-divs">
                    <InputDivs
                      heading="City"
                      placeholderText="City"
                      // setFunc={setaidropdownRegLink}
                      // funcValue={aidropdownRegLink}
                    />
                    <InputDivs
                      heading="Postal code"
                      placeholderText="Postal code"
                      // setFunc={setaidropdownRegLink}
                      // funcValue={aidropdownRegLink}
                    />
                  </div> */}
              </div>
              <div className="stepBtns">
                <div
                  style={{ background: '#5F6163' }}
                  onClick={() => {
                    setpromoterStep2(false);
                    setpromoterStep1(true);
                    setActionEnabled(true);
                    setbrandCountry('');
                    setEColorCode('');
                    setbrandDes('');
                    setBrancheckStep2('');
                    setprofileBrand('');
                    setbrandusername('');
                    setcoverpic('');
                    setbrandimgstep2('')
                  }}
                >
                  Go Back
                </div>
                <div
                  style={{
                    background: '#4CAF50',
                    // opacity:
                    //   brandCountry &&
                    //   EColorCode &&
                    //   brandDes &&
                    //   BrancheckStep2 &&
                    //   profileBrand &&
                    //   brandusername &&
                    //   coverpic
                    //     ? '1'
                    //     : '0.25',
                    // cursor:
                    //   brandCountry &&
                    //   EColorCode &&
                    //   brandDes &&
                    //   BrancheckStep2 &&
                    //   profileBrand &&
                    //   brandusername &&
                    //   coverpic
                    //     ? 'pointer'
                    //     : 'not-allowed',
                  }}
                  onClick={() => {
                    // if (
                    //   brandCountry &&
                    //   EColorCode &&
                    //   brandDes &&
                    //   BrancheckStep2 &&
                    //   profileBrand &&
                    //   brandusername &&
                    //   coverpic
                    // ) {
                    //   createPromterstep2();
                    // }
                    createPromterstep2();
                  }}
                >
                  Next Step
                </div>
              </div>
            </>

            {/* 
            {promotloader  && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%', height: '100%' }}
              >
                <LoadingAnimation icon={appLogo} width={200} />
              </div>
            )} */}

            {/* {entrepreneurStep === 2 && (
              <div className="successMsgg">
                Entrepreneur Profile Successfully Created
              </div>
            )} */}
          </div>
        )}


        {sucessMessageShow && (
          <div className="popular1">
            <div className="innerscucess">
              <div>Promoter Successfully Created</div>
            </div>
          </div>
        )}

        {corporateBrandEnabled && (
          <div className="popular1">
            {corporateBrandStep === 1 && (
              <>
                <div className="head-txt">
                  <div>Corporate Brand</div>
                  <div
                    onClick={() => {
                      setCorporateBrandEnabled(false);
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div className="overall-div">
                  <div className="subbTxt">
                    What country is your company headquartered in?
                  </div>
                  <div className="each-action">
                    <img src={dummy} alt="" />
                    <div>India</div>
                  </div>
                  <div className="each-action">
                    <img src={dummy} alt="" />
                    <div>India</div>
                  </div>
                  <div className="each-action">
                    <img src={dummy} alt="" />
                    <div>India</div>
                  </div>
                  <div className="each-action">
                    <img src={dummy} alt="" />
                    <div>India</div>
                  </div>
                  <div className="each-action">
                    <img src={dummy} alt="" />
                    <div>India</div>
                  </div>
                  <div className="each-action">
                    <img src={dummy} alt="" />
                    <div>India</div>
                  </div>
                </div>
              </>
            )}

            {corporateBrandStep === 2 && (
              <>
                <div className="head-txt">
                  <div>Corporate Brand</div>
                  <div
                    onClick={() => {
                      setCorporateBrandEnabled(false);
                      setBrandName('');
                      setCountryCode('');
                      setCountry('');
                      setContactNo('');
                      setFoundedDate('');
                      setColorCode('');
                      setCoverPhoto('');
                      setLogo('');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div className="overall-div">
                  <div className="coverPic-container">
                    <div className="coverPicDiv">
                      <ImageUploadDivCoverPic
                        setFunc={setCoverPhoto}
                        funcValue={coverPhoto}
                      />
                    </div>
                    <div className="logoDiv">
                      <ImageUploadDivLogo setFunc={setLogo} funcValue={logo} />
                    </div>
                  </div>
                  <div className="inputs-container">
                    <InputDivs
                      heading="What is your company’s brand name?"
                      placeholderText="Brand Name"
                      setFunc={setBrandName}
                      funcValue={brandName}
                    />
                    <InputDivs3
                      heading="What is the primary contact number for your company?"
                      placeholderText="+91"
                      setFunc={setCountryCode}
                      funcValue={countryCode}
                      placeholderText1="India"
                      setFunc1={setCountry}
                      funcValue1={country}
                      placeholderText2="000-000-0000"
                      setFunc2={setContactNo}
                      funcValue2={contactNo}
                    />
                    <InputDivs
                      heading="When was the company founded?"
                      placeholderText="Enter date"
                      setFunc={setFoundedDate}
                      funcValue={foundedDate}
                    />
                    <InputDivs2
                      heading="What is your company’s colour code?"
                      placeholderText="#000000"
                      setFunc={setColorCode}
                      funcValue={colorCode}
                    />
                    <div className="stepBtns">
                      <div
                        style={{ background: '#5F6163' }}
                        onClick={() => {
                          setCorporateBrandEnabled(false);
                          setActionEnabled(true);
                          setBrandName('');
                          setCountryCode('');
                          setCountry('');
                          setContactNo('');
                          setFoundedDate('');
                          setColorCode('');
                          setCoverPhoto('');
                          setLogo('');
                        }}
                      >
                        Go Back
                      </div>
                      <div
                        style={{
                          background: '#4CAF50',
                          opacity:
                            brandName &&
                            countryCode &&
                            country &&
                            contactNo &&
                            foundedDate &&
                            colorCode &&
                            coverPhoto &&
                            logo
                              ? '1'
                              : '0.25',
                          cursor:
                            brandName &&
                            countryCode &&
                            country &&
                            contactNo &&
                            foundedDate &&
                            colorCode &&
                            coverPhoto &&
                            logo
                              ? 'pointer'
                              : 'not-allowed',
                        }}
                        // onClick={() => {
                        //   if (brandName &&
                        //     countryCode &&
                        //     country &&
                        //     contactNo &&
                        //     foundedDate &&
                        //     colorCode &&
                        //     coverPhoto &&
                        //     logo) {
                        //     createEntrepreneurialProfile();
                        //   }
                        // }}
                      >
                        Next Step
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {corporateBrandStep === 3 && (
              <div className="successMsgg">
                Corporate Brand Successfully Created
              </div>
            )}
          </div>
        )}

        {issueShareTokenEnabled && (
          <div className="popular1">
            {issueShareTokenStep === 1 && (
              <>
                <div className="head-txt">
                  <div>New ShareToken</div>
                  <div
                    onClick={() => {
                      setIssueShareTokenEnabled(false);
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div className="overall-div">
                  {/* <label onClick={callendfunct}>sad</label> */}
                  <div className="inputs-container">
                    <InputDivsWithArrow
                      heading="Select Issuance Asset"
                      getappinfo={getappinfo}
                      placeholderText="Click To Select"
                      setFunc={setIssuanceAsset}
                      funcValue={issuanceAsset}
                      getinputvalue={getinputvalue}
                    />
                    <InputDivsWithArrow
                      heading="Select Issuance Country"
                      placeholderText="Click To Select"
                      setFunc={setIssuanceCountry}
                      funcValue={issuanceCountry}
                      getinputvalue={getinputCountry}
                    />
                    <InputDivsWithArrow
                      heading="Select Company For ShareToken"
                      placeholderText="Click To Select"
                      setFunc={setCompany}
                      funcValue={company}
                      getinputvalue={getinputcompany}
                    />
                    <InputDivsWithBox2
                      heading="Create Your ShareToken Ticker Symbol"
                      placeholderText="Enter Up To Four Characters"
                      setFunc={setTickerSymbol}
                      funcValue={tickerSymbol}
                    />
                    <InputDivs
                      heading="Create Your ShareToken Name"
                      placeholderText="Enter ShareToken Full Name"
                      setFunc={setSharetokenFullName}
                      funcValue={sharetokenFullName}
                    />
                    <ImageUploadDiv1
                      heading="Upload ShareToken Icon"
                      setFunc={setShareTokenIcon}
                      funcValue={shareTokenIcon}
                    />
                    <InputDivs2
                      heading="Enter Primary Colour Code"
                      placeholderText="Enter Primary Colour Code"
                      setFunc={setPrimaryColorCode}
                      funcValue={primaryColorCode}
                    />
                    <InputDivs2
                      heading="Enter Secondary Colour Code"
                      placeholderText="Enter Secondary Colour Code"
                      setFunc={setSecondaryColorCode}
                      funcValue={secondaryColorCode}
                    />
                    <InputDivsWithArrow
                      heading="Select Equity Round"
                      placeholderText="Enter Secondary Colour Code"
                      setFunc={setEquityRound}
                      funcValue={equityRound}
                    />
                    <InputDivs
                      heading="How Many ShareTokens Are You Issuing"
                      placeholderText="Enter Initial Supply..."
                      setFunc={setInitialSupply}
                      funcValue={initialSupply}
                    />
                    <InputDivsWithBox
                      heading="What Is The Initial Sale Price?"
                      placeholderText="Enter Price Per Token"
                      setFunc={setInitialSalePrice}
                      getappinfo={getappinfo}
                      funcValue={initialSalePrice}
                    />
                    <InputDivsWithBox1
                      heading="What Is The Commission For Affiliates?"
                      placeholderText="Enter Percentage Of Sale..."
                      setFunc={setAffiliateCommission}
                      funcValue={affiliateCommission}
                    />
                    <InputDivsWithBox1
                      heading="Set Direct Affiliate Commission"
                      placeholderText="Enter Percentage Of Sale..."
                      setFunc={setDirectAffiliateCommission}
                      funcValue={directAffiliateCommission}
                    />
                    <InputDivsWithBox1
                      heading="Set NetworkChain Commission"
                      placeholderText="Enter Percentage Of Sale..."
                      setFunc={setNetworkChainCommission}
                      funcValue={networkChainCommission}
                    />
                    <InputDivsWithBox1
                      heading="Set Direct Executive Override"
                      placeholderText="Enter Percentage Of Sale..."
                      setFunc={setDirectExecutiveOverride}
                      funcValue={directExecutiveOverride}
                    />
                    <InputDivsWithBox1
                      heading="Set Executive NetworkChain Commission"
                      placeholderText="Enter Percentage Of Sale..."
                      setFunc={setExecutiveNetworkChainCommission}
                      funcValue={executiveNetworkChainCommission}
                    />

                    <InputDivs
                      heading="How Much Are ShareTokens"
                      placeholderText="0"
                      setFunc={setshareTokenPrice}
                      funcValue={shareTokenPrice}
                    />

                    <InputDivs
                      heading="How Much Are SafeTokens"
                      placeholderText="0"
                      setFunc={setSafetokenPrice}
                      funcValue={SafetokenPrice}
                    />

                    <InputDivs
                      heading="How Much Are Esops"
                      placeholderText="0"
                      setFunc={setEsops}
                      funcValue={Esops}
                    />

                    <InputDivs
                      heading="How Much Are CSOPS"
                      placeholderText="0"
                      setFunc={setCSOPS}
                      funcValue={CSOPS}
                    />
                    <div className="stepBtns">
                      <div
                        style={{ background: '#5F6163' }}
                        onClick={() => {
                          setIssueShareTokenEnabled(false);
                          setActionEnabled(true);
                          setshareTokenPrice('');
                          setEsops('');
                          setCSOPS('');
                          setSafetokenPrice('');
                          setExecutiveNetworkChainCommission('');
                          setDirectExecutiveOverride('');
                          setNetworkChainCommission('');
                          setDirectAffiliateCommission('');
                          setAffiliateCommission('');
                          setInitialSalePrice('');
                          setInitialSupply('');
                          setEquityRound('');
                          setSecondaryColorCode('');
                          setPrimaryColorCode('');
                          setSharetokenFullName('');
                          setTickerSymbol('');
                          setCompany('');
                          setIssuanceCountry('');
                          setIssuanceAsset('');
                          setShareTokenIcon('');
                        }}
                      >
                        Go Back
                      </div>
                      <div
                        style={{
                          background: '#4CAF50',
                          opacity:
                            executiveNetworkChainCommission &&
                            directExecutiveOverride &&
                            networkChainCommission &&
                            directAffiliateCommission &&
                            affiliateCommission &&
                            initialSalePrice &&
                            initialSupply &&
                            equityRound &&
                            secondaryColorCode &&
                            CSOPS &&
                            primaryColorCode &&
                            SafetokenPrice &&
                            sharetokenFullName &&
                            tickerSymbol &&
                            company &&
                            Esops &&
                            issuanceCountry &&
                            issuanceAsset &&
                            shareTokenIcon
                              ? '1'
                              : '0.25',
                          cursor:
                            executiveNetworkChainCommission &&
                            directExecutiveOverride &&
                            networkChainCommission &&
                            directAffiliateCommission &&
                            affiliateCommission &&
                            initialSalePrice &&
                            initialSupply &&
                            equityRound &&
                            secondaryColorCode &&
                            primaryColorCode &&
                            sharetokenFullName &&
                            tickerSymbol &&
                            company &&
                            issuanceCountry &&
                            issuanceAsset &&
                            shareTokenPrice &&
                            shareTokenIcon
                              ? 'pointer'
                              : 'not-allowed',
                        }}
                        onClick={callfunction}
                        // onClick={() => {
                        //   if (
                        //     executiveNetworkChainCommission &&
                        //     directExecutiveOverride &&
                        //     networkChainCommission &&
                        //     directAffiliateCommission &&
                        //     affiliateCommission &&
                        //     initialSalePrice &&
                        //     initialSupply &&
                        //     equityRound &&
                        //     secondaryColorCode &&
                        //     primaryColorCode &&
                        //     sharetokenFullName &&
                        //     tickerSymbol &&
                        //     company &&
                        //     issuanceCountry &&
                        //     issuanceAsset &&
                        //     shareTokenIcon
                        //   ) {
                        //     createEntrepreneurialProfile();
                        //   }
                        // }}
                      >
                        Next Step
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        {sucessMessage && (
          <div className="popular1">
            <div className="innerscucess">
              <div>
                You Have Successfully Issued A ShareToken For {pathname}
              </div>
            </div>
          </div>
        )}

        {equityRoundEnabled && (
          <div className="popular1">
            <div className="head-txt">
              <div>New ShareToken</div>
              <div
                onClick={() => {
                  setEquityRoundEnabled(false);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <div className="overall-div1">
              <div className="subbTxt">Select The Equity Round</div>
              <div className="searchDiv">
                <input type="text" placeholder="Search Series....|" />
              </div>
              <div className="scrollableDiv">
                {Namelist?.map((e, i) => {
                  return (
                    <div
                      className="eachAsset"
                      key={i}
                      onClick={() => {
                        setEquityRound(e?.name);
                        setEquityRoundEnabled(false);
                        setIssueShareTokenEnabled(true);
                      }}
                    >
                      {/* <div>
                        <img src={e?.img} alt="" />
                      </div> */}
                      <div>{e?.name}</div>
                    </div>
                  );
                })}
              </div>
              <div className="stepBtns">
                <div
                  style={{ background: '#5F6163' }}
                  onClick={() => {
                    setEquityRoundEnabled(false);
                    setIssueShareTokenEnabled(true);
                  }}
                >
                  Go Back
                </div>
                <div style={{ background: '#4CAF50', opacity: '0.5' }}>
                  Next Step
                </div>
              </div>
            </div>
          </div>
        )}

        {companyForShareTokenEnabled && (
          <div className="popular1">
            <div className="head-txt">
              <div>New ShareToken</div>
              <div
                onClick={() => {
                  setCompanyForShareTokenEnabled(false);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <div className="overall-div1">
              <div className="subbTxt">Select One Of Your Companies</div>
              <div className="searchDiv">
                <input
                  type="text"
                  placeholder="Search Companies....|"
                  onChange={(e) => setsearchitemCompany(e.target.value)}
                />
              </div>
              <div className="scrollableDiv">
                {stake
                  ?.filter((tile) =>
                    tile?.app_name
                      ?.toLowerCase()
                      ?.includes(searchitemCompany?.toLowerCase())
                  )
                  ?.map((e, i) => {
                    return (
                      <div
                        className="eachAsset"
                        key={i}
                        onClick={() => {
                          setgetinputcompany({
                            coinSymbol: e?.napp_name,
                            coinImage: e?.app_icon,
                          });
                          setpathname(e?.napp_name);
                          setCompany(e?.app_code);
                          setCompanyForShareTokenEnabled(false);
                          setIssueShareTokenEnabled(true);
                        }}
                      >
                        <div>
                          <img src={e?.app_icon} alt="" />
                        </div>
                        <div>{e?.app_name}</div>
                      </div>
                    );
                  })}
              </div>
              <div className="stepBtns">
                <div
                  style={{ background: '#5F6163' }}
                  onClick={() => {
                    setCompanyForShareTokenEnabled(false);
                    setIssueShareTokenEnabled(true);
                  }}
                >
                  Go Back
                </div>
                <div style={{ background: '#4CAF50', opacity: '0.5' }}>
                  Next Step
                </div>
              </div>
            </div>
          </div>
        )}

        {entrepreneurEnabled && (
          <div className="popular">
            {entrepreneurStep === 1 && (
              <>
                <div className="head-txt">
                  <div>Entrepreneur Profile</div>
                  <div
                    onClick={() => {
                      setEntrepreneurEnabled(false);
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div className="overall-div">
                  <div style={{ fontSize: '1.1rem', marginBottom: '12px' }}>
                    Upload cover photo
                  </div>
                  <div
                    style={{
                      backgroundImage: `url(${entercover})`,
                      width: '100%',
                      height: '179px',
                      border: '0.5px solid #E5E5E5',
                      backgroundPosition: '100% 100%',
                      backgroundReapt: 'none',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <ImageUploadDivProfilePic
                      setFunc={setentercover}
                      funcValue={entercover}
                    />
                  </div>
                  <div style={{ fontSize: '1.1rem', marginTop: '1.3rem' }}>
                    Upload a picture of the founder
                  </div>
                  <ImageUploadDivProfilePic
                    setFunc={setUserPic}
                    funcValue={userPic}
                  />
                  <InputDivs
                    heading="What is the name of your brand?"
                    placeholderText="Enter brand name.."
                    setFunc={setFirstName}
                    funcValue={firstName}
                  />
                  <InputDivsWithArrow
                    heading="Which country is your brand in?"
                    placeholderText="Click To Select"
                    setFunc={setebrandCountry}
                    funcValue={ebrandCountry}
                    getinputvalue={getinputCountry}
                  />
                  <InputDivs
                    heading="What is your contact email?"
                    placeholderText="Enter brand name.."
                    setFunc={setLastName}
                    funcValue={lastName}
                  />
                  <InputDivs2
                    heading="Brand Colour Code"
                    placeholderText="#000000"
                    setFunc={setstepcolorCode}
                    funcValue={stepcolorCode}
                  />

                  <InputDivs
                    heading="When was your brand founded?"
                    placeholderText="Enter date.."
                    setFunc={setUserName}
                    funcValue={userName}
                  />
                  <InputDivs3
                    heading="What is your brands contact number?"
                    placeholderText="+91"
                    setFunc={setCountryCode}
                    funcValue={countryCode}
                    placeholderText1="India"
                    setFunc1={setCountry}
                    funcValue1={country}
                    placeholderText2="000-000-0000"
                    setFunc2={setcontactNumberEnter}
                    funcValue2={contactNumberEnter}
                  />
                  {/* <InputDivs
                    heading="What is your brands contact number?"
                    placeholderText="000-000-0000"
                    setFunc={setUserName}
                    funcValue={userName}
                  /> */}
                  <div
                    style={{
                      marginTop: '2rem',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.7rem',
                    }}
                  ></div>
                </div>
                <div className="stepBtns">
                  <div
                    style={{ background: '#5F6163' }}
                    onClick={() => {
                      setEntrepreneurEnabled(false);
                      setActionEnabled(true);
                      setUserPic('');
                      setUserName('');
                      setLastName('');
                      setFirstName('');
                      setentercover('');

                      setebrandCountry('');

                      setstepcolorCode('');

                      setcontactNumberEnter('');
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: '#4CAF50',
                      opacity:
                        userName &&
                        userPic &&
                        firstName &&
                        lastName &&
                        entercover &&
                        ebrandCountry &&
                        stepcolorCode &&
                        contactNumberEnter
                          ? '1'
                          : '0.25',
                      cursor:
                        userName &&
                        userPic &&
                        firstName &&
                        lastName &&
                        entercover &&
                        ebrandCountry &&
                        stepcolorCode &&
                        contactNumberEnter
                          ? 'pointer'
                          : 'not-allowed',
                    }}
                    onClick={() => {
                      if (
                        userName &&
                        userPic &&
                        firstName &&
                        lastName &&
                        entercover &&
                        ebrandCountry &&
                        stepcolorCode &&
                        contactNumberEnter
                      ) {
                        createEntrepreneurialProfile();
                      }
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {entrepreneurLoading && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%', height: '100%' }}
              >
                <LoadingAnimation icon={appLogo} width={200} />
              </div>
            )}

            {entrepreneurStep === 2 && (
              <div className="successMsgg">
                Entrepreneur Profile Successfully Created
              </div>
            )}
          </div>
        )}

        {EBrandedCountryEnabled && (
          <div className="popular1">
            <div className="head-txt">
              <div>Become An Entrepreneur</div>
              <div
                onClick={() => {
                  setEBrandedCountryEnabled(false);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <div className="overall-div1">
              <div className="subbTxt">Select Issuance Country</div>
              <div className="searchDiv">
                <input
                  type="text"
                  placeholder="Search Countries....|"
                  onChange={(e) => setsearchitemCountry(e.target.value)}
                />
              </div>
              <div className="scrollableDiv">
                {countrydata
                  .filter((tile) =>
                    tile?.name
                      ?.toLowerCase()
                      ?.includes(searchitemCountry?.toLowerCase())
                  )
                  ?.map((e, i) => {
                    return (
                      <div
                        className="eachAsset"
                        key={i}
                        onClick={() => {
                          setgetinputCountry({
                            coinSymbol: e?.name,
                            coinImage: e?.flag,
                          });
                          setebrandCountry(e?.name);
                          setEBrandedCountryEnabled(false);
                          // setIssueShareTokenEnabled(true);
                        }}
                      >
                        <div>
                          <img src={e?.flag} alt="" />
                        </div>
                        <div>{e?.name}</div>
                      </div>
                    );
                  })}
              </div>
              <div className="stepBtns">
                <div
                  style={{ background: '#5F6163' }}
                  onClick={() => {
                    // setIssuanceCountryEnabled(false);
                    // setIssueShareTokenEnabled(true);
                  }}
                >
                  Go Back
                </div>
                <div style={{ background: '#4CAF50', opacity: '0.5' }}>
                  Next Step
                </div>
              </div>
            </div>
          </div>
        )}
        {BrandedCountryEnabled && (
          <div className="popular1">
            <div className="head-txt">
              <div>New ShareToken</div>
              <div
                onClick={() => {
                  setBrandedCountryEnabled(false);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <div className="overall-div1">
              <div className="subbTxt">Select Issuance Country</div>
              <div className="searchDiv">
                <input
                  type="text"
                  placeholder="Search Countries....|"
                  onChange={(e) => setsearchitemCountry(e.target.value)}
                />
              </div>
              <div className="scrollableDiv">
                {countrydata
                  .filter((tile) =>
                    tile?.name
                      ?.toLowerCase()
                      ?.includes(searchitemCountry?.toLowerCase())
                  )
                  ?.map((e, i) => {
                    return (
                      <div
                        className="eachAsset"
                        key={i}
                        onClick={() => {
                          setgetinputCountry({
                            coinSymbol: e?.name,
                            coinImage: e?.flag,
                          });
                          setbrandCountry(e?.name);
                          setBrandedCountryEnabled(false);
                          // setIssueShareTokenEnabled(true);
                        }}
                      >
                        <div>
                          <img src={e?.flag} alt="" />
                        </div>
                        <div>{e?.name}</div>
                      </div>
                    );
                  })}
              </div>
              <div className="stepBtns">
                <div
                  style={{ background: '#5F6163' }}
                  onClick={() => {
                    setIssuanceCountryEnabled(false);
                    setIssueShareTokenEnabled(true);
                  }}
                >
                  Go Back
                </div>
                <div style={{ background: '#4CAF50', opacity: '0.5' }}>
                  Next Step
                </div>
              </div>
            </div>
          </div>
        )}

        {issuanceCountryEnabled && (
          <div className="popular1">
            <div className="head-txt">
              <div>New ShareToken</div>
              <div
                onClick={() => {
                  setIssuanceCountryEnabled(false);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <div className="overall-div1">
              <div className="subbTxt">Select Issuance Country</div>
              <div className="searchDiv">
                <input
                  type="text"
                  placeholder="Search Countries....|"
                  onChange={(e) => setsearchitemCountry(e.target.value)}
                />
              </div>
              <div className="scrollableDiv">
                {countrydata
                  .filter((tile) =>
                    tile?.name
                      ?.toLowerCase()
                      ?.includes(searchitemCountry?.toLowerCase())
                  )
                  ?.map((e, i) => {
                    return (
                      <div
                        className="eachAsset"
                        key={i}
                        onClick={() => {
                          setgetinputCountry({
                            coinSymbol: e?.name,
                            coinImage: e?.flag,
                          });
                          setIssuanceCountry(e?.name);
                          setIssuanceCountryEnabled(false);
                          setIssueShareTokenEnabled(true);
                        }}
                      >
                        <div>
                          <img src={e?.flag} alt="" />
                        </div>
                        <div>{e?.name}</div>
                      </div>
                    );
                  })}
              </div>
              <div className="stepBtns">
                <div
                  style={{ background: '#5F6163' }}
                  onClick={() => {
                    setIssuanceCountryEnabled(false);
                    setIssueShareTokenEnabled(true);
                  }}
                >
                  Go Back
                </div>
                <div style={{ background: '#4CAF50', opacity: '0.5' }}>
                  Next Step
                </div>
              </div>
            </div>
          </div>
        )}
        {issuanceAssetEnabled && (
          <div className="popular1">
            <div className="head-txt">
              <div>New ShareToken</div>
              <div
                onClick={() => {
                  setIssuanceAssetEnabled(false);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <div className="overall-div1">
              <div className="subbTxt">Select Issuance Asset</div>
              <div className="searchDiv">
                <input
                  type="text"
                  placeholder="Search Assets..."
                  onChange={(e) => setsearchitem(e.target.value)}
                />
              </div>
              <div className="scrollableDiv">
                {filteredIssuanceAssetData
                  .filter((tile) =>
                    tile?.coinName
                      ?.toLowerCase()
                      ?.includes(searchitem?.toLowerCase())
                  )
                  ?.map((e, i) => {
                    return (
                      <div
                        className="eachAsset"
                        key={i}
                        onClick={() => {
                          setgetinputvalue(e);
                          setgetappinfo(e);
                          setIssuanceAsset(e?.coinSymbol);
                          setIssuanceAssetEnabled(false);
                          setIssueShareTokenEnabled(true);
                        }}
                      >
                        <div>
                          <img src={e?.coinImage} alt="" />
                        </div>
                        <div>{e?.coinName}</div>
                      </div>
                    );
                  })}
              </div>
              <div className="stepBtns">
                <div
                  style={{ background: '#5F6163' }}
                  onClick={() => {
                    setIssuanceAssetEnabled(false);
                    setIssueShareTokenEnabled(true);
                  }}
                >
                  Go Back
                </div>
                <div style={{ background: '#4CAF50' }}>Next Step</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default CapitalizedPage;

export const ImageUploadDivProfilePicLogologoCutoms = ({
  setFunc,
  funcValue,
}) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(planBContext);

  return (
    <div
      className="imageUploadDiv"
      onClick={() => setSelectedDropDown('')}
      style={{
        width: '100px',
        height: '100px',
        // border: '0.5px solid #e7e7e7',
        borderRadius: '50%',
      }}
      onChange={(e) => {
        uploadImageFunc1(e, setFunc, setplanBAccountPicUploading);
      }}
    >
      <label
        htmlFor="profileUpdateImgPlanB"
        className="uploadFileDiv"
        style={{
          width: '100%',
          height: '100%',
          marginBottom: '0',
        }}
      >
        <input
          className="uploadNewPicPlanB"
          type="file"
          style={{
            border: 'none',
          }}
          onChange={(e) => {
            uploadImageFunc1(e, setFunc, setplanBAccountPicUploading);
          }}
          accept="image/*"
          id="profileUpdateImgPlanB"
        />
        <div
          style={{
            border: 'none',
          }}
        >
          {planBAccountPicUploading ? (
            <div>Uploading</div>
          ) : (
            <div>
              <img
                src={upload}
                alt=""
                style={{ width: '50px', height: '50px' }}
                
              />
            </div>
          )}
        </div>
      </label>
    </div>
  );
};

export const ImageUploadDivProfilePicLogo = ({ setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(planBContext);

  return (
    <div
      className="imageUploadDiv"
      onClick={() => setSelectedDropDown('')}
      style={{
        width: '100px',
        height: '100px',
        // border: '0.5px solid #e7e7e7',
        borderRadius: '50%',
      }}
    >
      <label
        htmlFor="profileUpdateImgPlanB"
        className="uploadFileDiv"
        style={{
          width: '100%',
          height: '100%',
          marginBottom: '0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <input
          className="uploadNewPicPlanB"
          type="file"
          style={{
            border: 'none',
          }}
          onChange={(e) => {
            uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
          }}
          accept="image/*"
          id="profileUpdateImgPlanB"
        />
        <div
          style={{
            border: 'none',
          }}
        >
          {planBAccountPicUploading ? (
            <div>Uploading</div>
          ) : (

            
            <div>
              <img
                src={upload}
                alt=""
                style={{ width: '50px', height: '50px' }}
              />
            </div>
          )}
        </div>
      </label>
    </div>
  );
};

export const ImageUploadDivProfilePicset1 = ({ setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(planBContext);

  return (
    <div
      className="imageUploadDiv"
      onClick={() => setSelectedDropDown('')}
      style={{
        width: '100px',
        height: '100px',
        border: '0.5px solid #e7e7e7',
        borderRadius: '50%',
      }}
    >
      {funcValue ? (
        <div className="imageDiv" style={{ marginRight: '0' }}>
          <img
            src={funcValue}
            alt="planBAccountPic"
            className="profileImg"
            htmlFor="profileUpdateImgPlanB"
            style={{ width: '100%', height: '6rem' }}
          />
        </div>
      ) : (
        <label
          htmlFor="profileUpdateImgPlanB"
          className="uploadFileDiv"
          style={{
            width: '100%',
            height: '100%',
            marginBottom: '0',
          }}
        >
          <input
            className="uploadNewPicPlanB"
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
            }}
            accept="image/*"
            id="profileUpdateImgPlanB"
          />
          <div
            style={{
              width: '100%',
              height: '7rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: 'none',
              borderRadius: '50%',
            }}
          >
            <div>
              <img
                src={upload}
                alt=""
                style={{ width: '30px', height: '30px' }}
              />
            </div>
          </div>
        </label>
      )}
    </div>
  );
};
export const ImageUploadDivProfilePicStep2 = ({ setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(planBContext);

  return (
    <div
      className="imageUploadDiv"
      onClick={() => setSelectedDropDown('')}
      style={{
        width: '100%',
        height: '100%',
        // border: '0.5px solid #e7e7e7',
        // borderRadius: '50%',
      }}
    >
      {funcValue ? (
        <div className="imageDiv" style={{ marginRight: '0' }}>
          {/* */}
           <label
          htmlFor="profileUpdateImgPlanc"
          className="uploadFileDiv"
          style={{
            width: '100%',
            height: '100%',
            marginBottom: '0',
          }}
        >
          <input
            className="uploadNewPicPlanB"
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
            }}
            accept="image/*"
            id="profileUpdateImgPlanc"
          />
           <img
            // src={funcValue}
            alt="planBAccountPic"
            className="profileImg"
            htmlFor="profileUpdateImgPlanB"
            style={{ width: '100%', height: '100%' }}
          />
          </label>
        </div>
      ) : (
        <label
          htmlFor="profileUpdateImgPlanc"
          className="uploadFileDiv"
          style={{
            width: '100%',
            height: '100%',
            marginBottom: '0',
          }}
        >
          <input
            className="uploadNewPicPlanB"
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
            }}
            accept="image/*"
            id="profileUpdateImgPlanc"
          />
          <div
            style={{
              width: '100%',
              height: "100%",
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: 'none',
              borderRadius: '50%',
            }}
          >
            <div>
              <img
                src={upload}
                alt=""
                style={{ width: '30px', height: '30px' }}
              />
            </div>
          </div>
        </label>
      )}
    </div>
  );
};

export const ImageUploadDivProfilePicCovering = ({ setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(planBContext);

  return (
    <div
      className="imageUploadDiv"
      onClick={() => setSelectedDropDown('')}
      style={{
        width: '100px',
        height: '100px',
        border: '0.5px solid #e7e7e7',
        borderRadius: '50%',
      }}
    >
      {funcValue ? (
        <div className="imageDiv" style={{ marginRight: '0' }}>
            <label
          htmlFor="profileUpdateImgPlancc"
          className="uploadFileDiv"
          style={{
            width: '100%',
            height: '100%',
            marginBottom: '0',
          }}
        >
          <input
            className="uploadNewPicPlanB"
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
            }}
            accept="image/*"
            id="profileUpdateImgPlancc"
          />
          <img
            src={funcValue}
            alt="planBAccountPic"
            className="profileImg"
            htmlFor="profileUpdateImgPlancc"
            style={{ width: '100%', height: '6rem' }}
          />
          </label>
        </div>
      ) : (
        <label
          htmlFor="profileUpdateImgPlancc"
          className="uploadFileDiv"
          style={{
            width: '100%',
            height: '100%',
            marginBottom: '0',
          }}
        >
          <input
            className="uploadNewPicPlanB"
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
            }}
            accept="image/*"
            id="profileUpdateImgPlancc"
          />
          <div
            style={{
              width: '100%',
              height: '7rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: 'none',
              borderRadius: '50%',
            }}
          >
            <div>
              <img
                src={upload}
                alt=""
                style={{ width: '30px', height: '30px',marginBottom: "10px" }}
              />
            </div>
          </div>
        </label>
      )}
    </div>
  );
};

export const ImageUploadDivProfilePic = ({ setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(planBContext);

  return (
    <div
      className="imageUploadDiv"
      onClick={() => setSelectedDropDown('')}
      style={{
        width: '100px',
        height: '100px',
        border: '0.5px solid #e7e7e7',
        borderRadius: '50%',
      }}
    >
      {funcValue ? (
        <div className="imageDiv" style={{ marginRight: '0' }}>
            <label
          htmlFor="profileUpdateImgPlanB"
          className="uploadFileDiv"
          style={{
            width: '100%',
            height: '100%',
            marginBottom: '0',
          }}
        >
          <input
            className="uploadNewPicPlanB"
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
            }}
            accept="image/*"
            id="profileUpdateImgPlanB"
          />
          <img
            src={funcValue}
            alt="planBAccountPic"
            className="profileImg"
            htmlFor="profileUpdateImgPlanB"
            style={{ width: '100%', height: '6rem' }}
          />
          </label>
        </div>
      ) : (
        <label
          htmlFor="profileUpdateImgPlanB"
          className="uploadFileDiv"
          style={{
            width: '100%',
            height: '100%',
            marginBottom: '0',
          }}
        >
          <input
            className="uploadNewPicPlanB"
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
            }}
            accept="image/*"
            id="profileUpdateImgPlanB"
          />
          <div
            style={{
              width: '100%',
              height: '7rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: 'none',
              borderRadius: '50%',
            }}
          >
            <div>
              <img
                src={upload}
                alt=""
                style={{ width: '30px', height: '30px',marginBottom: "10px" }}
              />
            </div>
          </div>
        </label>
      )}
    </div>
  );
};

export const ImageUploadDivLogo = ({ setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(planBContext);

  return (
    <div
      className="imageUploadDiv"
      onClick={() => setSelectedDropDown('')}
      style={{
        width: '120px',
        height: '120px',
        // border: '0.5px solid #e7e7e7',
        borderRadius: '50%',
      }}
    >
      {funcValue ? (
        <div
          className="imageDiv"
          style={{ height: '100%', width: '100%', marginRight: '0' }}
        >
          <img
            src={funcValue}
            alt="planBAccountPic"
            className="profileImg"
            htmlFor="profileUpdateImgPlanB"
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      ) : (
        <label
          htmlFor="profileUpdateImgPlanB"
          className="uploadFileDiv"
          style={{
            width: '100%',
            height: '100%',
            marginBottom: '0',
          }}
        >
          <input
            className="uploadNewPicPlanB"
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
            }}
            accept="image/*"
            id="profileUpdateImgPlanB"
          />
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: 'none',
              borderRadius: '50%',
            }}
          >
            {planBAccountPicUploading ? (
              <div>Uploading</div>
            ) : (
              <div>
                <img
                  src={logo}
                  alt=""
                  style={{ width: '50px', height: '50px' }}
                />
              </div>
            )}
          </div>
        </label>
      )}
    </div>
  );
};

export const ImageUploadDivCoverPic = ({ setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(planBContext);

  return (
    <div
      className="imageUploadDiv"
      onClick={() => setSelectedDropDown('')}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {funcValue ? (
        <div
          className="imageDiv"
          style={{ height: '100%', width: '100%', marginRight: '0' }}
        >
          <img
            src={funcValue}
            alt="planBAccountPic"
            className="profileImg"
            htmlFor="profileUpdateImgPlanB"
            style={{ width: '100%', height: '100%', borderRadius: '0' }}
          />
        </div>
      ) : (
        <label
          htmlFor="profileUpdateImgPlanB"
          className="uploadFileDiv"
          style={{
            width: '100%',
            height: '100%',
            marginBottom: '0',
          }}
        >
          <input
            className="uploadNewPicPlanB"
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
            }}
            accept="image/*"
            id="profileUpdateImgPlanB"
          />
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: 'none',
            }}
          >
            {planBAccountPicUploading ? (
              <div>Uploading</div>
            ) : (
              <div>
                <img src={cover} alt="" />
              </div>
            )}
          </div>
        </label>
      )}
    </div>
  );
};
