import React from 'react';
import './advertise.scss';
import Layout from '../../Layout/Layout';

const TestPage = () => {
  return (
    <Layout active="TestPagers" className="TestPage" hideFooter>
      <div>test div</div>
    </Layout>
  );
};

export default TestPage;
