import React, { useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useAppContextDetails } from '../../context/AppContext';

import './listComponent.scss';
import searchIcon from '../../static/images/search.svg';
import vault from '../../static/images/vaults/logo.svg';
import indianInvestorLogo from '../../static/images/logos/indianinvestor.svg';

import { useCoinContextData } from '../../context/CoinContext';

// import Investments from './Investments';
import Toggle from '../../components/Toggle';
import { TrackerContents } from '../VaultsPage/VaultComponent/TrackerContents';

import { TrackerDetailed } from '../VaultsPage/VaultComponent/TrackerDetailed';
import Vaults from '../Vaults';
import axios from 'axios';
import { BankContext } from '../../context/Context';

export const ListComponent = ({
  isLoading,
  leftHeaderData,
  leftSubHeader,
  rightHeaderData,
  searchedValue,
  setSearchedValue,
}) => {
  const { email } = useContext(BankContext);
  const { appColorCode, balanceToggle, setBalanceToggle, mmEarningsVault } =
    useAppContextDetails();
  const {
    setCoinType,
    coinType,
    startupVaultsAppcode,
    setStartupVaultsAppcode,
    selectedAppVaults,
    setSelectedAppVaults,
  } = useCoinContextData();
  const isBanking = window.location.pathname === '/banking';

  const [allUserApps, setAllUserApps] = useState('');

  const [vaultsSidebar, setVaultsSidebar] = useState(false);

  useEffect(() => {
    axios
      .get(
        `https://comms.globalxchange.io/gxb/apps/registered/user?email=${email}`
      )
      .then((response) => {
        // console.log(response?.data?.userApps, 'all user apps');
        setAllUserApps(response?.data?.userApps);
      })
      .catch((error) => {
        console.log(error?.message, 'all user apps error');
      });
  }, []);

  useEffect(() => {
    if (allUserApps?.length > 0) {
      let selectedApp = allUserApps?.filter(
        (eachitem) => eachitem?.app_code == startupVaultsAppcode
      );
      // console.log(selectedApp[0], 'selectedApp');
      setSelectedAppVaults(selectedApp[0]);
    }
  }, [startupVaultsAppcode]);

  return (
    <>
      <div className="list-component">
        {isBanking ? (
          <div
            className="userdata"
            style={{
              backgroundColor: appColorCode,
            }}
          >
            <div
              className="left-data"
              style={{
                width: isLoading ? '20%' : 'auto',
              }}
            >
              {isLoading ? (
                <Skeleton className="data-loader" />
              ) : (
                leftHeaderData
              )}
              {leftSubHeader}
            </div>
            <div
              style={{
                width: isLoading ? '20%' : 'auto',
              }}
              className="right-data"
            >
              {rightHeaderData &&
                (isLoading ? (
                  <Skeleton className="data-loader" />
                ) : (
                  rightHeaderData
                ))}
            </div>
          </div>
        ) : (
          <>
            <div className="vaultss-navbar">
              <div className="vaultss-icon">
                <img src={vault} alt="vault" />
              </div>
              <div className="search-vaultss">
                <div className="im">
                  <input
                    type="text"
                    placeholder="Im Looking For..."
                    // onChange={(event) => filterItem(event.target.value)}
                    onChange={(event) => {
                      setSearchedValue(event.target.value);
                    }}
                  />
                  <div className="arroww" style={{ cursor: 'pointer' }}>
                    <img src={searchIcon} alt="" />
                  </div>
                </div>
              </div>
              <div
                className="trackerBtn"
                onClick={() => setCoinType('tracker')}
              >
                Tracker
              </div>
            </div>
            <div className="w-space">
              <div className="optionss" style={{ width: '100%' }}>
                <div className="optionss-divider">
                  <div
                    className={`each-option ${
                      coinType === 'fiat' ? 'each-option-selected' : ''
                    }`}
                    onClick={() => setCoinType('fiat')}
                  >
                    <div>
                      <p>Forex</p>
                    </div>
                  </div>
                  <div
                    className={`each-option ${
                      coinType === 'crypto' ? 'each-option-selected' : ''
                    }`}
                    onClick={() => setCoinType('crypto')}
                  >
                    <div>
                      <p>Crypto</p>
                    </div>
                  </div>

                  {/* <div
                    className={`each-option ${
                      coinType === 'bonds' ? 'each-option-selected' : ''
                    }`}
                   
                    onClick={() => setCoinType('bonds')}
                  >
                    <div>
                      <p>Staking</p>
                    </div>
                  </div> */}
                  {/* <div
                    className={`each-option ${
                      coinType === 'moneyMarkets' ? 'each-option-selected' : ''
                    }`}
                   
                    onClick={() => setCoinType('moneyMarkets')}
                  >
                    <div>
                      <p>MoneyMarkets</p>
                    </div>
                  </div> */}
                </div>
                <div className="last-option">
                  {/* <div className="toggle">
                    <Toggle
                      toggle={balanceToggle}
                      setToggle={setBalanceToggle}
                      coinType={coinType}
                    />
                  </div> */}
                  <div className="changeAppVault">
                    <div>
                      <img
                        src={
                          startupVaultsAppcode == 'indianinvestor'
                            ? indianInvestorLogo
                            : selectedAppVaults?.app_icon
                            ? selectedAppVaults?.app_icon
                            : ''
                        }
                        alt="changeapplogo"
                      />
                      <span>
                        {startupVaultsAppcode == 'indianinvestor'
                          ? 'IndianInvestor'
                          : selectedAppVaults?.app_name
                          ? selectedAppVaults?.app_name
                          : ''}
                      </span>
                    </div>
                    <div
                      className="selectApp"
                      onClick={() => {
                        setVaultsSidebar(true);
                      }}
                    >
                      Change
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="vaultss-sidebar"
              style={{ display: vaultsSidebar ? '' : 'none' }}
            >
              <div
                style={{ position: 'absolute', inset: '0', zIndex: '1' }}
                onClick={() => {
                  setVaultsSidebar(false);
                }}
              ></div>
              <div className="vaultss-sidebar-menu">
                <div className="heading">
                  <div>Select App</div>
                  <div
                    onClick={() => {
                      setVaultsSidebar(false);
                    }}
                  >
                    X
                  </div>
                </div>
                <div className="allApps">
                  {allUserApps?.length > 0
                    ? allUserApps?.map((eachitem, index) => {
                        return (
                          <div
                            className="eachApp"
                            key={eachitem?.app_name + index}
                            onClick={() => {
                              setStartupVaultsAppcode(eachitem?.app_code);
                              setVaultsSidebar(false);
                              localStorage.setItem(
                                'vaultsSelectedApp',
                                JSON.stringify(eachitem)
                              );
                            }}
                          >
                            <img src={eachitem?.app_icon} alt="" />
                            <span>{eachitem?.app_name}</span>
                          </div>
                        );
                      })
                    : ''}
                </div>
              </div>
            </div>
          </>
        )}

        {coinType === 'tracker' ? (
          <TrackerContents />
        ) : coinType === 'trackerdetailed' ? (
          <TrackerDetailed />
        ) : (
          coinType != 'investments' && <Vaults searchedValue={searchedValue} />
        )}
      </div>
    </>
  );
};
