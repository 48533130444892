import React, { useContext, useState } from 'react';
import FundVault from './VaultFundWithdraw/FundVault';
import DepositCrypto from './DepositCrypto';
import DepositWithdrawActionPanel from '../DepositWithdrawActionPanel/DepositWithdrawActionPanel';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { VaultPageContext } from '../../context/VaultPageContext';

function VaultFab() {
  const history = useHistory();
  const { vaultSelected, assetClass, cpanelOpen, setCpanelOpen } =
    useContext(VaultPageContext);
  const { action, type } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [isDeposit, setisDeposit] = useState(true);
  const [openCrypto, setOpenCrypto] = useState(false);
  const [depositMenuOpen, setDepositMenuOpen] = useState(false);
  useEffect(() => {
    if (action === 'do') {
      setDepositMenuOpen(true);
      if (type === 'deposit') {
        setisDeposit(true);
      } else {
        setisDeposit(false);
      }
      setOpenModal(true);
      history.push('/vaults');
    }
  }, [action]);

  return (
    <>
      {assetClass?.name && (
        <div
          className={`fabBtn ${depositMenuOpen || cpanelOpen}`}
          onClick={() => {
            console.log('assetClass?.name', assetClass?.name);
            switch (assetClass?.name) {
              case 'crypto':
                setDepositMenuOpen(!depositMenuOpen);
                break;
              case 'fiat':
                setCpanelOpen('fiat');
                break;
              case 'moneyMarkets':
              case 'bonds':
                setCpanelOpen('moneyMarkets');
                break;
              default:
                break;
            }
          }}
        />
      )}
      {depositMenuOpen && !openModal && (
        <DepositWithdrawActionPanel
          setisDeposit={setisDeposit}
          setOpenModal={setOpenModal}
          setDepositMenuOpen={setDepositMenuOpen}
          vaultSelected={vaultSelected}
        />
      )}
      <FundVault
        key={`${openModal}`}
        fundOrWithdraw={isDeposit ? 'Deposit' : 'Withdraw'}
        isDeposit={isDeposit}
        openModal={openModal}
        setOpenModal={setOpenModal}
        coinSymbol={vaultSelected?.coinSymbol}
      />
      <DepositCrypto
        openModal={openCrypto}
        setOpenModal={setOpenCrypto}
        coinSymbol={vaultSelected}
      />
    </>
  );
}

export default VaultFab;
