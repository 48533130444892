import portfolioIcon from '../../static/images/sidebar-icons/portfolio.svg';

import Fund from '../../static/images/sidebar-icons/fund.svg';

import vaultsIcon from '../../static/images/sidebar-icons/vaults.svg';
import defiIcon from '../../static/images/sidebar-icons/defiIcon.svg';
import affiliateIcon from '../../static/images/sidebar-icons/affiliateIcon.svg';
import taxesIcon from '../../static/images/sidebarIcons/taxes.svg';
import supportIcon from '../../static/images/sidebar-icons/supportIcon.svg';
import supportNewIcon from '../../static/images/sidebar-icons/supportNew.svg';
import sharesToken from '../../static/images/login/sharestoken.svg';
import products from '../../static/images/sidebar-icons/malls.svg';
import trainingIcon from '../../static/images/sidebar-icons/trainingIcon.svg';
import swapIcon from '../../static/images/sidebar-icons/swapIcon.svg';
import aiindexIcon from '../../static/images/sidebar-icons/aiindexIcon.svg';
import tellersIcon from '../../static/images/sidebar-icons/tellersIcon.svg';

import displayIcon from '../../static/images/settings/displayCurrencyIcon.svg';
import themeIcon from '../../static/images/settings/themeIcon.svg';
import securityIcon from '../../static/images/settings/securityIcon.svg';
import settingsIcon from '../../static/images/settings/settingsIcon.svg';
import kycIcon from '../../static/images/settings/kycIcon.svg';
import bankingIcon from '../../static/images/settings/banking.svg';
import logoutIcon from '../../static/images/settings/logoutIcon.svg';

import qrAddress from '../../static/images/actionSidebar/qrAddress.svg';
import connectIcon from '../../static/images/vaultCpanelIcons/connect.svg';
import withdraw from '../../static/images/vaultCpanelIcons/withdraw.svg';
import arrowUp from '../../static/images/clipIcons/arrowUp.svg';
import arrowDown from '../../static/images/clipIcons/arrowDown.svg';
import plus from '../../static/images/actionSidebar/plus.svg';
import retiredLogo from '../../static/images/retiredLogo.svg';

import icon1 from '../../static/images/new-menu/1.svg';
// import connectionIcon from '../../static/images/new-menu/connection.svg';
import offeringsIcon from '../../static/images/new-menu/offerings.svg';
// import vaultsIcon from '../../static/images/new-menu/vaults.svg';
import MMIcon from '../../static/images/new-menu/moneyMarkets.svg';
import startupIcon from '../../static/images/new-menu/startup.svg';
import icon2 from '../../static/images/new-menu/2.svg';
import icon3 from '../../static/images/new-menu/3.svg';
import icon4 from '../../static/images/new-menu/4.svg';
import icon5 from '../../static/images/new-menu/5.svg';
import icon6 from '../../static/images/new-menu/6.svg';
import icon7 from '../../static/images/new-menu/7.svg';
import icon8 from '../../static/images/new-menu/8.svg';
import networkChainIcon from '../../static/images/new-menu/networkchain.svg';
import icon9 from '../../static/images/new-menu/14.svg';
import funnelIcon from '../../static/images/new-menu/funnel.svg';
import icon10 from '../../static/images/new-menu/9.svg';
import engagementIcon from '../../static/images/new-menu/engagement.svg';
import icon11 from '../../static/images/new-menu/10.svg';
import prstudioIcon from '../../static/images/new-menu/prstudio.svg';
import icon12 from '../../static/images/new-menu/11.svg';
import advertisersIcon from '../../static/images/new-menu/advertisers.svg';
import icon13 from '../../static/images/new-menu/12.svg';
import endorsementIcon from '../../static/images/new-menu/endorsement.svg';
import icon14 from '../../static/images/new-menu/13.svg';
import linkedIcon from '../../static/images/new-menu/linked.svg';
import publishingIcon from '../../static/images/new-menu/publishing.svg';
import productionIcon from '../../static/images/new-menu/production.svg';
import prop from '../../static/images/prop.svg';
import dealsIcon from '../../static/images/icons/deals.svg';
import decksIcon from '../../static/images/icons/decks.svg';
import supernovaIcon from '../../static/images/icons/supernova.svg';
import vault from '../../static/images/vault.svg';

import { useCoinContextData } from '../../context/CoinContext';

export const SIDEBAR_CONTENTS = [
  {
    id: 1,
    name: 'Portfolio.ai',
    icon: portfolioIcon,
  },
  {
    id: 2,
    name: 'Vaults',
    icon: vaultsIcon,
  },
  {
    id: 3,
    name: 'Defi',
    icon: defiIcon,
  },
  {
    id: 4,
    name: 'Affiliate',
    icon: affiliateIcon,
  },
  {
    id: 8,
    name: 'Products',
    icon: products,
  },
  {
    id: 9,
    name: 'Training',
    icon: trainingIcon,
  },
  {
    id: 10,
    name: 'Swap',
    icon: swapIcon,
  },
  {
    id: 11,
    name: 'AI Index',
    icon: aiindexIcon,
  },
  {
    id: 12,
    name: 'Funds',
    icon: Fund,
  },
  {
    id: 13,
    name: 'Retired',
    icon: retiredLogo,
  },
];

export const SIDEBAR_MENU = [
  {
    id: 1,
    icon: offeringsIcon,
    name: 'Offerings',
  },
  {
    id: 2,
    icon: vault,
    name: 'Vaults',
  },
  {
    id: 3,
    icon: MMIcon,
    name: 'Market',
  },
  {
    id: 4,
    icon: startupIcon,
    name: 'Capitalized',
  },
  {
    id: 5,
    icon: vault,
    name: 'VaultsSecond',
  },
  {
    id: 6,
    icon: prop,
    name: 'Prospectus',
  },
  {
    id: 7,
    icon: supernovaIcon,
    name: 'Supernova',
  },
  {
    id: 8,
    icon: dealsIcon,
    name: 'Deals',
  },
];

export const SETTINGS_MENU = (
  onLogoutClick,
  onSupportClick,
  onShareTokenClick,
  onTaxesClick,
  onSettingsClick,
  onHoldClick
) => [
  {
    id: 1,
    icon: settingsIcon,
    text: 'Settings',
    subText: 'Manage Your Settings',
    onClick: onSettingsClick,
  },
  {
    icon: supportIcon,
    text: 'OnHold',
    subText: 'Help Your customers',
    onClick: onHoldClick,
  },
  {
    id: 5,
    icon: logoutIcon,
    text: 'Logout',
    subText: 'Hope To See You Again Soon',
    onClick: onLogoutClick,
  },
];

export const COIN_ACTIONS = (coinName, setCoinAction, isMarketsPage) => {
  const { coinType } = useCoinContextData();
  const showBuyAndSellOnly = [
    // {
    //   id: 5,
    //   icon: arrowUp,
    //   text: 'Buy',
    //   subText: `Buy ${coinName} With Another Currency`,
    //   action: () => setCoinAction(['Buy']),
    // },
    // {
    //   id: 6,
    //   icon: arrowDown,
    //   text: 'Sell',
    //   subText: `Buy ${coinName} For Another Currency`,
    //   action: () => setCoinAction(['Sell']),
    // },
  ];

  const enableActionsByCondition = isMarketsPage
    ? showBuyAndSellOnly
    : coinType === 'crypto'
    ? [
        {
          id: 1,
          icon: qrAddress,
          text: 'Add',
          subText: `Add ${coinName} From External Wallet`,
          action: () => setCoinAction(['Add']),
        },
        {
          id: 2,
          icon: vaultsIcon,
          text: 'Transfer',
          subText: `Transfer ${coinName} Between Your Vaults`,
          action: () => setCoinAction(['Transfer']),
        },
        {
          id: 3,
          icon: connectIcon,
          text: 'Connect',
          subText: `Send ${coinName} To Your Friends`,
        },
        {
          id: 4,
          icon: withdraw,
          text: 'Withdraw',
          subText: `Send ${coinName} To An External Wallet`,
          action: () => setCoinAction(['Withdraw']),
        },
        ...showBuyAndSellOnly,
      ]
    : [
        {
          id: 1,
          icon: plus,
          text: 'Add',
          subText: `Send ${coinName} From Your Bank Account`,
          action: () => setCoinAction(['Add']),
        },
        {
          id: 2,
          icon: vaultsIcon,
          text: 'Transfer',
          subText: `Transfer ${coinName} Between Your Vaults`,
          action: () => setCoinAction(['Transfer']),
        },
        {
          id: 3,
          icon: connectIcon,
          text: 'Connect',
          subText: `Send ${coinName} To Your Friends`,
        },
        {
          id: 4,
          icon: withdraw,
          text: 'Withdraw',
          subText: `Send ${coinName} To Your Bank Account`,
          action: () => setCoinAction(['Withdraw']),
        },
      ];
  return enableActionsByCondition;
};
