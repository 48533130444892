import React, { useContext } from 'react';
import { useAppContextDetails } from '../../context/AppContext';
import { useCoinContextData } from '../../context/CoinContext';
import { BankContext } from '../../context/Context';

import { DefaultSideBarContent } from './DefaultSideBarContent';
import { SettingsMenu } from './SettingsMenu';
import { CoinActions } from './CoinActions';
import SharetokenAction from './SharetokenAction';
export const SideBarMenu = ({
  openSettings,
  active,
  chatOn,
  setChatOn,
  toggleSettings,
  isSecondaryMenu,
}) => {
  const { coinActionEnabled, addBankingEnabled } = useCoinContextData();
  const { sidebarCollapse } = useContext(BankContext);
  const { appColorCode ,cardSelected} = useAppContextDetails();

  const defaultSideBarProps = {
    active,
    sidebarCollapse,
    chatOn,
    setChatOn,
    appColorCode,
  };

  if (cardSelected)
    return <SharetokenAction sidebarCollapse={sidebarCollapse} />;
  if (coinActionEnabled)
    return <CoinActions sidebarCollapse={sidebarCollapse} />;
  return openSettings ? (
    <SettingsMenu
      sidebarCollapse={sidebarCollapse}
      toggleSettings={toggleSettings}
      openSettings={openSettings}
    />
  ) : (
    <DefaultSideBarContent
      {...defaultSideBarProps}
      toggleSettings={toggleSettings}
      isSecondaryMenu={isSecondaryMenu}
      openSettings={openSettings}
    />
  );
};
