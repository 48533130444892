import corporateBondsIcon from '../../../static/images/mapIcons/corporateBonds.svg';
import mortagesIcon from '../../../static/images/mapIcons/mortages.svg';
import privateEquityIcon from '../../../static/images/mapIcons/privateEquity.svg';
import realestateIcon from '../../../static/images/mapIcons/realestate.svg';

export const switchItems = [
  { name: 'Private Equity', icon: privateEquityIcon },
  { name: 'Corporate Bonds', icon: corporateBondsIcon },
  { name: 'Real Estate', icon: realestateIcon },

  // { name: 'Real Estate', icon: realestateIcon },
  // { name: 'Mortgages', icon: mortagesIcon },
];
