import React, { useEffect, useState } from 'react';
import './Card.scss'; // Import the CSS file for styling
import { useAppContextDetails } from '../../../context/AppContext';

const Card = ({ name, func }) => {
  const { selectedAppDecks } = useAppContextDetails();

  const hasTickMark =
    selectedAppDecks?.other_data &&
    selectedAppDecks.other_data[name.toLowerCase()];

  return (
    <div className="ecosystem-single-container-image">
      <div className="eco-content">
        <div>
          {hasTickMark && (
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="#4CAF50"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M14 0C6.28009 0 0 6.28009 0 14C0 21.7199 6.28009 28 14 28C21.7199 28 28 21.7199 28 14C28 6.28009 21.7199 0 14 0Z" />
              <path
                d="M21.0957 11.0332L13.5123 18.6164C13.2848 18.8439 12.9861 18.9584 12.6875 18.9584C12.3889 18.9584 12.0902 18.8439 11.8627 18.6164L8.07111 14.8248C7.61481 14.3687 7.61481 13.6313 8.07111 13.1752C8.52719 12.7189 9.2644 12.7189 9.7207 13.1752L12.6875 16.142L19.4461 9.38361C19.9022 8.92731 20.6394 8.92731 21.0957 9.38361C21.5518 9.83969 21.5518 10.5769 21.0957 11.0332Z"
                fill="#FAFAFA"
              />
            </svg>
          )}
          <span>{name}</span>
        </div>
        <div className="ecosystem-single-container">
          <div>{name}</div>
          <div className="ecosystem-single-container-btns">
            <div
              style={{ pointerEvents: hasTickMark ? 'none' : '' }}
              onClick={() => {
                func(name);
              }}
            >
              Add
            </div>
            <div>Edit</div>
            <div>Copy With AI</div>
            <div>View</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
