import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import classNames from './vaultsPage.module.scss';
import Layout from '../../Layout/Layout';
import { BankContext } from '../../context/Context';
import { useUserApps } from '../../queryHooks';
import VaultPageContextProvider, {
  VaultPageContext,
} from '../../context/VaultPageContext';
import VaultControllPanel from '../../components/VaultControllPanel';
import VaultControllPanelForex from '../../components/VaultControllPanelForex';
import { useAppContextDetails } from '../../context/AppContext';
import { VaultComponent } from '../../components/VaultsPage/VaultComponent';
import { Vaults } from '../../components/VaultsPage/Vaults';
import {
  DecksContent,
  SwithNavbar,
  TopNavbar,
  VaultDetailedSection,
} from './SeperateComponents';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import { LoadingAnimation } from '../../components/LoadingAnimation';
import { useMarketContext } from '../../context/MarketContext';
import EnterArticle from '../../components/RichTextEditor/EnterArticle';

function DecksPage() {
  return (
    <VaultPageContextProvider>
      <VaultsPageIn />
    </VaultPageContextProvider>
  );
}

function VaultsPageIn() {
  const { email, token } = useContext(BankContext);
  const { appSelected, setAppSelected, cpanelOpen, setCpanelOpen } =
    useContext(VaultPageContext);
  const { data: appList = [] } = useUserApps(email);
  const { appCodeParam, coinParam } = useParams();
  const {
    appCode,
    decksAppcode,
    setDecksAppcode,
    selectedAppDecks,
    setSelectedAppDecks,
    setDecksContentAPI,
    appLogo,
  } = useAppContextDetails();

  const [openedSidebar, setOpenedSidebar] = useState('mydecks');

  const [allUserApps, setAllUserApps] = useState('');
  const [allUserOfferings, setAllUserOfferings] = useState('');
  const [allUserAppsLoading, setAllUserAppsLoading] = useState(false);
  const [allUserOfferingsLoading, setAllUserOfferingsLoading] = useState(false);

  //createdeck
  const [deckSelectedSymbol, setDeckSelectedSymbol] = useState('');
  const [deckWebsite, setDeckWebsite] = useState('');
  const [deckTagline, setDeckTagline] = useState('');
  const [deckExplanationVideo, setDeckExplanationVideo] = useState('');
  const [deckCreateLoading, setDeckCreateLoading] = useState(false);

  const [vaultsSidebar, setVaultsSidebar] = useState(false);

  useEffect(() => {
    setAllUserOfferingsLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/investment/path/get?path_status=active&banker_email=${email}&investmentType=EQT`
      )
      .then((response) => {
        console.log(response?.data?.paths, 'all user offerings decks');
        setAllUserOfferings(response?.data?.paths);
        if (response?.data?.paths?.length > 0) {
          setDecksAppcode(
            response?.data?.paths[0]?.token_profile_data?.coinSymbol
          );
        }
        setAllUserOfferingsLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, 'all user apps error decks');
        setAllUserOfferingsLoading(false);
      });
  }, []);

  //my decks

  useEffect(() => {
    setAllUserAppsLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/gxb/apps/sharetoken/prospectus?email=${email}`
      )
      .then((response) => {
        // console.log(response?.data?.data, 'all my decks');
        setAllUserApps(response?.data?.data);
        if (response?.data?.data?.length > 0) {
          setDecksAppcode(response?.data?.data[0]?.token_symbol);
        }
        setAllUserAppsLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, 'all user apps error decks');
        setAllUserAppsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (allUserApps?.length > 0) {
      let selectedApp = allUserApps?.filter(
        (eachitem) => eachitem?.token_symbol == decksAppcode
      );
      // console.log('data came', selectedApp[0]);
      if (!selectedAppDecks) {
        // console.log(selectedApp, 'selectedApp');
        setSelectedAppDecks(selectedApp[0]);
        localStorage.setItem(
          'decksSelectedApp',
          JSON.stringify(selectedApp[0])
        );
        // console.log('working decks setting first time');
      }
    }
  }, [decksAppcode]);

  //decks API content not using currently

  useEffect(() => {
    axios
      .get(
        `https://comms.globalxchange.io/gxb/apps/sharetoken/prospectus?token_symbol=${decksAppcode}`
      )
      .then((response) => {
        if (response?.data?.data?.length > 0) {
          // console.log(response?.data?.data[0], 'decks content');
          setDecksContentAPI(response?.data?.data[0]);
        }
      })
      .catch((error) => {
        console.log(error?.message, 'Decks content error');
      });
  }, []);

  useEffect(() => {
    let appSelect;
    if (appCodeParam) {
      appSelect = appList.filter((app) => app.app_code === appCodeParam)[0];
    } else {
      appSelect = appList.filter((app) => app.app_code === appCode)[0];
    }
    if (appSelect) {
      setAppSelected(appSelect);
    }
  }, [appList]);

  function createDeck() {
    setDeckCreateLoading(true);
    const obj = {
      email: email,
      token: token,
      token_symbol: deckSelectedSymbol ? deckSelectedSymbol : '',
      other_data: {
        website: deckWebsite ? deckWebsite : '',
        tagline: deckTagline ? deckTagline : '',
        explanationvideo: deckExplanationVideo ? deckExplanationVideo : '',
      },
    };

    // console.log(obj, 'obj deck create');

    axios
      .post(
        'https://comms.globalxchange.io/gxb/apps/sharetoken/prospectus',
        obj
      )
      .then((response) => {
        // console.log(response, 'create deck');
        if (response?.data?.status) {
          setOpenedSidebar('finalmessage');
        } else {
          setOpenedSidebar('finalmessageerror');
        }
        setDeckCreateLoading(false);
        setTimeout(() => {
          setOpenedSidebar('');
          window.location.reload();
          setVaultsSidebar(false);
          setDecksMainSidebar('');
        }, 3000);
      })
      .catch((error) => {
        console.log(error?.message, 'create deck error');
        setOpenedSidebar('finalmessageerror');
        setDeckCreateLoading(false);
        setTimeout(() => {
          setOpenedSidebar('');
          window.location.reload();
          setVaultsSidebar(false);
          setDecksMainSidebar('');
        }, 3000);
      });
  }

  //decks item  sidebar
  const [decksMainSidebar, setDecksMainSidebar] = useState('');

  //create article problem
  const [articleBody, setArticleBody] = useState('');
  const [localBody, setLocalBody] = useState(articleBody);

  function createArticleDynamic() {
    // setArticleBody(JSON.stringify(localBody));
    let passObjField =
      decksMainSidebar == 'Business Model'
        ? 'business_model'
        : decksMainSidebar?.toLowerCase();
    setDeckCreateLoading(true);
    let article = JSON.stringify(localBody);

    const obj = {
      email: email,
      token: token,
      token_symbol: decksAppcode,
      other_data: {
        [passObjField]: {
          content: article,
        },
      },
    };

    // console.log(obj, 'obj article create');

    axios
      .put('https://comms.globalxchange.io/gxb/apps/sharetoken/prospectus', obj)
      .then((response) => {
        // console.log(response?.data?.status, 'create deck problem');
        if (response?.data?.status) {
          setDecksMainSidebar('articlesuccessful');
        } else {
          setDecksMainSidebar('articlefailed');
        }
        setDeckCreateLoading(false);
        setTimeout(() => {
          setDecksMainSidebar('');
          setVaultsSidebar(false);
          setDecksMainSidebar('');
          localStorage.removeItem('decksSelectedApp');
          setSelectedAppDecks('');
          window.location.reload();
        }, 3000);
      })
      .catch((error) => {
        console.log(error?.message, 'error message article put decks');
        setDecksMainSidebar('articlefailed');
        setDeckCreateLoading(false);
        setTimeout(() => {
          setDecksMainSidebar('');
          window.location.reload();
          setVaultsSidebar(false);
          setDecksMainSidebar('');
        }, 3000);
      });
  }

  if (!email) {
    return <Redirect to="/" />;
  }

  console.log(token, 'token');

  function getCpanel() {
    switch (cpanelOpen) {
      case 'moneyMarkets':
        return <VaultControllPanel onClose={() => setCpanelOpen(false)} />;
      case 'fiat':
        return (
          <VaultControllPanelForex
            onClose={() => setCpanelOpen(false)}
            appSelected={appSelected}
          />
        );
      default:
        return false;
    }
  }

  return (
    <Layout
      active="supernova"
      className={classNames.vaults}
      hideFooter
      sidebarCustom={getCpanel()}
    >
      {/* {coinParam ? <VaultComponent /> : <Vaults />} */}
      <div
        style={{ width: '100%', height: '100%' }}
        className={classNames.decksContent}
      >
        <TopNavbar />
        <SwithNavbar
          vaultsSidebar={vaultsSidebar}
          setVaultsSidebar={setVaultsSidebar}
          setOpenedSidebar={setOpenedSidebar}
        />
        <DecksContent
          vaultsSidebar={vaultsSidebar}
          setVaultsSidebar={setVaultsSidebar}
          setDecksMainSidebar={setDecksMainSidebar}
          setOpenedSidebar={setOpenedSidebar}
        />
        <div
          className={classNames.vaultssSidebar}
          style={{ display: vaultsSidebar ? '' : 'none' }}
        >
          <div
            style={{ position: 'absolute', inset: '0', zIndex: '1' }}
            onClick={() => {
              setVaultsSidebar(false);
              setDecksMainSidebar('');
            }}
          ></div>

          {openedSidebar == 'mydecks' ? (
            <div className={classNames.vaultssSidebarMenu}>
              <div className={classNames.heading}>
                <div>My Decks</div>
                <div
                  onClick={() => {
                    setVaultsSidebar(false);
                    setDecksMainSidebar('');
                  }}
                >
                  X
                </div>
              </div>
              <div className={classNames.allApps}>
                {allUserAppsLoading ? (
                  <div className={classNames.eachApp} key={'loadingdivallapps'}>
                    <Skeleton width={180} height={25} />
                  </div>
                ) : allUserApps?.length > 0 ? (
                  allUserApps?.map((eachitem, index) => {
                    return (
                      <div
                        className={classNames.eachApp}
                        key={eachitem?.token_symbol + index}
                        onClick={() => {
                          console.log(eachitem, 'decks selected');
                          setDecksAppcode(eachitem?.token_symbol);
                          setSelectedAppDecks(eachitem);
                          setVaultsSidebar(false);
                          setDecksMainSidebar('');
                          localStorage.setItem(
                            'decksSelectedApp',
                            JSON.stringify(eachitem)
                          );
                        }}
                      >
                        {/* <img src={eachitem?.token_symbol} alt="" /> */}
                        <span>{eachitem?.token_symbol}</span>
                      </div>
                    );
                  })
                ) : (
                  ''
                )}
              </div>
              <div
                className={classNames.createNewDeck}
                onClick={() => {
                  setOpenedSidebar('newdeck');
                }}
              >
                Create New Deck
              </div>
            </div>
          ) : openedSidebar == 'newdeck' ? (
            <div className={classNames.vaultssSidebarMenu}>
              <div className={classNames.heading}>
                <div>Select An Offering</div>
                <div
                  onClick={() => {
                    setVaultsSidebar(false);
                    setDecksMainSidebar('');
                  }}
                >
                  X
                </div>
              </div>
              <div className={classNames.allApps}>
                {allUserOfferingsLoading ? (
                  <div className={classNames.eachApp} key={'loadingdivallapps'}>
                    <Skeleton circle height={25} width={25} />
                    <Skeleton width={130} height={25} />
                  </div>
                ) : allUserOfferings?.length > 0 ? (
                  allUserOfferings?.map((eachitem, index) => {
                    return (
                      <div
                        className={classNames.eachApp}
                        key={eachitem?.token_profile_data?.coinName + index}
                        onClick={() => {
                          setDeckSelectedSymbol(
                            eachitem?.token_profile_data?.coinSymbol
                          );
                          setOpenedSidebar('basicinfo');
                        }}
                      >
                        <img
                          src={eachitem?.token_profile_data?.coinImage}
                          alt=""
                        />
                        <span>{eachitem?.token_profile_data?.coinName}</span>
                      </div>
                    );
                  })
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : openedSidebar == 'basicinfo' ? (
            <div className={classNames.vaultssSidebarMenu}>
              {deckCreateLoading ? (
                <div className={classNames.loadingDiv}>
                  <LoadingAnimation icon={appLogo} width={200} />
                </div>
              ) : (
                ''
              )}
              <div className={classNames.heading}>
                <div>Basic Information</div>
                <div
                  onClick={() => {
                    setVaultsSidebar(false);
                    setDecksMainSidebar('');
                  }}
                >
                  X
                </div>
              </div>
              <div className={classNames.allInput}>
                <div className={classNames.inputDiv}>
                  <label htmlFor="websiteDeck">Whats your website?</label>
                  <input
                    placeholder="Url.."
                    id="websiteDeck"
                    onChange={(event) => {
                      setDeckWebsite(event?.target?.value);
                    }}
                  />
                </div>
                <div className={classNames.inputDiv}>
                  <label htmlFor="taglineDeck">Whats your tagline?</label>
                  <input
                    placeholder="Tagline.."
                    id="
                  taglineDeck"
                    onChange={(event) => {
                      setDeckTagline(event?.target?.value);
                    }}
                  />
                </div>
                <div className={classNames.inputDiv}>
                  <label htmlFor="explanationDeck">
                    Add an explanation video link
                  </label>
                  <input
                    placeholder="Url.."
                    id="explanationDeck"
                    onChange={(event) => {
                      setDeckExplanationVideo(event?.target?.value);
                    }}
                  />
                </div>
              </div>
              <div
                className={classNames.createNewDeck}
                onClick={() => {
                  createDeck();
                }}
              >
                Submit Deck
              </div>
            </div>
          ) : openedSidebar == 'finalmessage' ? (
            <div className={classNames.vaultssSidebarMenu}>
              <div className={classNames.finalMessage}>
                You Have Successfully Created A Deck
              </div>
            </div>
          ) : openedSidebar == 'finalmessageerror' ? (
            <div className={classNames.vaultssSidebarMenu}>
              <div className={classNames.finalMessage}>
                Failed to create deck try again later...
              </div>
            </div>
          ) : (
            ''
          )}

          {/* main items sidebar */}

          {decksMainSidebar == 'articlesuccessful' ? (
            <div className={classNames.vaultssSidebarMenu}>
              <div className={classNames.finalMessage}>
                You Have Successfully Updated Problem
              </div>
            </div>
          ) : decksMainSidebar == 'articlefailed' ? (
            <div className={classNames.vaultssSidebarMenu}>
              <div className={classNames.finalMessage}>
                Failed to Updated Problem
              </div>
            </div>
          ) : decksMainSidebar && openedSidebar == 'addingSection' ? (
            <div className={classNames.vaultssSidebarMenu}>
              {deckCreateLoading ? (
                <div className={classNames.loadingDiv}>
                  <LoadingAnimation icon={appLogo} width={200} />
                </div>
              ) : (
                ''
              )}
              <div className={classNames.heading}>
                <div>{decksMainSidebar}</div>
                <div
                  onClick={() => {
                    setVaultsSidebar(false);
                    setDecksMainSidebar('');
                  }}
                >
                  X
                </div>
              </div>
              <div
                className={classNames.allApps}
                style={{ overflow: 'hidden' }}
              >
                <EnterArticle
                  articleBody={articleBody}
                  setArticleBody={setArticleBody}
                  localBody={localBody}
                  setLocalBody={setLocalBody}
                />
              </div>
              <div
                className={classNames.createNewDeck}
                onClick={() => {
                  createArticleDynamic();
                }}
              >
                Add {decksMainSidebar}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </Layout>
  );
}

export default DecksPage;
