import React from 'react';
import { useCoinContextData } from '../../../context/CoinContext';
import checkbook from '../../../static/images/checkbook.svg';
import paste from '../../../static/images/paste.svg';
import { pasteSelected } from '../../../utils/FunctionTools';

export const EnterAddress = ({ handleBreadCrumb }) => {
  const { setAddressTerm } = useCoinContextData();

  return (
    <div className="sidebarwithdraw">
      <div>
        <div className="breadcrumb" style={{ display: 'flex' }}>
          <p
            className="breadcrumbText"
            style={{
              marginTop: '5px',
              cursor: 'pointer',
            }}
            onClick={() => handleBreadCrumb()}
          >
            <span>Withdraw</span>
            &nbsp;&nbsp;&gt;&nbsp;&nbsp;
          </p>
          <p className="active-crumb">
            <span
              style={{
                textDecoration: 'underline',
                textUnderlineOffset: '3px',
              }}
            >
              Enter Address
            </span>
          </p>
        </div>
        <div className="step-div">
          <p className="step-head">Step 1: Enter Address</p>
          <p className="step-para">
            Enter The External BTC Address That You Want The Coins Sent To.
          </p>
          <div className="address-div">
            <input
              className="address-input"
              key="address"
              id="clipboard"
              placeholder="Address..."
              type="text"
              onChange={(e) => setAddressTerm(e.target.value)}
            />
          </div>
          <div>
            <div className="each-paste" key="paste">
              <div>
                <img className="paste-btn" src={paste} alt="Paste" width={20} />
              </div>
              <p
                className="paste-text"
                onClick={() => pasteSelected(setAddressTerm)}
              >
                Paste Clipboard
              </p>
            </div>
            <div className="each-paste" key="checkbook">
              <div>
                <img className="checkbook-btn" src={checkbook} alt="" />
              </div>
              <p className="checkbook-text">Add From Checkbook</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
