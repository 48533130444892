import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import countryCodes from 'country-codes-list';
import CandleSeriesChart from '../CandleSeriesChart/ComingSoon';
import classNames from './marketAssetDetail.module.scss';
import { FormatCurrency, FormatNumber } from '../../utils/FunctionTools';
import { useAppDetail, useShareTokensDetail } from '../../queryHooks';
import MarketAssetDetailFinancials from '../MarketAssetDetailFinancials';
import { useAppContextDetails } from '../../context/AppContext';
import down from '../../assets/down.svg';
import up from '../../assets/up.svg';
import './marketDetail.scss';
import axios from 'axios'
function MarketAssetDetail({
  coin,
  onClose,
  assetClass,
  streamOpen,
  setStreamOpen,
  coinSymbol,
  marketsPage = true,
}) {
  const { data: shareToken, isLoading } = useShareTokensDetail(coinSymbol);
  const { data: appDetail, isLoading: loadingApp } = useAppDetail(
    shareToken?.pathAppCode
  );
  const { appCurrencySymbol, toggledata, settoggledata, opentab, setopentab } =
    useAppContextDetails();
const [primaryadata,setprimaryadata]=useState({})
  const [tabSelected, setTabSelected] = useState('Overview');
  const myCountryCodesObject = countryCodes.customList(
    'countryNameEn',
    '{countryCode}'
  );
  console.log('toggledata', coinSymbol);

  useEffect(()=>{    
    axios
      .get(`https://comms.globalxchange.io/coin/investment/path/shareToken/trade/stats?token=${coinSymbol}`)
      .then(({ data }) => {
      
         
          console.log(data,"all countries")
          setprimaryadata(data)
        
      });

  },[coinSymbol])

  
  const tabContent = useCallback(() => {
    switch (tabSelected) {
      case 'Financials':
        return <MarketAssetDetailFinancials shareToken={shareToken} />;
      case 'Overview':
      default:
        return (
          <>
            <div className="dropdownmarket">
              <div className="title">Trading Stats</div>
              {opentab == 'Trading' ? (
                <img
                  src={down}
                  onClick={() => {
                    setopentab('');
                    // alert();
                  }}
                />
              ) : (
                <img
                  src={up}
                  onClick={() => {
                    setopentab('Trading');
                    // alert();
                  }}
                />
              )}
            </div>
            {opentab == 'Trading' && (
              <div className={classNames.aboutChart}>
                <div className={classNames.about}>
                  {/* <div className={classNames.title}>About</div>
              <p className={classNames.desc}>
                {isLoading ? (
                  <Skeleton width={600} count={2} />
                ) : (
                  shareToken?.additional_data?.about
                )}
              </p> */}
                  <div className={classNames.valuesList}>
                    {/* <div className={classNames.value}>
                      <div className={classNames.head}>Headquarters</div>
                      <div className={classNames.val}>
                        {isLoading ? (
                          <Skeleton width={150} />
                        ) : (
                          shareToken?.additional_data?.headquarters
                        )}
                      </div>
                    </div>
                    <div className={classNames.value}>
                      <div className={classNames.head}>Industry</div>
                      <div className={classNames.val}>--</div>
                    </div>
                    <div className={classNames.value}>
                      <div className={classNames.head}>Sector</div>
                      <div className={classNames.val}>--</div>
                    </div> */}
   <div className={classNames.value}>
                      <div className={classNames.head}>Market Capitalization</div>
                      <div className={classNames.val}>
                        {appCurrencySymbol}&nbsp;
                        {isLoading ? (
                          <Skeleton width={150} />
                        ) : (
                      
                            shareToken?.asset_balance?.toFixed(3)
                           
                        )}
                      </div>
                    </div>



                    <div className={classNames.value}>
                      <div className={classNames.head}>
                      Total Shares
                      </div>
                      <div className={classNames.val}>
                        {isLoading ? (
                          <Skeleton width={150} />
                        ) : (
                         shareToken?.token_totalSupply?.toFixed(2)
                        )}
                      </div>
                    </div>
                    <div className={classNames.value}>
                      <div className={classNames.head}>
                      Circulating Shares
                      </div>
                      <div className={classNames.val}>
                        {isLoading ? (
                          <Skeleton width={150} />
                        ) : (
                          FormatNumber(shareToken?.tokens_sold)
                        )}
                      </div>
                    </div>
                    <div className={classNames.value}>
                      <div className={classNames.head}>Primary Market Volume</div>
                      <div className={classNames.val}>
                     {primaryadata?.primaryTradingVolume?.asset_volume?.toFixed(3)}
                      </div>
                    </div>
                    <div className={classNames.value}>
                      <div className={classNames.head}>Secondary Mkt Cap</div>
                      <div className={classNames.val}>
                      { primaryadata?.secondaryTradingVolume?.asset_volume?.toFixed(3)}
                      </div>
                    </div> 
                    {/* <div className={classNames.value}>
                      <div className={classNames.head}>Outstanding Shares</div>
                      <div className={classNames.val}>
                        {isLoading ? (
                          <Skeleton width={150} />
                        ) : (
                          FormatNumber(shareToken?.token_totalSupply)
                        )}
                      </div>
                    </div> */}
                    {/* <div className={classNames.value}>
                      <div className={classNames.head}>Total Shares Sold</div>
                      <div className={classNames.val}>
                        {FormatNumber(shareToken?.tokens_sold)}
                      </div>
                    </div>
                 
                   
                    <div className={classNames.value}>
                      <div className={classNames.head}>
                        Current Shareholders
                      </div>
                      <div className={classNames.val}>
                        {isLoading ? (
                          <Skeleton width={150} />
                        ) : (
                          FormatNumber(shareToken?.tokenHoldersCount, 0)
                        )}
                      </div>
                    </div>
                    <div className={classNames.value}>
                      <div className={classNames.head}>Capital Raises</div>
                      <div className={classNames.val}>
                        {appCurrencySymbol}&nbsp;
                        {FormatCurrency(shareToken?.capital_raised)}
                      </div>
                    </div>
                  */}
                    {/* <div className={classNames.value}>
                      <div className={classNames.head}>
                        Secondary Mkt Volume
                      </div>
                      <div className={classNames.val}>
                        {appCurrencySymbol}--
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* {!streamOpen && (
              <div className={classNames.chart}>
                <div className={classNames.chartHead}>
                  <div className={classNames.titleRow}>
                    <div className={classNames.title}>
                      Market Cap
                      <svg
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.21428 5C8.01703 5 7.85714 5.1599 7.85714 5.35715V8.92857C7.85714 9.12582 7.69724 9.28572 7.49998 9.28572H1.07143C0.874178 9.28572 0.714279 9.12582 0.714279 8.92857V1.78571C0.714279 1.58846 0.874178 1.42856 1.07143 1.42856H5.35715C5.5544 1.42856 5.7143 1.26867 5.7143 1.07141C5.7143 0.874162 5.5544 0.714285 5.35715 0.714285H1.07143C0.479695 0.714285 0 1.19398 0 1.78571V8.92857C0 9.5203 0.479695 10 1.07143 10H7.50001C8.09174 10 8.57144 9.5203 8.57144 8.92857V5.35713C8.57144 5.1599 8.41154 5 8.21428 5Z"
                          fill="#5F6163"
                        />
                        <path
                          d="M9.59983 0.400494C9.34339 0.144021 8.99558 -3.62024e-05 8.6329 5.65035e-06C8.27002 -0.00104067 7.92185 0.143267 7.66613 0.400724L2.96182 5.10463C2.92279 5.14396 2.89335 5.19175 2.87575 5.24428L2.16147 7.38714C2.09913 7.57428 2.20031 7.77651 2.38745 7.83883C2.42376 7.85093 2.46178 7.8571 2.50004 7.85714C2.53837 7.85708 2.57648 7.85093 2.61289 7.83894L4.75575 7.12466C4.80838 7.10708 4.8562 7.07749 4.89539 7.03823L9.59968 2.33394C10.1336 1.80007 10.1337 0.93443 9.59983 0.400494Z"
                          fill="#5F6163"
                        />
                      </svg>
                    </div>
                    <div className={classNames.switchList}>
                      <div
                        className={`${classNames.switch} ${classNames.true}`}
                      >
                        D
                      </div>
                      <div className={classNames.switch}>M</div>
                      <div className={classNames.switch}>W</div>
                      <div className={classNames.switch}>YTD</div>
                      <div className={classNames.switch}>1Y</div>
                    </div>
                  </div>
                  <div className={classNames.marketValues}>
                    <div className={classNames.marketValue}>
                      Open:
                      <span>--</span>
                    </div>
                    <div className={classNames.marketValue}>
                      Closed:
                      <span>--</span>
                    </div>
                    <div className={classNames.marketValue}>
                      High:
                      <span>--</span>
                    </div>
                    <div className={classNames.marketValue}>
                      Low:
                      <span>--</span>
                    </div>
                  </div>
                </div>
                <CandleSeriesChart />
                <div className={classNames.chartFooter}>
                  <div className={classNames.footerItem}>All</div>
                  <div className={classNames.footerItem}>24 Hrs</div>
                  <div className={classNames.footerItem}>1W</div>
                  <div className={classNames.footerItem}>1M</div>
                  <div className={classNames.footerItem}>3M</div>
                  <div className={classNames.footerItem}>YTD</div>
                </div>
              </div>
            )} */}
              </div>
            )}

            <div
              className="dropdownmarket"
              style={{
                borderTop: '0.5px solid #E7E7E7',
                borderBottom:
                  opentab == 'About'
                    ? '0px solid #E7E7E7'
                    : '0.5px solid #E7E7E7',
              }}
            >
              <div className="title">About {coin?.coinName} </div>
              {opentab == 'About' ? (
                <img
                  src={down}
                  onClick={() => {
                    setopentab('');
                    // alert();
                  }}
                />
              ) : (
                <img
                  src={up}
                  onClick={() => {
                    setopentab('About');
                    // alert();
                  }}
                />
              )}
            </div>
            {opentab == 'About' && (
              <div className="describeTitle">
                {' '}
                {isLoading ? (
                  <Skeleton width={600} count={2} />
                ) : (
                  shareToken?.additional_data?.about
                )}
              </div>
            )}
          </>
        );
    }
  }, [tabSelected, isLoading, shareToken, streamOpen, opentab, coin,primaryadata]);

  return (
    <div className={classNames.marketAssetDetail}>
      <div
        className={classNames.topArea}
        style={{
          maxHeight: tabSelected === 'Financials' ? '52px' : 'calc(30% + 15px)',
        }}
      >
        {marketsPage && (
          <div className={classNames.breadCrumbs}>
            <span onClick={() => onClose()}>Markets</span>
            <span className={classNames.gt}>&#8680;&nbsp;</span>
            <span onClick={() => onClose()}>{assetClass.label}</span>
            <span className={classNames.gt}>&#8680;&nbsp;</span>
            <span>{coin?.coinName}</span>
          </div>
        )}

        {tabSelected === 'Financials' ? (
          ''
        ) : (
          <div className={classNames.assetRow}>
            <div className='insidedivtitle'>Markets / ShareTokens / Startups / {coin?.coinSymbol}</div>
            <div className={classNames.selectedAsset}>
              <img src={coin?.coinImage} alt="" />
              <div className={classNames.names}>
                <span className={classNames.coinName}>
                  {coin?.coinName}
                  {isLoading ? (
                    <div className={classNames.assetCoin}>
                      <Skeleton width={140} />
                    </div>
                  ) : (
                    <div className={classNames.assetCoin}>
                      <img src={shareToken?.asset_metaData?.coinImage} alt="" />
                      <span>{shareToken?.asset}</span>
                    </div>
                  )}
                </span>
                <div className={classNames.symbol}>
                  <span>{coin?.coinSymbol}</span>
                  {isLoading ? (
                    <Skeleton width={140} />
                  ) : (
                    <span>
                      <img
                        src={`https://flagcdn.com/w160/${myCountryCodesObject[
                          shareToken?.country
                        ]?.toLowerCase()}.png`}
                        alt=""
                      />
                      {shareToken?.country}
                    </span>
                  )}
                  {isLoading || loadingApp ? (
                    <Skeleton width={140} />
                  ) : (
                    <span>
                      <img src={appDetail?.app_icon} alt="" />
                      {appDetail?.app_name}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className={classNames.price}>
              <div className={classNames.value}>
                {isLoading ? (
                  <Skeleton width={140} />
                ) : (
                  FormatCurrency(shareToken?.token_price, shareToken?.asset)
                )}
              </div>
              <div className={classNames.label}>
                <TimeComponent />
              </div>
            </div>

            {/* {!streamOpen && (
            <div className={classNames.btns}>
              <div
                className={classNames.btnBuy}
                onClick={() => setStreamOpen('Buy')}
              >
                Buy
              </div>
              <div
                className={classNames.btnSell}
                onClick={() => setStreamOpen('Sell')}
              >
                Sell
              </div>
              <a
                href={shareToken?.additional_data?.webstie}
                className={classNames?.btnShare}
                target="_blank"
              >
                Website
              </a>
            </div>
          )} */}
          </div>
        )}
        <div className={classNames.bottomRow}>
          <div className={classNames.tabList}>
            <div
              className={`${classNames.tab} ${
                classNames[(tabSelected === 'Overview').toString()]
              }`}
              onClick={() => setTabSelected('Overview')}
            >
              Overview
            </div>
            <div
              className={`${classNames.tab} ${
                classNames[(tabSelected === 'Financials').toString()]
              }`}
              onClick={() => setTabSelected('Financials')}
            >
              Financials
            </div>
            <div
              className={`${classNames.tab} ${classNames[false]}`}
              style={{ opacity: 0.4 }}
            >
              Team
            </div>
            <div
              className={`${classNames.tab} ${classNames[false]}`}
              style={{ opacity: 0.4 }}
            >
              Business Plan
            </div>
            <div
              className={`${classNames.tab} ${classNames[false]}`}
              style={{ opacity: 0.4 }}
            >
              Roadmap
            </div>
            <div className={`${classNames.tab} ${classNames[false]}`}>News</div>
          </div>
        </div>
      </div>

      {tabContent()}
    </div>
  );
}

function TimeComponent() {
  const [date, setDate] = useState(new Date());
  useEffect(() => {
    setInterval(() => setDate(new Date()), 1000);
  }, []);
  return (
    <>Last Quote ( {moment(date).format('MMMM Do YYYY [at] hh:mm:ss A')} )</>
  );
}

export default MarketAssetDetail;
