import React, { createContext, useContext, useMemo, useState } from 'react';
import { useLoadAppDetails } from '../queryHooks';
import { APP_CODE } from '../config/appConfig';
import { useEffect } from 'react';
import axios from 'axios';
import { BankContext } from './Context';
import { allLicenses } from '../assets/data/apidata';

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [opentab, setopentab] = useState('Trading');
  const [toggledata, settoggledata] = useState(false);
  const { appByCode, appByCodeLoading } = useLoadAppDetails(APP_CODE);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const { email } = useContext(BankContext);
  const [isloading, setisloading] = useState(true);
  const [sharesTab, setSharesTab] = useState('Holdings');
  const [shareTokenTabName, setshareTokenTabName] = useState('default');
  const [shareTokenSelected, setshareTokenSelected] = useState({});
  const [Divident, setDivident] = useState({});
  const [cardSelected, setCardSelected] = useState(false);
  const [tokenSymbol, setTokenSymbol] = useState();
  const [selectedAppCode, setSelectedAppCode] = useState();
  const [dividendBalance, setDividendBalance] = useState([]);
  const [selectedVaultData, setSelectedVaultdata] = useState([]);
  const [dividendLoading, setDividendLoading] = useState(false);
  const [filteredSelectedVaultData, setFilteredSelectedVaultdata] = useState(
    []
  );
  const [displayData, setDisplayData] = useState('default');
  const [mapData, setMapData] = useState([]);
  const [filteredMapData, setFilteredMapData] = useState([]);
  //Mobile View Data
  const [openSidebar, setOpenSidebar] = useState(false);
  const [selectedCurrencyType, setSelectedCurrencyType] = useState('crypto');
  const [selectedMenu, setSelectedMenu] = useState('market');
  const [selectedSubMenu, setSelectedSubMenu] = useState('Crypto');

  // vault sections
  const [selectedView, setSelectedView] = useState('Overview');
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [responseData, setResponseData] = useState([]);
  const [activeTab, setActiveTab] = useState('crypto');
  const [investmentTab, setInvestmentTab] = useState('staking');
  const [stakingResponse, setStakingResponse] = useState([]);
  const [selectedContract, setSelectedContract] = useState([]);
  const [searchedValue, setSearchedValue] = useState('');
  const [balanceToggle, setBalanceToggle] = useState(false);
  const [mmEarningsVault, setMMEarningsVault] = useState('0.00');

  //BuySell Flow
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [selectedOperation, setSelectionOperation] = useState(null);
  const [buySellStep, setBuySellStep] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [tradeResponse, setTradeResponse] = useState(null);

  // Action Flow
  const [actionFlow, setActionFlow] = useState(null);

  // BuyBond
  const [selectedBond, setSelectedBond] = useState(null);

  //malls
  const [index, setIndex] = useState([]);
  const [ind, setInd] = useState();
  const [selectedTab, setSelectedTab] = useState('home');
  const [buy, setBuy] = useState('step1');
  const [mallCoindata, setMallCoinData] = useState([]);
  const [filteredcoins, setfilteredcoins] = useState([]);
  const [mallselectedCoin, setMallSeclectedCoin] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [subscriptionDataStatic, setSubscriptionDataStatic] = useState([]);

  // affiliate
  const [affiliateTab, setaffiliateTab] = useState('crm');
  const [levelData, setLevelData] = useState([]);
  const [balances, setBalances] = useState([]);
  const [showleveldata, setShowLevelData] = useState([]);
  const [showleveldata1, setShowLevelData1] = useState([]);
  const [leveltab, setLevelTab] = useState('levels');
  const [adduser, setAdduser] = useState(false);
  const [adduserStep, setAdduserStep] = useState('step1');
  const [affiliateid, setAffiliateId] = useState('');
  const [allaffiliatedata, setAllaffiliatedata] = useState([]);
  const [selectedApp, setSelectedApp] = useState([]);
  const [selectedAffiliate, setSelectedAffiliate] = useState([]);
  const [isaffiliateselected, setIsaffiliateselected] = useState(false);
  const [isappselected, setIsappselected] = useState(false);
  const [mail, setMail] = useState('');
  const [userName, setUsername] = useState('');
  const [iswithdraw, setIswithdraw] = useState(false);
  const [withdrawStep, setWithdrawStep] = useState('step1');
  const [withdrawVal, setWithdrawVal] = useState('');
  const [chainuserData, setchainUserData] = useState([]);
  const [affStats, setAffStats] = useState([]);
  const [upline, setUpline] = useState([]);
  const [invite, setInvite] = useState(false);
  const [inviteStep, setInviteStep] = useState('step1');
  const [selectedInviteApp, setSelectedInviteApp] = useState([]);
  const [isInviteappselected, setIsInviteappselected] = useState(false);
  const [selectedInviteAsset, setSelectedInviteAsset] = useState([]);
  const [isInviteAssetSelected, setIsInviteAssetSelected] = useState(false);
  const [userfilteredApps, setuserfilteredApps] = useState([]);
  const [userfilteredAff, setuserfilteredAff] = useState([]);
  const [invitefilteredApps, setinvitefilteredApps] = useState([]);
  const [invitefilteredAsset, setinvitefilteredAsset] = useState([]);
  const [exchangeData, setExchangeData] = useState([]);
  const [productsABData, setProductsABData] = useState([]);
  const [MMData, setMMData] = useState([]);
  const [stakingData, setStakingData] = useState([]);
  const [drawOpen, setDrawOpen] = useState(false);
  const [hamMenu, setHamMenu] = useState('Home');
  const [ddselected, setDdSelected] = useState('Staking');
  const [filteredExchangedata, setFilteredExchangeData] = useState([]);
  const [filteredMMData, setFilteredMMData] = useState([]);
  const [filteredStakingData, setFilteredStakingdata] = useState([]);
  const [filteredProductsABData, setFilteredProductsABData] = useState([]);

  //academy
  const [academytab, setAcademytab] = useState('customers');

  // Funds
  const [FundsTab, setFundsTab] = useState("IFO's");
  const [StoreFundsDetail, setStoreFundsDetail] = useState([]);
  const [fundsDetailTable, setfundsDetailTable] = useState([]);
  const [cardNameFunds, setcardNameFunds] = useState('');
  const [CoinStore, setCoinStore] = useState([]);
  const [FundAddlabel, setFundAddlabel] = useState('addfund');
  const [buyItemDetail, setbuyItemDetail] = useState({
    name: '',
    img: '',
  });
  const [finaldata, setfinaldata] = useState({});
  const [amountFunds, setamountFunds] = useState({});
  const [UpdateVaultFunds, setUpdateVaultFunds] = useState([]);
  const [AssetsFunds, setAssetsFunds] = useState([]);
  const [ToggleAddFunds, setToggleAddFunds] = useState(false);

  //ai index
  const [aitab, setAiTab] = useState('My Listings');
  const [allOpportunities, setAllOpportunities] = useState('');
  const [filteredallOpportunities, setFilteredallOpportunities] = useState('');
  const [filtersearch, setfiltersearch] = useState('');
  const [marketSearch, setMarketSearch] = useState('');
  const [allOpportunitiesRefresh, setAllOpportunitiesRefresh] = useState(false);
  const [aiIndexOfferingSelected, setAiIndexOfferingSelected] = useState('');

  //update username
  const [newUserName, setnewUserName] = useState('');
  const [newUserNameSidebar, setnewUserNameSidebar] = useState(false);
  const [usernameExist, setusernameExist] = useState(false);

  //swap
  const [swaptab, setSwaptab] = useState('crypto');

  //settings
  const [settingsTab, setSettingsTab] = useState('Profile');
  const [settingsProfile, setSettingsProfile] = useState('');
  const [authenticated2FA, setAuthenticated2FA] = useState(false);
  const [changePassword, setChangePassword] = useState('');
  const [changePasswordPin, setChangePasswordPin] = useState('');
  const [changePasswordPass, setChangePasswordPass] = useState('');
  const [changePasswordCnfmPass, setChangePasswordCnfmPass] = useState('');
  const [contentData, setContentData] = useState('');
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [refreshSettings, setrefreshSettings] = useState('');
  const [settingsProfileData, setSettingsProfileData] = useState('');
  const [settingsEmail, setSettingsEmail] = useState('');
  const [settingsUsername, setSettingsUsername] = useState('');

  const [qrData2FA, setqrData2FA] = useState('');
  const [qr2FAPin, setqr2FAPin] = useState('');

  const [allCountries, setAllCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [settingsCountry, setSettingsCountry] = useState('');
  const [settingsCountryDropdown, setSettingsCountryDropdown] = useState(false);

  //InfluenceMarket
  const [IMtab, setIMtab] = useState('shop');

  //defaultsidebar
  const [hideEveryone, setHideEveryone] = useState(false);
  const [hideAffiliate, setHideAffiliate] = useState(true);
  const [hideBrands, setHideBrands] = useState(true);
  const [hideInfluencers, setHideInfluencers] = useState(true);
  const [hideContentCreators, setHideContentCreators] = useState(true);

  //crm page
  const [popular, setPopular] = useState(false);
  const [updatingUsername, setupdatingUsername] = useState(false);

  const [BrandedCountryEnabled, setBrandedCountryEnabled] = useState(false);
  const [EBrandedCountryEnabled, setEBrandedCountryEnabled] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://comms.globalxchange.io/coin/vault/countries/data/get`)
      .then(({ data }) => {
        if (data?.status) {
          setAllCountries(data?.countries);
          // console.log(data?.countries,"all countries")
          setLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    let mailId = email;
    allLicenses(mailId)
      .then((response) => {
        let result = response?.data?.productLicenses;
        setSubscriptionData(result);
        setSubscriptionDataStatic(result);
        setisloading(false);
      })
      .catch((error) => {
        console.log(error, 'error in getting allLicenses');
      });
  }, []);

  useEffect(() => {
    if (subscriptionDataStatic?.length > 0) {
      const filtered = subscriptionDataStatic?.filter((eachItem) => {
        return eachItem?.product_name
          ?.toLowerCase()
          .includes(filtersearch?.toLowerCase());
      });
      setSubscriptionData(filtered);
      // console.log(filtered, 'filtered');
    }
  }, [filtersearch]);

  useEffect(() => {
    axios
      .get(
        `https://comms.globalxchange.io/user/profile/data/get?email=${email}`
      )
      .then((response) => {
        if (response?.data?.usersData?.length > 0) {
          // console.log(response?.data?.usersData[0], 'settings profile data');

          if (response?.data?.usersData[0]?.dynamic?.length > 0) {
            setSettingsProfile(
              response?.data?.usersData[0]?.dynamic[0]?.data?.profile_img
            );
            // console.log(
            //   response?.data?.usersData[0]?.dynamic[0]?.data?.profile_img,
            //   'settings pic'
            // );
          }
          if (response?.data?.usersData[0]?.hardCoded?.length > 0) {
            setSettingsEmail(
              response?.data?.usersData[0]?.hardCoded[0]?.data?.email
            );
            setSettingsUsername(
              response?.data?.usersData[0]?.hardCoded[0]?.data?.username
            );
          }
        }
      })
      .catch((error) => {
        console.log(error?.message, 'settings profile error');
      });
  }, [email]);

  useEffect(() => {
    switch (changePassword) {
      case 'changepass1':
        setContentData({
          name: 'Change Password',
          para: 'Are you sure you want to change your password?',
          btn1: 'Yes I Am Sure',
          btn2: 'Never Mind',
        });
        break;
      case 'changepass2':
        setContentData({
          name: 'Change Password',
          para: `Enter the six digit verification code which we just sent to ${email}`,
          btn1: 'Next',
          btn2: 'Cancel Password Reset',
          inputDiv: 'pin',
        });
        break;
      case 'changepass3':
        setContentData({
          name: 'Change Password',
          para: 'Enter Your New Password',
          btn1: 'Next',
          btn2: 'Cancel Password Reset',
          inputDiv: 'password',
        });
        break;
      case 'changepass4':
        setContentData({
          name: 'Change Password',
          para: 'Confirm Your Password',
          btn1: 'Submit',
          btn2: 'Cancel Password Reset',
          inputDiv: 'cnfmpassword',
        });
        break;
      case 'changepass5':
        setContentData({
          name: 'Success',
          para: 'You have successfully updated your password.',
          btn1: 'Submit',
          btn2: 'Close',
        });
        break;
      case 'changepassfailed':
        setContentData({
          name: 'Failed to change password...',
          para: 'Cannot update your password...',
          btn1: 'Submit',
          btn2: 'Close',
        });
        break;
      case 'twofactorEnable1':
        setContentData({
          name: 'Enable 2FA',
          para: 'To enable 2FA, please download the Google Authenticator app on your phone and have it ready to scan in the next step. ',
          btn1: 'I Am Ready',
          btn2: 'Never Mind',
        });
        break;
      case 'twofactorEnable2':
        setContentData({
          name: 'Enable 2FA',
          para: 'Scan this QR code using your Google Auth App.',
          btn1: 'I’ve Added It',
          btn2: 'Cancel 2FA Setup',
          inputDiv: 'qrdiv',
        });
        break;
      case 'twofactorEnable3':
        setContentData({
          name: 'Enable 2FA',
          para: 'Enter the 2FA code which you see on your Google Auth App',
          btn1: 'Next',
          btn2: 'Cancel 2FA Setup',
          inputDiv: 'pin',
        });
        break;
      case 'twofactorEnable4':
        setContentData({
          name: 'Success',
          para: 'You have successfully enabled 2FA. You will need your Google Authenticator app each time you login from now on. ',
          btn1: '',
          btn2: 'Close',
        });
        break;
      case 'twofactorDisable1':
        setContentData({
          name: 'Disable 2FA',
          para: 'Are you sure you want to disable your 2FA? Your account will become less secure.',
          btn1: 'Yes Disable It',
          btn2: 'Never Mind',
        });
        break;
      case 'twofactorDisable2':
        setContentData({
          name: 'Success',
          para: 'You have successfully disabled 2FA. You will no longer need your Google Authenticator app each time you login.',
          btn1: '',
          btn2: 'Close',
        });
        break;
      case 'twofactorDisableFailed':
        setContentData({
          name: 'Failed to disable 2FA',
          para: '2FA disabling failed... ',
          btn1: '',
          btn2: 'Close',
        });
        break;
      default:
        break;
    }
  }, [changePassword]);

  const appData = useMemo(
    () => ({
      appName: appByCode?.app_name,
      appCode: APP_CODE,
      appLogo: appByCode?.app_icon,
      appFullLogo: appByCode?.data?.color_logo,
      appColorCode: `#${appByCode?.color_codes?.[0]?.primarycolourcode}`,
      appCurrencyName: appByCode?.data?.currencyname
        ? appByCode?.data?.currencyname
        : 'USD',
      appCurrencySymbol: appByCode?.data?.currencyname,
      websiteTitle: appByCode?.data?.website_title,
      websiteDescription: appByCode?.data?.website_description,
      ownerEmail: appByCode?.operatorData?.email,
      registrationLink: appByCode?.registration_link,
      token: appByCode?.shareTokenData?.token,
    }),
    [appByCode]
  );

  const {
    appName,
    appCode,
    appLogo,
    appFullLogo,
    appColorCode,
    appCurrencyName,
    appCurrencySymbol,
    websiteTitle,
    websiteDescription,
    ownerEmail,
    registrationLink,
    token,
  } = appData;

  //aiindex filter
  const [selectedType, setSelectedType] = useState('');
  const [selectedCustody, setSelectedCustody] = useState('');
  const [selectedAssetClass, setSelectedAssetClass] = useState('');
  const [selectedBilling, setSelectedBilling] = useState('');
  const [selectedComp, setSelectedComp] = useState('');
  const [selectedLeverage, setSelectedLeverage] = useState('');
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState('');

  //aiindex shop filter
  const [shopCompanies, setShopCompanies] = useState('');
  const [shopBilling, setShopBilling] = useState('');
  const [shopMarket, setShopMarket] = useState('');

  useEffect(() => {
    setSelectedType('');
    setSelectedCustody('');
    setSelectedAssetClass('');
    setSelectedBilling('');
    setSelectedComp('');
    setSelectedLeverage('');
    setSelectedPaymentMethods('');
    setShopCompanies('');
    setShopBilling('');
    setShopMarket('');
  }, [aitab]);

  useEffect(() => {
    setFilteredallOpportunities('');

    if (aitab == 'My Listings') {
      axios
        .get(
          'https://comms.globalxchange.io/gxb/product/aiprowallet/index/item/all',
          {
            params: {
              email: email,
              item_type: selectedType ? selectedType : '',
              custody: selectedCustody ? selectedCustody : '',
              asset_class: selectedAssetClass ? selectedAssetClass : '',
              billing_type: selectedBilling ? selectedBilling : '',
              comp_plan: selectedComp ? selectedComp : '',
              leverage: selectedLeverage ? selectedLeverage : '',
              accepted_payment_methods: selectedPaymentMethods
                ? selectedPaymentMethods
                : '',
            },
          }
        )
        .then((response) => {
          setAllOpportunities(response?.data?.data);
          setFilteredallOpportunities(response?.data?.data);
          // setAiIndexOfferingSelected(response?.data?.data[0]._id);
          // console.log('resposne all opprtunities', response?.data?.data[0]._id);
        })
        .catch((error) => {
          console.log(error, 'all My Listings error');
        });
    }
    // else {
    //   axios
    //     .get(
    //       'https://comms.globalxchange.io/gxb/product/aiprowallet/index/item/all',
    //       {
    //         params: {
    //           item_type: selectedType ? selectedType : '',
    //           custody: selectedCustody ? selectedCustody : '',
    //           asset_class: selectedAssetClass ? selectedAssetClass : '',
    //           billing_type: selectedBilling ? selectedBilling : '',
    //           comp_plan: selectedComp ? selectedComp : '',
    //           leverage: selectedLeverage ? selectedLeverage : '',
    //           accepted_payment_methods: selectedPaymentMethods
    //             ? selectedPaymentMethods
    //             : '',
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       setAllOpportunities(response?.data?.data);
    //       setFilteredallOpportunities(response?.data?.data);
    //       setAiIndexOfferingSelected(response?.data?.data[0]._id);
    //       // console.log('resposne all opprtunities', response?.data?.data[0]._id);
    //     })
    //     .catch((error) => {
    //       console.log(error, 'all opportunities error');
    //     });
    // }
  }, [
    email,
    aitab,
    allOpportunitiesRefresh,
    selectedType,
    selectedCustody,
    selectedAssetClass,
    selectedBilling,
    selectedComp,
    selectedLeverage,
    selectedPaymentMethods,
  ]);

  useEffect(() => {
    if (allOpportunities?.length > 0) {
      let filteredRes = allOpportunities?.filter(
        (res) =>
          res?.item_name?.toLowerCase().includes(filtersearch?.toLowerCase()) ||
          res?.company_name?.toLowerCase().includes(filtersearch?.toLowerCase())
      );
      setFilteredallOpportunities(filteredRes);
    }
  }, [filtersearch]);

  useEffect(() => {
    setAuthenticated2FA('');
    if (email) {
      axios
        .get(
          `https://comms.globalxchange.io/brokerage/comp4/txn/stats/get?email=${email}`
        )
        .then((res) => {
          // console.log(res?.data?.logs, 'products affiliate data', email);
          setProductsABData(res?.data?.logs);
          setFilteredProductsABData(res?.data?.logs);
        })
        .catch((error) => {
          console.log(error?.message, 'products affiliate failed');
        });

      axios
        .post('https://gxauth.apimachine.com/gx/user/mfa/status', {
          email: email,
        })
        .then((response) => {
          // console.log(response?.data, 'authenticated 2FA');
          setAuthenticated2FA(response?.data);
        })
        .catch((error) => {
          console.log(error?.message, '2FA authentication');
        });
    }
  }, [email, refreshSettings]);

  //market page
  const [coinSelected, setCoinSelected] = useState();

  //capitalized page
  const [capTab, setCapTab] = useState('Companies');
  const [ProTab, setProTab] = useState('Checklist');
  const [issueShareTokenEnabled, setIssueShareTokenEnabled] = useState(false);
  const [issuanceAssetEnabled, setIssuanceAssetEnabled] = useState(false);
  const [issuanceCountryEnabled, setIssuanceCountryEnabled] = useState(false);
  const [companyForShareTokenEnabled, setCompanyForShareTokenEnabled] =
    useState(false);
  const [equityRoundEnabled, setEquityRoundEnabled] = useState(false);

  const [decksAppcode, setDecksAppcode] = useState(
    JSON.parse(localStorage.getItem('decksSelectedApp'))?.token_symbol ||
      'My Decks'
  );
  const [selectedAppDecks, setSelectedAppDecks] = useState(
    JSON.parse(localStorage.getItem('decksSelectedApp')) || ''
  );
  const [decksContentAPI, setDecksContentAPI] = useState('');

  return (
    <AppContext.Provider
      value={{
        setProTab,
        ProTab,
        appName,
        appCode,
        appLogo,
        appFullLogo,
        appColorCode,
        appCurrencyName,
        appCurrencySymbol,
        websiteTitle,
        websiteDescription,
        appByCode,
        appDetailsLoading: appByCodeLoading,
        ownerEmail,
        registrationLink,
        token,
        isloading,
        setisloading,

        //Mobile View data
        openSidebar,
        setOpenSidebar,
        selectedCurrencyType,
        setSelectedCurrencyType,
        selectedMenu,
        setSelectedMenu,
        selectedSubMenu,
        setSelectedSubMenu,
        selectedView,
        setSelectedView,
        selectedCurrency,
        setSelectedCurrency,
        responseData,
        setResponseData,
        activeTab,
        setActiveTab,
        investmentTab,
        setInvestmentTab,
        stakingResponse,
        setStakingResponse,
        selectedContract,
        setSelectedContract,
        searchedValue,
        setSearchedValue,
        balanceToggle,
        setBalanceToggle,
        mmEarningsVault,
        setMMEarningsVault,

        //Buy Sell Flow Mobile
        selectedCoin,
        setSelectedCoin,
        selectedOperation,
        setSelectionOperation,
        buySellStep,
        setBuySellStep,
        selectedAsset,
        setSelectedAsset,
        tradeResponse,
        setTradeResponse,

        // Action Flow
        actionFlow,
        setActionFlow,

        //Buy Bond
        selectedBond,
        setSelectedBond,

        //malls page
        index,
        setIndex,
        ind,
        setInd,
        selectedTab,
        setSelectedTab,
        buy,
        setBuy,
        mallCoindata,
        setMallCoinData,
        filteredcoins,
        setfilteredcoins,
        mallselectedCoin,
        setMallSeclectedCoin,
        subscriptionData,
        setSubscriptionData,

        //affiliate page
        affiliateTab,
        setaffiliateTab,
        levelData,
        setLevelData,
        balances,
        setBalances,
        showleveldata,
        setShowLevelData,
        showleveldata1,
        setShowLevelData1,
        leveltab,
        setLevelTab,
        adduser,
        setAdduser,
        adduserStep,
        setAdduserStep,
        affiliateid,
        setAffiliateId,
        allaffiliatedata,
        setAllaffiliatedata,
        selectedApp,
        setSelectedApp,
        selectedAffiliate,
        setSelectedAffiliate,
        isaffiliateselected,
        setIsaffiliateselected,
        isappselected,
        setIsappselected,
        mail,
        setMail,
        userName,
        setUsername,
        iswithdraw,
        setIswithdraw,
        withdrawStep,
        setWithdrawStep,
        withdrawVal,
        setWithdrawVal,
        chainuserData,
        setchainUserData,
        affStats,
        setAffStats,
        upline,
        setUpline,
        invite,
        setInvite,
        inviteStep,
        setInviteStep,
        selectedInviteApp,
        setSelectedInviteApp,
        isInviteappselected,
        setIsInviteappselected,
        selectedInviteAsset,
        setSelectedInviteAsset,
        isInviteAssetSelected,
        setIsInviteAssetSelected,
        userfilteredApps,
        setuserfilteredApps,
        userfilteredAff,
        setuserfilteredAff,
        invitefilteredApps,
        setinvitefilteredApps,
        invitefilteredAsset,
        setinvitefilteredAsset,
        exchangeData,
        setExchangeData,
        productsABData,
        setProductsABData,
        MMData,
        setMMData,
        stakingData,
        setStakingData,
        drawOpen,
        setDrawOpen,
        hamMenu,
        setHamMenu,
        ddselected,
        setDdSelected,
        filteredExchangedata,
        setFilteredExchangeData,
        filteredMMData,
        setFilteredMMData,
        filteredStakingData,
        setFilteredStakingdata,
        filteredProductsABData,
        setFilteredProductsABData,

        //academy
        academytab,
        setAcademytab,

        //ai index
        aitab,
        setAiTab,
        allOpportunities,
        filteredallOpportunities,
        setFilteredallOpportunities,
        filtersearch,
        setfiltersearch,
        allOpportunitiesRefresh,
        setAllOpportunitiesRefresh,
        aiIndexOfferingSelected,
        setAiIndexOfferingSelected,

        //update username
        newUserNameSidebar,
        setnewUserNameSidebar,
        newUserName,
        setnewUserName,
        usernameExist,
        setusernameExist,

        //swappage
        swaptab,
        setSwaptab,
        // funds
        FundsTab,
        setFundsTab,
        setStoreFundsDetail,
        StoreFundsDetail,
        fundsDetailTable,
        setfundsDetailTable,
        setcardNameFunds,
        cardNameFunds,
        setCoinStore,
        CoinStore,
        setbuyItemDetail,
        buyItemDetail,
        setfinaldata,
        finaldata,
        setamountFunds,
        amountFunds,
        setUpdateVaultFunds,
        UpdateVaultFunds,
        setAssetsFunds,
        AssetsFunds,
        FundAddlabel,
        setToggleAddFunds,
        ToggleAddFunds,
        setFundAddlabel,

        //settings tab
        settingsProfileData,
        setSettingsProfileData,
        settingsTab,
        setSettingsTab,
        settingsProfile,
        setSettingsProfile,
        authenticated2FA,
        setAuthenticated2FA,
        changePassword,
        setChangePassword,
        contentData,
        setContentData,
        changePasswordPin,
        setChangePasswordPin,
        changePasswordPass,
        setChangePasswordPass,
        changePasswordCnfmPass,
        setChangePasswordCnfmPass,
        message,
        setMessage,
        changePasswordLoading,
        setChangePasswordLoading,
        qrData2FA,
        setqrData2FA,
        qr2FAPin,
        setqr2FAPin,
        refreshSettings,
        setrefreshSettings,
        settingsEmail,
        setSettingsEmail,
        settingsUsername,
        setSettingsUsername,
        allCountries,
        setAllCountries,
        settingsCountry,
        setSettingsCountry,
        settingsCountryDropdown,
        setSettingsCountryDropdown,

        //aiindexfilter
        selectedType,
        setSelectedType,
        selectedCustody,
        setSelectedCustody,
        selectedAssetClass,
        setSelectedAssetClass,
        selectedBilling,
        setSelectedBilling,
        selectedComp,
        setSelectedComp,
        selectedLeverage,
        setSelectedLeverage,
        selectedPaymentMethods,
        setSelectedPaymentMethods,

        //aiindex shop filter
        shopCompanies,
        setShopCompanies,
        shopBilling,
        setShopBilling,
        shopMarket,
        setShopMarket,

        //InfluenceMarket
        IMtab,
        setIMtab,

        //defaultsidebar
        hideEveryone,
        setHideEveryone,
        hideAffiliate,
        setHideAffiliate,
        hideBrands,
        setHideBrands,
        hideInfluencers,
        setHideInfluencers,
        hideContentCreators,
        setHideContentCreators,

        //crm page
        popular,
        setPopular,
        updatingUsername,
        setupdatingUsername,
        showMoreInfo,
        setShowMoreInfo,

        //market page
        coinSelected,
        setCoinSelected,
        marketSearch,
        setMarketSearch,

        //capitalized page
        capTab,
        setCapTab,
        issueShareTokenEnabled,
        setIssueShareTokenEnabled,
        issuanceAssetEnabled,
        setIssuanceAssetEnabled,
        issuanceCountryEnabled,
        setIssuanceCountryEnabled,
        companyForShareTokenEnabled,
        setCompanyForShareTokenEnabled,
        equityRoundEnabled,
        setEquityRoundEnabled,
        shareTokenTabName,
        setshareTokenTabName,
        shareTokenSelected,
        setshareTokenSelected,
        Divident,
        setDivident,
        cardSelected,
        setCardSelected,
        tokenSymbol,
        setTokenSymbol,
        selectedAppCode,
        setSelectedAppCode,
        dividendBalance,
        setDividendBalance,
        selectedVaultData,
        setSelectedVaultdata,
        dividendLoading,
        setDividendLoading,
        setFilteredSelectedVaultdata,
        setFilteredMapData,
        filteredMapData,
        sharesTab,
        setSharesTab,
        displayData,
        setDisplayData,
        mapData,
        setMapData,
        filteredSelectedVaultData,
        setBrandedCountryEnabled,
        BrandedCountryEnabled,
        EBrandedCountryEnabled,
        setEBrandedCountryEnabled,

        //decks
        decksAppcode,
        setDecksAppcode,
        selectedAppDecks,
        setSelectedAppDecks,
        decksContentAPI,
        setDecksContentAPI,
        toggledata,
        settoggledata,
        opentab,
        setopentab,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default AppContextProvider;

export const useAppContextDetails = () => useContext(AppContext);
