import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import Scrollbars from 'react-custom-scrollbars';
import * as jose from 'jose';
import cloudUploadIcon from '../../../static/images/clipIcons/cloudUpload.svg';

import loadingGif from '../../../static/images/loading.gif';
import { BankContext } from '../../../context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAllergies, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import BankerList from './DisplayCurrencyList';
// import LoadingAnimation from "../LoadingAnimation";

// import { selectLoginData } from "../../app/loginSlice";
import { toast } from 'react-toastify';
import axios from 'axios';
// import clearInput from "../../../static/images/icons/refresh.svg";
import CountryList from './CountryList';

import './numberInput.css';
import './drawerlist.scss';
// import { GlobalContex } from "../../../globalContex";

// import plus from "../../../static/images/globaldrawer/plus.svg";
import MetaverseBrandList from './MetaverseBrandList';
import CategoriesList from './CategoriesList';
import ConglomerateList from './ConglomerateList';
import DisplayCurrencyList from './DisplayCurrencyList';

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const secret = 'uyrw7826^&(896GYUFWE&*#GBjkbuaf'; // secret not to be disclosed anywhere.
const emailDev = 'rahulrajsb@outlook.com'; // email of the developer.

const NewMcbApp = ({ setcompanytoggle, companytoggle }) => {
  // const {
  //   loginData,
  //   bankerEmail,
  //   refetchAppData,
  //   setRefetchAppData,
  //   globalMenuAdd,
  //   setGlobalMenuAdd,
  // } = useContext(GlobalContex);

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState('');
  // Form Variables
  const [icon, setIcon] = useState('');

  const [bankerTag, setBankerTag] = useState('');

  const [company, setCompany] = useState();
  const [tokenSymbol, setTokenSymbol] = useState('');
  const [tokenName, setTokenName] = useState('');
  const [tokenAvailable, setTokenAvailable] = useState(null);

  //myCryptoBrand New App

  const [brand, setBrand] = useState(null);
  const [country, setCountry] = useState(null);
  const [categories, setCategories] = useState([]);
  const [conglomerate, setConglomerate] = useState(null);
  const [displayCurrency, setDisplayCurrency] = useState(null);
  const [appName, setAppName] = useState('');
  const [appCode, setAppCode] = useState('');
  const [callUsers, setCallUsers] = useState('');

  //logo states

  const [colouredIcon, setColouredIcon] = useState('');
  const [whiteLogo, setWhiteLogo] = useState('');
  const [colouredLogo, setColouredLogo] = useState('');
  const [whiteLogo2, setWhiteLogo2] = useState('');
  const [coverPhoto, setCoverPhoto] = useState('');

  //logo loading states

  const [colouredIconLoading, setColouredIconLoading] = useState('');
  const [whiteLogoLoading, setWhiteLogoLoading] = useState('');
  const [colouredLogoLoading, setColouredLogoLoading] = useState('');
  const [whiteLogo2Loading, setWhiteLogo2Loading] = useState('');
  const [coverPhotoLoading, setCoverPhotoLoading] = useState('');

  const [primaryColour, setPrimaryColour] = useState('');
  const [website, setWebsite] = useState('');
  const [shortDesc, setShortDesc] = useState('');
  const [longDesc, setLongDesc] = useState('');
  const [websiteTitle, setWebsiteTitle] = useState('');
  const [websiteDesc, setWebsiteDesc] = useState('');

  const [visibleSubmit, setVisibleSubmit] = useState(false);
  const { email, token } = useContext(BankContext);
  useEffect(() => {
    localStorage.setItem('step', step);
  }, [step]);

  const handleCheckTokenAvailability = () => {
    if (tokenAvailable === null && tokenSymbol !== '') {
      axios
        .get(
          `https://comms.globalxchange.io/coin/investment/path/get?token=${tokenSymbol}`
        )
        .then((res) => {
          if (res.data.status) {
            setTokenAvailable(false);
          } else {
            setTokenAvailable(true);
          }
        });
    } else {
      setTokenAvailable(null);
      setTokenSymbol('');
    }
  };

  const signJwt = async (fileName, emailDev, secret) => {
    try {
      const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
        .setProtectedHeader({ alg: 'HS512' })
        .setIssuer('gxjwtenchs512')
        .setExpirationTime('10m')
        .sign(new TextEncoder().encode(secret));
      return jwts;
    } catch (error) {
      console.log(error, 'kjbedkjwebdw');
    }
  };

  const uploadImage = async (e, setImage, setLoading) => {
    setLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf('.')
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append('files', file);
    const path_inside_brain = 'root/';

    const jwts = await signJwt(fileName, emailDev, secret);
    console.log(jwts, 'lkjkswedcf');
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${jwts}&name=${fileName}`,
      formData,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
    setImage(data.payload.url);
    setLoading(false);
  };

  function getContent() {
    switch (step) {
      case 'success':
        setTimeout(() => {
          // // navigate("/ventures/Brands");
          // window.location.reload();
          // setGlobalMenuAdd(false);
        }, 1000);
        //  setRefetchAppData(true);
        return (
          <div className="newConglomerate">
            <div className="succesView">
              <div className="labelItm" style={{ textAlign: 'center' }}>
                You Have Successfully Created the app {appName}. You Will Be
                Redirected To The Updated App List Automatically
              </div>
            </div>
          </div>
        );

      case 'country':
        return (
          <div className="newConglomerate">
            <CountryList setCountry={setCountry} onClose={() => setStep('')} />
          </div>
        );

      case 'Metaverse Brand':
        return (
          <div className="newConglomerate">
            <MetaverseBrandList
              setBrand={setBrand}
              onClose={() => setStep('')}
            />
          </div>
        );
      case 'Categories':
        return (
          <div className="newConglomerate">
            <CategoriesList
              setCategories={setCategories}
              categories={categories}
              onClose={() => setStep('')}
            />
          </div>
        );

      case 'Conglomerate':
        return (
          <div className="newConglomerate">
            <ConglomerateList
              setConglomerate={setConglomerate}
              onClose={() => setStep('')}
            />
          </div>
        );

      case 'Display Currency':
        return (
          <div className="newConglomerate">
            <DisplayCurrencyList
              setDisplayCurrency={setDisplayCurrency}
              onClose={() => setStep('')}
            />
          </div>
        );
      case 'Token Expired':
        return (
          <>
            <div className="newConglomerate">
              <div className="succesView">
                <div className="labelItm" style={{ textAlign: 'center' }}>
                  Token Expired. Login Again.
                </div>
              </div>
            </div>

            <div className="footerBtns">
              <div
              // onClick={(e) => handleBackStep()}
              >
                Go Back
              </div>
              <div
                onClick={(e) => {
                  localStorage.clear();
                  window.location.reload();
                }}
              >
                Logout
              </div>
            </div>
          </>
        );

      default:
        return (
          <>
            <div className="newConglomerate">
              <Scrollbars
                className="scrollForm"
                renderTrackHorizontal={() => <div />}
                renderThumbHorizontal={() => <div />}
                renderTrackVertical={() => <div />}
                renderThumbVertical={() => <div />}
              >
                {/* Select The Metaverse Brand */}
                <div className="name">Select The Metaverse Brand</div>
                {brand?.brand_name ? (
                  <div
                    className="user"
                    onClick={() => setStep('Metaverse Brand')}
                  >
                    <img className="dp" src={brand?.brand_logo} alt="" />
                    <div className="userDetail">
                      <div className="name">{brand?.brand_name}</div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="inputWrap"
                    onClick={() => setStep('Metaverse Brand')}
                  >
                    <input
                      type="text"
                      className="text"
                      placeholder="Click To Select"
                      readOnly
                    />
                    <div
                      className="btnCheck"
                      style={{
                        border: 'none',
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                )}
                {/* Select Country */}
                <div className="name">Select The Country</div>
                {country?.name ? (
                  <div className="user" onClick={() => setStep('country')}>
                    <img className="dp" src={country?.image} alt="" />
                    <div className="userDetail">
                      <div className="name">{country?.name}</div>
                    </div>
                  </div>
                ) : (
                  <div className="inputWrap" onClick={() => setStep('country')}>
                    <input
                      type="text"
                      className="text"
                      placeholder="Click To Select"
                      readOnly
                    />
                    <div
                      className="btnCheck"
                      style={{
                        border: 'none',
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                )}

                {/* Add Categories */}
                <div className="name">Add Categories</div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {categories.map((item, index) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',

                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            width: '100px',
                            height: '100px',
                            border: '0.5px solid #E7E7E7',
                            padding: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '10px',
                          }}
                        >
                          <img
                            src={item.icon}
                            alt=""
                            style={{ width: '60px', height: '60px' }}
                          />
                        </div>
                        <div
                          style={{
                            wordWrap: 'break-word',
                            width: '100px',
                            textAlign: 'center',
                          }}
                        >
                          {item.name}
                        </div>
                      </div>
                    );
                  })}
                  <div
                    onClick={(e) => setStep('Categories')}
                    style={{
                      width: '100px',
                      height: '100px',
                      border: '0.5px solid #E7E7E7',
                      padding: '30px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: '10px',
                    }}
                  >
                    {/* <img src={plus} alt="" /> */}
                  </div>
                </div>

                {/* Select Conglomerate */}
                <div className="name" style={{ paddingTop: '20px' }}>
                  Select Conglomerate
                </div>
                {conglomerate?.groupname ? (
                  <div className="user" onClick={() => setStep('Conglomerate')}>
                    <img className="dp" src={conglomerate?.icon} alt="" />
                    <div className="userDetail">
                      <div className="name">{conglomerate?.groupname}</div>
                      <div className="email" style={{}}>
                        {conglomerate?.group_id}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="inputWrap"
                    onClick={() => setStep('Conglomerate')}
                  >
                    <input
                      type="text"
                      className="text"
                      placeholder="Click To Select"
                      readOnly
                    />
                    <div
                      className="btnCheck"
                      style={{
                        border: 'none',
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                )}

                {/* Select App Display Currency */}
                <div className="name">Select App Display Currency</div>
                {displayCurrency?.coinName ? (
                  <div
                    className="user"
                    onClick={() => setStep('Display Currency')}
                  >
                    <img
                      className="dp"
                      src={displayCurrency?.coinImage}
                      alt=""
                    />
                    <div className="userDetail">
                      <div className="name">{displayCurrency?.coinName}</div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="inputWrap"
                    onClick={() => setStep('Display Currency')}
                  >
                    <input
                      type="text"
                      className="text"
                      placeholder="Click To Select"
                      readOnly
                    />
                    <div
                      className="btnCheck"
                      style={{
                        border: 'none',
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                )}

                {/* Name Of App */}
                <div className="name">Name Of App</div>
                <div className="inputWrap">
                  <input
                    value={appName}
                    onChange={(e) => setAppName(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="App Name..."
                  />
                </div>

                {/* Create App Code */}
                <div className="name">Create App Code</div>
                <div className="inputWrap">
                  <div
                    style={{
                      width: '10px',
                      background:
                        tokenAvailable !== null
                          ? tokenAvailable === true
                            ? '#78C92E'
                            : '#EA0F0F'
                          : '',
                    }}
                  >
                    &nbsp;
                  </div>
                  <input
                    value={appCode}
                    onChange={(e) => setAppCode(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="App Code..."
                  />
                  <div
                    // onClick={(e) => handleCheckTokenAvailability()}
                    className="btnCheck"
                    style={{
                      borderLeft: 'solid 1px #e7e7e7',
                    }}
                  >
                    {tokenAvailable !== null ? (
                      tokenAvailable === true ? (
                        <></>
                      ) : (
                        // <img style={{ cursor: "pointer" }} src={clearInput} />
                        <></>
                        // <img style={{ cursor: "pointer" }} src={clearInput} />
                      )
                    ) : (
                      'Check'
                    )}
                  </div>
                </div>

                {/* What Do You Call Your Users? */}
                <div className="name">What Do You Call Your Users?</div>
                <div className="inputWrap">
                  <input
                    value={callUsers}
                    onChange={(e) => setCallUsers(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="What Do You Call Your Users?"
                  />
                </div>

                {/* Upload Logos */}
                <div className="name">Upload Logos</div>

                <div
                //   style={{
                //     display: "grid",
                //     gridTemplateColumns: "25% 25% 50%",
                //   }}
                >
                  <div className="filesUpload" style={{}}>
                    <label className="fileInp icon">
                      <img
                        className={`${Boolean(colouredIcon)}`}
                        src={
                          colouredIconLoading
                            ? loadingGif
                            : colouredIcon || cloudUploadIcon
                        }
                        alt=""
                        style={{ width: '40px' }}
                      />
                      <input
                        type="file"
                        onChange={(e) => {
                          uploadImage(
                            e,
                            setColouredIcon,
                            setColouredIconLoading
                          );
                        }}
                        accept="image/*"
                      />
                      <div
                        className="text"
                        style={{ fontWeight: '700', fontSize: '12px' }}
                      >
                        Colored Icon
                      </div>
                      <div className="hovTxt">
                        Upload
                        <br />
                        New
                      </div>
                    </label>
                    <label className="fileInp icon">
                      <img
                        className={`${Boolean(whiteLogo)}`}
                        src={
                          whiteLogoLoading
                            ? loadingGif
                            : whiteLogo || cloudUploadIcon
                        }
                        alt=""
                        style={{ width: '40px' }}
                      />
                      <input
                        type="file"
                        onChange={(e) => {
                          uploadImage(e, setWhiteLogo, setWhiteLogoLoading);
                        }}
                        accept="image/*"
                      />
                      <div
                        className="text"
                        style={{ fontWeight: '700', fontSize: '12px' }}
                      >
                        White Logo
                      </div>
                      <div className="hovTxt">
                        Upload
                        <br />
                        New
                      </div>
                    </label>
                    <label className="fileInp icon">
                      <img
                        className={`${Boolean(colouredLogo)}`}
                        src={
                          colouredLogoLoading
                            ? loadingGif
                            : colouredLogo || cloudUploadIcon
                        }
                        alt=""
                        style={{ width: '40px' }}
                      />
                      <input
                        type="file"
                        onChange={(e) => {
                          uploadImage(
                            e,
                            setColouredLogo,
                            setColouredLogoLoading
                          );
                        }}
                        accept="image/*"
                      />
                      <div className="text" style={{ fontWeight: '700' }}>
                        Colored Logo
                      </div>
                      <div className="hovTxt">
                        Upload
                        <br />
                        New
                      </div>
                    </label>
                  </div>
                  <div className="filesUpload">
                    <label className="fileInp icon">
                      <img
                        className={`${Boolean(whiteLogo2)}`}
                        src={
                          whiteLogo2Loading
                            ? loadingGif
                            : whiteLogo2 || cloudUploadIcon
                        }
                        alt=""
                        style={{ width: '40px' }}
                      />
                      <input
                        type="file"
                        onChange={(e) => {
                          uploadImage(e, setWhiteLogo2, setWhiteLogo2Loading);
                        }}
                        accept="image/*"
                      />
                      <div
                        className="text"
                        style={{ fontWeight: '700', fontSize: '12px' }}
                      >
                        White Logo
                      </div>
                      <div className="hovTxt">
                        Upload
                        <br />
                        New
                      </div>
                    </label>
                    <label className="fileInp icon">
                      <img
                        className={`${Boolean(coverPhoto)}`}
                        src={
                          coverPhotoLoading
                            ? loadingGif
                            : coverPhoto || cloudUploadIcon
                        }
                        alt=""
                        style={{ width: '40px' }}
                      />
                      <input
                        type="file"
                        onChange={(e) => {
                          uploadImage(e, setCoverPhoto, setCoverPhotoLoading);
                        }}
                        accept="image/*"
                      />
                      <div
                        className="text"
                        style={{ fontWeight: '700', fontSize: '12px' }}
                      >
                        Cover Photo
                      </div>
                      <div className="hovTxt">
                        Upload
                        <br />
                        New
                      </div>
                    </label>
                    <label
                      className="fileInp icon"
                      style={{ visibility: 'hidden' }}
                    ></label>
                  </div>
                </div>

                {/* Enter Primary Colour Code */}
                <div className="name">Enter Primary Colour Code</div>
                <div className="inputWrap">
                  <input
                    value={primaryColour}
                    onChange={(e) => setPrimaryColour(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="#0000000...."
                  />
                  <div
                    className="btnCheck"
                    style={{
                      borderLeft: 'solid 1px #e7e7e7',
                      background: primaryColour
                        ? '#' + primaryColour
                        : '#ffffff',
                      width: '68px',
                    }}
                  >
                    &nbsp;
                  </div>
                </div>

                {/* Enter Website */}
                <div className="name">Enter Website</div>
                <div className="inputWrap">
                  <input
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="Website..."
                  />
                </div>

                {/* Enter Short Description */}
                <div className="name">Enter Short Description</div>
                <div className="inputWrap">
                  <input
                    value={shortDesc}
                    onChange={(e) => setShortDesc(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="Description..."
                  />
                </div>

                {/* Enter Long Description */}
                <div className="name">Enter Long Description</div>
                <div className="inputWrap" style={{ height: '300px' }}>
                  <textarea
                    rows="10"
                    value={longDesc}
                    onChange={(e) => setLongDesc(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="Long Description..."
                    style={{ paddingTop: '20px' }}
                  />
                </div>

                {/* Enter Website Title */}
                <div className="name">Enter Website Title</div>
                <div className="inputWrap">
                  <input
                    value={websiteTitle}
                    onChange={(e) => setWebsiteTitle(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="Website Title..."
                  />
                </div>

                {/* Enter Website Description */}
                <div className="name">Enter Website Description</div>
                <div className="inputWrap">
                  <input
                    value={websiteDesc}
                    onChange={(e) => setWebsiteDesc(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="Website Description..."
                  />
                </div>

                <div className="space"></div>
              </Scrollbars>
            </div>

            <div className="footerBtns">
              <div
                className="newField"
                style={{ fontWeight: 700 }}
                onClick={(e) => {
                  setStep('');
                  //   setMainMenu("");
                }}
              >
                Go Back
              </div>
              <div
                className="btnSubmit"
                onClick={() => validate()}
                style={{
                  fontWeight: 700,
                  opacity:
                    brand &&
                    country &&
                    categories &&
                    conglomerate &&
                    displayCurrency &&
                    appName &&
                    appCode &&
                    colouredIcon &&
                    whiteLogo &&
                    colouredLogo &&
                    whiteLogo2 &&
                    coverPhoto &&
                    primaryColour &&
                    website &&
                    shortDesc &&
                    longDesc &&
                    websiteTitle &&
                    websiteDesc
                      ? 1
                      : 0.3,
                }}
              >
                Submit App
              </div>
            </div>

            {/* <div className="ftBtns">
              <div
                className="newField"
                style={{ fontWeight: 700 }}
                onClick={(e) => {
                  setStep("");
                  setMainMenu("");
                }}
              >
                Go Back
              </div>
              <div
                className="btnSubmit"
                onClick={() => validate()}
                style={{ fontWeight: 700, opacity: visibleSubmit ? 1 : 0.3 }}
              >
                Submit App
              </div>
            </div> */}
          </>
        );
    }
  }

  const validate = () => {
    if (
      (brand,
      country,
      categories,
      conglomerate,
      displayCurrency,
      appName,
      appCode,
      colouredIcon,
      whiteLogo,
      colouredLogo,
      whiteLogo2,
      coverPhoto,
      primaryColour,
      website,
      shortDesc,
      longDesc,
      websiteTitle,
      websiteDesc)
    ) {
      setVisibleSubmit(true);
      createApp();
    } else {
      toast.warning('All Fields Are Mandatory');
    }
  };
  const createApp = () => {
    setLoading(true);
    axios
      .post(`https://comms.globalxchange.io/gxb/apps/create`, {
        app_name: appName,
        app_code: appCode,
        profile_start_code: appCode,
        parent_group_id: conglomerate.group_id,
        GXNativeapp: true,
        mobileApp: true,
        short_description: shortDesc,
        long_description: longDesc,
        categories: categories.map((o) => o.category_id),
        color_codes: [
          {
            primarycolourcode: primaryColour,
          },
        ],
        user_label: callUsers,
        app_icon: colouredIcon,
        white_logo: whiteLogo2,
        website: website,
        cover_photo: coverPhoto,
        operatorid: brand.operator_id,
        email: email,
        token: token,
        country: country.name,
        data: {
          color_logo: colouredLogo,
          white_icon: whiteLogo,
          website_description: websiteDesc,
          website_title: websiteTitle,
          currencyname: displayCurrency.coinSymbol,
        },
      })
      .then(({ data }) => {
        if (data.status === false) {
          toast.success(data.message || 'API Error');
        } else {
          setStep('success');
          window.reload();
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };
  //   const createApp = () => {
  //     setTokenAvailable(false);
  //     setLoading(true);
  //     Axios.post("https://comms.globalxchange.io/coin/investment/path/define", {
  //       email: loginData?.user?.email,
  //       token: loginData.idToken,
  //       asset: issuanceAsset.coinSymbol,
  //       banker: bankerTag ? bankerTag : localStorage.getItem("bankerTag"),
  //       country: country.name,
  //       investmentType: "EQT",
  //       investmentSubType: "privateequity",
  //       pathType: "shareToken",
  //       pathAppCode: company.app_code,
  //       commission_fee_percentage: commissionForAffiliates,
  //       total_steps: {
  //         step1: {
  //           name: "Initiated",
  //           status: "Initiated",
  //         },
  //         step2: {
  //           name: "Reedemption state",
  //           status: "Redeemed",
  //         },
  //       },
  //       banker_trade_fee: 0,
  //       gx_trade_fee: 0,
  //       banker_fixed_fee: 0,
  //       gx_fixed_fee: 0,
  //       success_step: "step2",
  //       failure_step: "step1",
  //       seriesName: equity,
  //       isssuingAmount: shareTokenCount,
  //       token_price: initialSalePrice,
  //       token_symbol: tokenSymbol,
  //       token_profile_data: {
  //         coinName: tokenName,
  //         coinSymbol: tokenSymbol,
  //         coinImage: icon,
  //         color_codes: [primaryColour, secondaryColour],
  //       },
  //       commission_distribution: {
  //         direct: directAffiliatesCommission,
  //         indirect: networkChainCommission,
  //         direct_bd: directExecutiveOverride,
  //         indirect_bd: executiveNetworkChainCommission,
  //       },
  //     })
  //       .then(({ data }) => {
  //         if (data.status === false) {
  //           toast.success(data.message || "API Error");
  //         } else setStep("success");
  //       })
  //       .catch((error) => {
  //         toast.error(error?.response?.data?.message);
  //       })
  //       .finally(() => setLoading(false));
  //   };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70vh',
          }}
        >
          <div className="loader"></div>
          <div style={{ padding: '20px', fontWeight: 600, color: '#18191D' }}>
            Creating New App ...
          </div>
        </div>
      ) : (
        getContent()
      )}
    </>
  );
};

export default NewMcbApp;
