import React from 'react';
import { useAppContextDetails } from '../../../context/AppContext';
import { useCoinContextData } from '../../../context/CoinContext';
import { handleDecimal } from '../../../utils/FunctionTools';
import picelock from '../../../static/images/picelock.svg';

export const EnterAmount = ({
  handleBreadCrumb,
  validateText,
  isValueLoading,
  check,
  setCheck,
  handleCurrencyConversion,
}) => {
  const { addressTerm, selectedCoin, fromTerm, toTerm, setFromTerm } =
    useCoinContextData();
  const { appCurrencySymbol, appCurrencyName } = useAppContextDetails();

  const handlePercCal = (value) => {
    let balance = handleDecimal(selectedCoin.coinValue);
    let final = (balance * value) / 100;
    final = handleDecimal(final);
    setFromTerm(final);
    handleCurrencyConversion(final);
  };

  return (
    <div className="sidebarwithdraw">
      <div>
        <div className="breadcrumb" style={{ display: 'flex' }}>
          <p
            className="breadCrumbText"
            style={{
              marginTop: '5px',
              cursor: 'pointer',
            }}
            onClick={handleBreadCrumb}
          >
            <span>Enter Address</span>
            &nbsp;&nbsp;&gt;&nbsp;&nbsp;
          </p>
          <p className="active-crumb">
            <span
              style={{
                textDecoration: 'underline',
                textUnderlineOffset: '3px',
              }}
            >
              Enter Amount
            </span>
          </p>
        </div>
        <div className="step-div">
          <p className="step-head">Step 2: Enter Amount</p>
          <p className="step-para">{`Enter The Amount Of Bitcoin You Wish To Send To ${addressTerm}`}</p>
          <div className="step2-div">
            <div className="from-details">
              <p className="from-text">From</p>
              <p className="from-text">
                Balance: {handleDecimal(selectedCoin.coinValue)}
              </p>
            </div>
            <div className="from-to-inputs">
              <div className="div-from-input">
                <input
                  className="from-input"
                  placeholder="0.00"
                  key="from-1"
                  id="from-1"
                  type="text"
                  value={fromTerm}
                  onChange={(e) => validateText(e.target.value)}
                />
                <div className="from-coin" key="from-coin">
                  <img
                    className="coinimgsmall"
                    src={selectedCoin.coinImage}
                    alt=""
                  />
                  <p className="coinsymbolsmall">{selectedCoin.coinSymbol}</p>
                </div>
              </div>
              <div className="div-border"></div>
              <div className="div-to-input">
                <input
                  className="from-input"
                  placeholder="0.00"
                  key="to-1"
                  id="to-1"
                  type="text"
                  style={{ opacity: isValueLoading ? 0.5 : 1 }}
                  value={isValueLoading ? 'Loading..' : toTerm}
                  disabled
                />
                <div className="from-coin" key="to-coin">
                  {/* <img
                  className="coinimgsmall"
                  src={appCurrencySymbol}
                  alt=""
                /> */}
                  <p className="coinsymbolsmall">{appCurrencySymbol}</p>
                  <p className="coinsymbolsmall">{appCurrencyName}</p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="withdraw-perc" onClick={() => handlePercCal(25)}>
                25%
              </p>
              <p className="withdraw-perc" onClick={() => handlePercCal(50)}>
                50%
              </p>
              <p className="withdraw-perc" onClick={() => handlePercCal(100)}>
                100%
              </p>
            </div>
            <div className="picelock">
              <img src={picelock} alt="" />
              <div className="d-flex">
                <p className="pice-text ml-3 mt-3">Enable PiceLock Insurance</p>
                <div className="withdraw-toggle">
                  <input
                    className="withdraw-tgl1"
                    type="checkbox"
                    id="switch"
                    checked={check}
                    onClick={(e) => {
                      const { checked } = e.target;
                      setCheck(checked);
                    }}
                  />
                  <label className="withdraw-tgl2" htmlFor="switch"></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
