import React, { useContext } from 'react';
import VaultFab from '../VaultFab';
// import { ASSET_CLASSES } from '../../../config/constants';
import { VaultPageContext } from '../../../context/VaultPageContext';
import { useAppContextDetails } from '../../../context/AppContext';
import classNames from '../../../pages/VaultsPage/vaultsPage.module.scss';
import { VaultContents } from './VaultContents';
import searchIcon from '../../../static/images/search.svg';
import vault from '../../../static/images/vaults/logo.svg';
import ddown from '../../../static/images/malls/ddown.svg';
import { useHistory } from 'react-router-dom';
import { useCoinContextData } from '../../../context/CoinContext';
import { NetWorthContext } from '../../../context/NetWorthContext';

export const VaultComponent = () => {
  const {
    assetClass,
    setAssetClass,
    vaultSelected,
    trackerData,
    mmEarningsVault,
  } = useContext(VaultPageContext);
  const { setSearchedValue } = useAppContextDetails();
  const history = useHistory();
  const { coinType, setCoinType } = useCoinContextData();
  const { netWorth, liquidCrypto, liquidFiat } = useContext(NetWorthContext);

  return (
    <>
      <div className={classNames.vaultssnavbar}>
        <div className={classNames.vaultssicon}>
          <img src={vault} alt="vault" />
        </div>
        <div className={classNames.searchvaultss}>
          {/* <div className={classNames.all}>
            <p>All Assets</p>
            <div className={classNames.arroww} style={{ cursor: 'pointer' }}>
              <img src={ddown} alt="" />
            </div>
          </div> */}
          <div className={classNames.im}>
            <input
              type="text"
              placeholder="Im Looking For..."
              // onChange={(event) => filterItem(event.target.value)}
              onChange={(event) => {
                setSearchedValue(event.target.value);
              }}
            />
            <div className={classNames.arroww} style={{ cursor: 'pointer' }}>
              <img src={searchIcon} alt="" />
            </div>
          </div>
        </div>
        <div
          className={classNames.trackerBtn}
          onClick={() => {
            history.push('/vaults');
            setCoinType('tracker');
          }}
        >
          Tracker
        </div>
        {/* old vaults balance div */}
        <div className={classNames.righttop} style={{ display: 'none' }}>
          <div
            style={{ borderRight: '1px solid #e7e7e7', paddingRight: '3rem' }}
            className={classNames.divv}
          >
            <div>
              <p style={{ opacity: '0.25' }}>
                {coinType === 'crypto'
                  ? 'Liquid Crypto'
                  : coinType === 'tracker'
                  ? 'Deposits'
                  : coinType === 'moneyMarkets'
                  ? 'MM Earnings Vaults'
                  : coinType === 'fiat'
                  ? 'Liquid Forex'
                  : 'Liquid Holdings'}
              </p>
              <p style={{ fontWeight: '700' }}>
                {trackerData?.perAppCode?.length > 0 && coinType === 'tracker'
                  ? trackerData?.perAppCode[0]?.count
                  : coinType === 'tracker'
                  ? '0'
                  : coinType === 'crypto'
                  ? `$${liquidCrypto} USD`
                  : coinType === 'fiat'
                  ? `$${liquidFiat} USD`
                  : coinType === 'moneyMarkets'
                  ? `$${mmEarningsVault ? mmEarningsVault : ''} USD`
                  : '$0.00 USD'}
              </p>
            </div>
          </div>
          <div style={{ paddingLeft: '2rem' }} className={classNames.divv}>
            <div>
              <p style={{ opacity: '0.25' }}>
                {coinType === 'tracker' ? 'Withdrawals' : 'Total Net-Worth'}
              </p>
              <p style={{ fontWeight: '700' }}>
                {coinType === 'tracker' ? '0' : `$${netWorth} USD`}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames.wspace}>
        <div className={classNames.optionss} style={{ width: '100%' }}>
          <div className={classNames.optionssdivider}>
            <div
              className={classNames.eachoption}
              style={{
                fontWeight: coinType === 'fiat' ? '650' : '300',
              }}
              onClick={() => {
                setCoinType('fiat');
                history.push('/vaults');
              }}
            >
              <div>
                <p>Forex</p>
              </div>
            </div>
            <div
              className={classNames.eachoption}
              style={{
                fontWeight: coinType === 'crypto' ? '650' : '300',
              }}
              onClick={() => {
                setCoinType('crypto');
                history.push('/vaults');
              }}
            >
              <div>
                <p>Crypto</p>
              </div>
            </div>
            {/* <div
              className={classNames.eachoption}
              style={{
                fontWeight: coinType === 'bonds' ? '650' : '300',
              }}
              onClick={() => {
                setCoinType('bonds');
                history.push('/vaults');
              }}
            >
              <div>
                <p>Staking Rewards</p>
              </div>
            </div>
            <div
              className={classNames.eachoption}
              style={{
                fontWeight: coinType === 'moneyMarkets' ? '650' : '300',
              }}
              onClick={() => {
                setCoinType('moneyMarkets');
                history.push('/vaults');
              }}
            >
              <div>
                <p>MoneyMarket Earnings</p>
              </div>
            </div> */}
            {/* <div
              className={classNames.eachoption}
              style={{
                fontWeight: coinType === 'tracker' ? '650' : '300',
              }}
              onClick={() => {
                setCoinType('tracker');
                history.push('/vault');
              }}
            >
              <div>
                <p>Tracker</p>
              </div>
            </div> */}
          </div>
          <div className={classNames.lastoption}></div>
        </div>
      </div>

      <VaultContents />
      {/* <VaultFab vaultSelected={vaultSelected} /> */}
    </>
  );
};
