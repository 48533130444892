import React, { useContext, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BankContext } from './context/Context';
// import LoginPage from './pages/Login/index';
import LoginPage from './pages/Login/Login';
import { useAppContextDetails } from './context/AppContext';
import FirstPage from './pages/Registration/pages/FirstPage';
import HomePage from './pages/Registration/pages/HomePage';
import { Toaster } from 'react-hot-toast';
import TestPage from './pages/TestPage';
import SettingsPage from './pages/Settings';
import VaultsPage from './pages/VaultsPage';
import MarketsPage from './pages/MarketsPage';
import CapitalizedPage from './pages/CapitalizedPage';
import Prospectus from './pages/Prospectus';
import VaultPageOne from './pages/SharesPage';
import VPTemplatePage from './pages/VpTemplate/VPTemplate';
import OfferingsPage from './pages/OfferingsPage';
import DealsPage from './pages/DealsPage';
import DecksPage from './pages/Decks';
function Routes() {
  const { refreshPage, email } = useContext(BankContext);
  const { appLogo, websiteTitle, websiteDescription } = useAppContextDetails();

  return (
    <HelmetProvider>
      <Helmet>
        <title>{websiteTitle}</title>
        <meta name="description" content={websiteDescription} />
      </Helmet>

      <Toaster />
      <Switch>
        <Route exact path="/register" component={HomePage} />
        <Route exact path="/register/affiliate" component={FirstPage} />
        <Route exact path="/register/affiliate/:id" component={FirstPage} />
        <Route exact path="/register/pre-registered" component={FirstPage} />
        <Route
          exact
          path="/register/pre-registered/:id"
          component={FirstPage}
        />
        <Route exact path="/register/by-myself" component={FirstPage} />
        <Route exact path="/register/by-myself/:id" component={FirstPage} />
        <Route exact path="/market/:assetClassName" component={MarketsPage} />
        <Route
          exact
          path="/market/:assetClassName/:coin"
          component={MarketsPage}
        />
        <Route exact path="/market" component={MarketsPage} />
        <Route
          exact
          path="/offerings/:assetClassName/:offeringsCoin"
          component={VPTemplatePage}
        />
        <Route
          exact
          path="/offerings/:assetClassName"
          component={OfferingsPage}
        />
        <Route exact path="/offerings" component={OfferingsPage} />
        <Route exact path="/malls" component={TestPage} />
        <Route
          exact
          path="/vaults/:assetClassParam?/:appCodeParam?/:coinParam?"
          component={VaultsPage}
          key={refreshPage === 'vaults'.toString()}
        />
        <Route exact path="/settings" component={SettingsPage} />
        <Route exact path="/capitalized" component={CapitalizedPage} />
        <Route exact path="/prospectus" component={Prospectus} />

        <Route exact path="/vault" component={VaultPageOne} />
        <Route
          exact
          path="/deals"
          component={DealsPage}
          key={refreshPage === 'vaults'.toString()}
        />
        <Route
          exact
          path="/supernova"
          component={DecksPage}
          key={refreshPage === 'vaults'.toString()}
        />
        <Route exact path="/" component={LoginPage} />
        <Route exact path="*" component={LoginPage} />
      </Switch>
    </HelmetProvider>
  );
}

export default Routes;
