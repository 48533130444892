import Axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { useContext } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import Skeleton from 'react-loading-skeleton';
import { BankContext } from '../../../context/Context';

const MetaverseBrandList = ({ setBrand, onClose }) => {
  const { email, token } = useContext(BankContext);
  const [allBrand, setAllBrand] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Axios.get(
      `https://comms.globalxchange.io/gxb/app/gxlive/user/operator/get?email=${email}&show_apps=true`
    )
      .then((res) => {
        if (res.data.status) {
          setAllBrand(res.data?.operators);
        }
      })
      .finally(() => setLoading(false));
  }, []);
  const [search, setSearch] = useState('');
  return (
    <Fragment>
      <div className="titleOp">Select Metaverse Brand</div>
      <div className="searchWrap">
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          placeholder="Search Brands....|"
        />
      </div>
      <Scrollbars className="searchList">
        {loading
          ? Array(6)
              .fill('')
              .map((_, i) => (
                <div className="user" key={i}>
                  <Skeleton className="dp" circle />
                  <div className="userDetail">
                    <Skeleton className="name" width={200} />
                  </div>
                </div>
              ))
          : allBrand
              .filter((item) =>
                item.brand_name?.toLowerCase().includes(search.toLowerCase())
              )
              .map((item) => (
                <div
                  className="user"
                  key={item._id}
                  onClick={() => {
                    setBrand(item);
                    onClose();
                  }}
                >
                  <img className="dp" src={item.brand_logo} alt="" />
                  <div className="userDetail">
                    <div className="name">{item.brand_name}</div>
                    {/* <div className="email">{banker.email}</div> */}
                  </div>
                </div>
              ))}
        <div className="space"></div>
      </Scrollbars>
    </Fragment>
  );
};

export default MetaverseBrandList;
