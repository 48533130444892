import React, { useContext } from 'react';
import './enterAmountForTransfer.scss';
import { useCoinContextData } from '../../../context/CoinContext';
import { ForexBreadCrumbs } from './ConditionalForexComponents';
import { useAppContextDetails } from '../../../context/AppContext';
import { BankContext } from '../../../context/Context';
import { useHistory } from 'react-router-dom';
import { InputDecimal } from 'react-input-decimal';

const EnterAmountForTransfer = ({ previousPage }) => {
  const {
    selectedCoin,
    addApiValue,
    addForexCurrencyValue,
    setAddForexCurrencyValue,
    setCoinActionEnabled,
    setCoinAction,
    setFundingCurrency,
    setPayMethod,
    setOtcDesk,
    setEnterAmount,
    setCurrentStep,
    setSelectedTab,
    setAddApiValue,
    setToCurrencyApiValue,
    setCountryApiValue,
    setPayMethodApiValue,
    setOtcApiValue,
    setEachCardShowValue,
    setNewWholeValue,
    newWholeValue,
    senbtnClicked,
    setSendBtnClicked,
  } = useCoinContextData();
  const { sidebarCollapse, admin } = useContext(BankContext);
  const { appLogo, appFullLogo } = useAppContextDetails();
  const history = useHistory();

  const onBlur = (e) => {
    const float = parseFloat(e.target.value);
    setAddForexCurrencyValue(float.toFixed(2));
  };

  return (
    <div className="newSidebar" style={{ opacity: '1' }}>
      <div className="top-text">
        <img
          className="clickable"
          src={sidebarCollapse ? appLogo : appFullLogo}
          style={{ width: '80%', height: '80%' }}
          onClick={() => {
            if (admin) {
              // setAdminSidebar(true);
              // setChatOn(false);
              console.log('admin');
            } else {
              history.push('/app');
              setCoinAction(['Menu']);
              setCoinActionEnabled(false);
            }
          }}
        />
      </div>
      <div className="new-breadcrumb">
        <ForexBreadCrumbs />
      </div>
      <div className="sending-text">
        How Much {addApiValue.value} Are You Sending?
      </div>
      <div
        className="add-amount-box"
        style={{
          padding: '0 1.85rem',
          width: '100%',
          borderBottom: '0.5px solid #e5e5e5',
          marginLeft: '0',
          marginTop: '0',
          borderRadius: '0px',
          borderTop: 'none',
          borderRight: 'none',
          height: '5rem',
        }}
      >
        <div className="div-amount-input1">
          <div
            className="add-from-coin"
            key="add"
            style={{ justifyContent: 'flex-start', borderRight: 'none' }}
          >
            <img
              className="coinimgsmall"
              src={selectedCoin?.coinImage}
              alt=""
              style={{ width: '25px', height: '25px' }}
            />
            <p
              className="coinsymbolsmall"
              style={{ fontSize: '1.5rem', fontWeight: '600' }}
            >
              {selectedCoin?.coinSymbol}
            </p>
          </div>
          <input
            style={{
              fontSize: '1.5rem',
              fontWeight: '400',
            }}
            className="add-input"
            placeholder="0.00"
            key="add"
            id="inputNumber"
            type="number"
            value={addForexCurrencyValue}
            onChange={(e) => {
              setAddForexCurrencyValue(e.target.value);
            }}
            onBlur={onBlur}
          />
        </div>
      </div>
      <div className="newbtns-div" style={{ borderTop: '0.5px solid #e5e5e5' }}>
        <div className="top-btns">
          <div style={{ background: '#9FD6DF' }} onClick={previousPage}>
            Back
          </div>
          <div
            style={{ background: '#FF5E5E' }}
            onClick={() => {
              setCoinActionEnabled(false);
              setCoinAction(['Menu']);
              setFundingCurrency(false);
              setPayMethod(false);
              setOtcDesk(false);
              setEnterAmount(false);
              setCurrentStep('step1');
              setSelectedTab('sendingCurrency');
              setAddApiValue('');
              setToCurrencyApiValue('');
              setCountryApiValue('');
              setPayMethodApiValue('');
              setOtcApiValue('');
              setEachCardShowValue('step1');
              setNewWholeValue({
                ...newWholeValue,
                step2: '',
                step3: '',
                step4: '',
              });
              setSendBtnClicked('');
            }}
          >
            Close
          </div>
        </div>
        <div
          className="bottom-btn"
          onClick={() => {
            setCoinAction(['Menu']);
            setFundingCurrency(false);
            setPayMethod(false);
            setOtcDesk(false);
            setEnterAmount(false);
            setCurrentStep('step1');
            setSelectedTab('sendingCurrency');
            setAddApiValue('');
            setToCurrencyApiValue('');
            setCountryApiValue('');
            setPayMethodApiValue('');
            setOtcApiValue('');
            setEachCardShowValue('step1');
            setNewWholeValue({
              ...newWholeValue,
              step2: '',
              step3: '',
              step4: '',
            });
            setSendBtnClicked('');
          }}
        >
          {selectedCoin?.coinSymbol ? selectedCoin?.coinSymbol : 'CAD'} Actions
        </div>
      </div>
    </div>
  );
};

export default EnterAmountForTransfer;
