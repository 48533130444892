import feedIcon from '../../../static/images/mapIcons/feedIcon.svg';
import ibankersIcon from '../../../static/images/mapIcons/ibankersIcon.svg';
import wealthManagersIcon from '../../../static/images/mapIcons/wealthManagerIcon.svg';
import affiliatesIcon from '../../../static/images/mapIcons/affiliatesIcon.svg';
import influncersIcon from '../../../static/images/mapIcons/influncersIcon.svg';

export const switchItems = [
  { name: 'Feed', icon: feedIcon },
  { name: 'IBankers', icon: ibankersIcon },
  { name: 'Wealth Managers', icon: wealthManagersIcon },
  { name: 'Affiliates', icon: affiliatesIcon },
  { name: 'Influencers', icon: influncersIcon },
];
