import React, { useContext, useState } from 'react';
import Layout from '../../Layout/Layout';
import VpTemplate from '.';
import classNames from './vpTemplate.module.scss';
import { Redirect, useHistory } from 'react-router-dom';
import fulllogo from '../../static/images/offeringsfull.svg';
import search from '../../static/images/malls/search.svg';
import { useAppContextDetails } from '../../context/AppContext';
import { GlobalContext } from '../../context/GlobalContext';
import { BankContext } from '../../context/Context';

const VPTemplatePage = () => {
  const history = useHistory();
  const { email } = useContext(BankContext);
  let pathName = window?.location?.pathname?.split('/');
  const { setMarketSearch, coinSelected, setCoinSelected } =
    useAppContextDetails();
  const { selectedTab, setSelectedTab, offeringsData } =
    useContext(GlobalContext);
  const [offeringsOverflow, setOfferingsOverflow] = useState(false);

  // if (!email) {
  //   return <Redirect to="/" />;
  // }

  return (
    <Layout active="Offerings" className={classNames.offerings} hideFooter>
      <div className={classNames.settingsNavbar}>
        <div
          className={classNames.settingsIcon}
          onClick={() => {
            setCoinSelected('');
            // history.push(`/market/${assetClass.name}`);
            history.push('/offerings/shares');
          }}
        >
          <img src={fulllogo} alt="" />
        </div>
        <div className={classNames.searchSettings}>
          {/* <div className="all">
              <p>All Categories</p>
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={ddown} alt="" />
              </div>
            </div> */}
          <div className={classNames.im}>
            <input
              type="text"
              placeholder="Im Looking For..."
              onChange={(event) => setMarketSearch(event?.target?.value)}
            />
            <div className={classNames.arroww} style={{ cursor: 'pointer' }}>
              <img src={search} alt="" />
            </div>
          </div>
        </div>
        <div
          className={classNames.settingsListBtn}
          onClick={() => {
            setOfferingsOverflow(true);
          }}
          style={{
            background:
              offeringsData &&
              offeringsData?.paths?.length > 0 &&
              offeringsData?.paths[0]?.token_profile_data?.color_codes?.length >
                0
                ? '#' +
                  offeringsData?.paths[0]?.token_profile_data?.color_codes[0]
                : '',
          }}
        >
          Own{' '}
          {offeringsData &&
          offeringsData?.paths?.length > 0 &&
          offeringsData?.paths[0]?.token_profile_data?.coinName?.length > 0
            ? offeringsData?.paths[0]?.token_profile_data?.coinName
            : ''}
        </div>
      </div>
      <div className={classNames.wSpace}>
        <div className={classNames.optionss}>
          <div
            className={
              selectedTab === 'Pitch' ? classNames.selectedTab : classNames.tabs
            }
            onClick={(e) => {
              setSelectedTab('Pitch');
            }}
          >
            <div>Pitch</div>
          </div>
          <div
            className={
              selectedTab === 'Discussion'
                ? classNames.selectedTab
                : classNames.tabs
            }
            onClick={(e) => {
              setSelectedTab('Discussion');
            }}
          >
            <div>Discussion</div>
            {/* <div className={classNames.numberBadge}>38</div> */}
          </div>
          <div
            className={
              selectedTab === 'Updates'
                ? classNames.selectedTab
                : classNames.tabs
            }
            onClick={(e) => {
              setSelectedTab('Updates');
            }}
          >
            <div>Updates</div>
            {/* <div className={classNames.numberBadge}>9</div> */}
          </div>
          <div
            className={
              selectedTab === 'Reviews'
                ? classNames.selectedTab
                : classNames.tabs
            }
            onClick={(e) => {
              setSelectedTab('Reviews');
            }}
          >
            <div>Reviews</div>
            {/* <div className={classNames.numberBadge}>38</div> */}
          </div>
        </div>
      </div>
      <div className={classNames.offeringsParent}>
        <VpTemplate />
        <div
          className={`${classNames.offeringsOverflow} ${
            offeringsOverflow ? '' : classNames.offeringsOverflowHidden
          }`}
          // style={{ display: offeringsOverflow ? '' : 'none' }}
        >
          <div
            className={classNames.overflowContainer}
            onClick={() => {
              setOfferingsOverflow(false);
            }}
          ></div>
          <div className={classNames.offeringsRight}>
            <div className={classNames.header}>
              <div className={classNames.title}>
                Want To Own{' '}
                {offeringsData &&
                offeringsData?.paths?.length > 0 &&
                offeringsData?.paths[0]?.token_profile_data?.coinName?.length >
                  0
                  ? offeringsData?.paths[0]?.token_profile_data?.coinName
                  : ''}
                ?
              </div>
              <div
                onClick={() => {
                  setOfferingsOverflow(false);
                }}
              >
                x
              </div>
            </div>
            <div className={classNames.options}>
              <div className={classNames.eachOption}>Buy</div>
              <div className={classNames.eachOption}>Earn</div>
              <div className={classNames.eachOption}>Win</div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default VPTemplatePage;
